import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import "./styles.scss";
import { customSelectTheme } from "./theme";

const CustomSelect = ({
  theme,
  id,
  name,
  placeholder,
  options,
  value,
  onChange,
  classNamePrefix,
  disabled,
}) => {
  return (
    <Select
      theme={theme}
      id={id}
      name={name}
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={onChange}
      classNamePrefix={classNamePrefix}
      className="custom-select-container"
      disabled={disabled}
    />
  );
};

CustomSelect.propTypes = {
  theme: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  classNamePrefix: PropTypes.string,
  disabled: PropTypes.bool,
};

CustomSelect.defaultProps = {
  theme: customSelectTheme,
  id: "cusom-select-container",
  name: "cusom-select-name",
  placeholder: "Show 10 rows",
  options: [],
  value: {},
  onChange: Function.prototype,
  classNamePrefix: "custom-select",
  disabled: false,
};

export default CustomSelect;
