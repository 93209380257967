import "@progress/kendo-theme-default/dist/all.css";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoaderWithLabel from "../../../components/ProcessingLoader/LoaderWithLabel";
import { GET_USER } from "../../../helper/localstorageReaderHelper";
import { useUploadFileHook } from "../../../hooks/useUploadFileHook";
import {
  CREATE_BUSINESSUNIT,
  EDIT_BUSINESSUNIT,
  GET_ALL_BUSINESS_UNITS,
} from "../../../store/Types";
import { ENUM_USER_TYPE, permission } from "../../../utils/Constants";
import {
  getCsv,
  requiredError,
  validate,
  validateError,
} from "../../../utils/commonFun";
import BusinessUnitAccordian from "./BusinessUnitAccordian";
import "./BusinessUnitList.scss";

const BusinessUnitList = (props) => {
  const { selectedClient } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");
  const permissions = get(user, "permissions", []);
  const addPermission = permissions.includes(permission.buAdd);
  const { isLoading } = useSelector((state) => state.BusinessUnitReducer);

  useEffect(() => {
    if (!isEmpty(selectedClient)) {
      dispatch({ type: GET_ALL_BUSINESS_UNITS, payload: selectedClient });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const businessDetails = useSelector(
    (state) => state.BusinessUnitReducer.businessUnits
  );

  const csvHeader1 = [
    [
      "BU Name",
      "BU Entities Name",
      "BU Contact Person Name",
      "BU Contact Person Country Code",
      "BU Contact Person Number",
      "TAN Number",
      "Address Line 1",
      "Address Line 2",
      "Country",
      "State",
      "City",
      "Pincode",
    ],
  ];

  const csvHeaderCallback = () => csvHeader1;

  const [visible, setVisible] = useState(false);

  const uploadFileToggle = () => {
    setVisible(!visible);
  };

  const org_id = get(selectedClient, "id", "");

  const csvData = getCsv(businessDetails, ENUM_USER_TYPE.BU);

  const headerConfig = () => {
    const resultArr = [];
    csvHeader1[0].forEach((item) => {
      const obj = {
        name: item,
        inputName: item,
        required:
          item.trim().toUpperCase() !== "CITY" &&
          item.trim().toUpperCase() !== "ACTIVE/INACTIVE",
        requiredError,
        validate: (value) => validate(value, item),
        validateError,
      };
      resultArr.push(obj);
    });
    return resultArr;
  };
  const csvHeaderConfig = headerConfig();

  const handleCSVUpload = (uploadedData) => {
    const csvUploadArray = [];
    uploadedData?.forEach((item) => {
      const obj = {
        name: item["BU Name"],
        entities_name: item["BU Entities Name"],
        contact_person_name: item["BU Contact Person Name"],
        contact_person_number: item["BU Contact Person Number"],
        contact_person_country_code: `+${item["BU Contact Person Country Code"]}`,
        tan: item["TAN Number"],
        address_line1: item["Address Line 1"],
        address_line2: item["Address Line 2"],
        country: item.Country.toLowerCase(),
        state: item.State,
        city: item.City,
        pincode: item.Pincode,
      };
      csvUploadArray.push(obj);
    });

    const payload = {
      org_id: org_id,
      formData: csvUploadArray,
    };
    dispatch({ type: "businessUnit/businessCSVUploadInitiate" });
    dispatch({ type: "businessUnit/resetBUError" });
    dispatch({ type: "businessUnit/setLoaderTrue" });
    dispatch({ type: "businessUnit/resetApiSuccess" });

    dispatch({ type: CREATE_BUSINESSUNIT, payload: payload });
    setTimeout(() => {
      navigate("/setup");
      toggleDialog();
      dispatch({ type: "businessUnit/resetCreatedBUs" });
    }, 300);
  };

  const onCreateBUClick = () => {
    navigate("/setup/create");
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const handleClick = (isActive) => {
    const payload = {
      formData: { ...isActive, is_active: !isActive.is_active },
      org_id: isActive.organization,
      business_id: isActive.id,
      isStatus: true,
      selectedClient: selectedClient,
    };
    dispatch({ type: EDIT_BUSINESSUNIT, payload: payload });
  };

  const uploadDialog = useUploadFileHook({
    uploadFileToggle,
    handleCSVUpload,
    menuUser: false,
    handleBack: Function.prototype,
    csvHeaderConfig,
    templateDataHandlerCallback: csvHeaderCallback,
    templateFileName: "BusinessUnit_Template.csv",
  });

  return (
    <div>
      <section className="client-listing">
        {isLoading && <LoaderWithLabel />}
        <h3 className="section-heading">Setup: {selectedClient.name} </h3>
        <div className="section-breadcrumbs">
          <nav aria-label="breadcrumb">
            {isEmpty(tenantId) ? (
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/client" className="section-breadcrumbs-a">
                    Clients
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="section-breadcrumbs-a">
                    {selectedClient.name}
                  </span>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Setup
                </li>
              </ol>
            ) : (
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Setup
                </li>
              </ol>
            )}
          </nav>
        </div>
        <div className="client-listing-content w-100">
          <div className="client-listing-toolbar">
            <div className="client-listing-toolbar-left"></div>
            <div className="client-listing-toolbar-right">
              <div className="client-listing-toolbar-right-csv-download">
                {addPermission && (
                  <button
                    className="btn secondary-button"
                    onClick={toggleDialog}
                  >
                    {" "}
                    CSV Upload{" "}
                    <i className="fa fa-upload" aria-hidden="true"></i>
                  </button>
                )}
              </div>
              <div
                className="client-listing-toolbar-right-csv-download"
                style={{ marginLeft: "10px" }}
              >
                <button
                  className="btn secondary-button csv-download-button"
                  disabled={csvData.length === 0}
                >
                  <CSVLink data={csvData} filename={"BusinessUnits.csv"}>
                    {" "}
                    CSV Download{" "}
                    <i className="fa fa-download" aria-hidden="true"></i>
                  </CSVLink>
                </button>
              </div>
              <div className="client-listing-toolbar-right-add-client">
                {addPermission && (
                  <button
                    className="btn primary-button ml-20 "
                    onClick={onCreateBUClick}
                    style={{ marginRight: "20px" }}
                  >
                    {" "}
                    Create BU{" "}
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div>
            <BusinessUnitAccordian
              businessData={isLoading ? [] : businessDetails}
              handleOnClick={handleClick}
            />
          </div>
        </div>
      </section>

      {visible && uploadDialog}
    </div>
  );
};

export default BusinessUnitList;
