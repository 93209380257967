import { get, isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderWithLabel from "../../../components/ProcessingLoader/LoaderWithLabel";
import Toolbar from "../../../components/Toolbar/Toolbar";
import { GET_USER } from "../../../helper/localstorageReaderHelper";
import {
  GET_ALL_CLIENTS,
  GET_ALL_PRODUCTS,
  GET_CLIENT_CONFIG,
} from "../../../store/Types";
import { permission } from "../../../utils/Constants";
import ClientGrid from "./ClientGrid/ClientGrid";
import "./ClientList.scss";

const ClientList = () => {
  const navigate = useNavigate();
  const user = GET_USER();
  const permissions = get(user, "permissions", []);

  const [rowLength, setRowLength] = useState(10);
  const [pageSize, setPageSize] = useState({
    label: "Show 10 Rows",
    value: 10,
  });
  const [editItem, setEditItem] = useState([]);

  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);

  const clientDetails = useSelector((state) => state.ClientReducer.clients);
  const { isLoading } = useSelector((state) => state.ClientReducer);
  const dispatch = useDispatch();

  const setupPermission = permissions.includes(permission.setup);

  const configurationPermission = permissions.includes(
    permission.clientConfigure
  );

  const editPermission = permissions.includes(permission.clientEdit);

  const addPermission = permissions.includes(permission.clientAdd);

  const getAllClientsDispatch = () => {
    dispatch({ type: "client/resetClientError" });
    dispatch({ type: GET_ALL_CLIENTS });
    dispatch({ type: GET_ALL_PRODUCTS });
  };

  useEffect(() => {
    getAllClientsDispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshHandler = () => {
    setPageSize({ label: "Show 10 Rows", value: 10 });
    getAllClientsDispatch();
    setRefresh(true);
    setSearchField("");
    setEditItem([]);
    setTimeout(() => {
      setRefresh(false);
    }, 500);
  };

  const onEditClick = () => {
    !isEmpty(editItem[0]) && editItem.length === 1
      ? navigate("/client/edit", { state: editItem[0] })
      : toast.error("Select One Client to Edit !!!");
  };

  const setupRedirect = () => {
    navigate("/setup");
    dispatch({
      type: "businessUnit/selectedClientSlice",
      payload: editItem[0],
    });
    dispatch({
      type: "client/selectedClientSlice",
      payload: editItem[0],
    });
  };

  const configureRedirect = () => {
    dispatch({ type: "client/setLoaderTrue" });
    dispatch({ type: "client/resetClientError" });
    dispatch({ type: "client/initiateClientConfigSlice" });

    dispatch({
      type: GET_CLIENT_CONFIG,
      payload: editItem[0].id,
    });

    dispatch({
      type: "client/selectedClientSlice",
      payload: editItem[0],
    });

    navigate("/configuration");
  };

  const onSetupClick = () => {
    !isEmpty(editItem[0]) && editItem.length === 1
      ? setupRedirect()
      : toast.error("Select One Client to Setup !!!");
  };

  const onConfigureClick = () => {
    !isEmpty(editItem[0]) && editItem.length === 1
      ? configureRedirect()
      : toast.error("Select One Client to Configure !!!");
  };

  const headers = [
    { label: "Name", key: "name" },
    { label: "Tan Number", key: "tan" },
    { label: "Mobile Number", key: "phone_number" },
    { label: "Registration Number", key: "registration_number" },
    { label: "Address Line 1", key: "address_line_1" },
    { label: "Address Line 2", key: "address_line_2" },
    { label: "Country", key: "country" },
    { label: "State", key: "state" },
    { label: "City", key: "city" },
    { label: "Pincode", key: "pincode" },
    { label: "Created On", key: "created_on" },
    { label: "Modified On", key: "modified_on" },
    { label: "Active/Inactive", key: "is_active" },
  ];

  const filteredClientDetails = clientDetails.filter((client) => {
    return (
      client.client_id.toLowerCase().includes(searchField.toLowerCase()) ||
      client.name.toLowerCase().includes(searchField.toLowerCase())
    );
  });

  const CurrentDate = moment().format("DD-MM-YYYY");

  return (
    <div>
      {isLoading && <LoaderWithLabel />}
      <section className="client-listing">
        <h3 className="section-heading">Clients</h3>
        <div className="section-breadcrumbs">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Client List
              </li>
            </ol>
          </nav>
        </div>

        <div className="client-listing-content">
          <Toolbar
            searchLabel="Client Name"
            addNew={addPermission && "Add New Client"}
            addNewPath="/client/edit"
            setSearchField={setSearchField}
            setRowLength={setRowLength}
            refreshHandler={refreshHandler}
            searchField={searchField}
            editItem={editItem}
            onEditClick={editPermission && onEditClick}
            csvDownload={true}
            csvDownloadHeaders={headers}
            csvDownloadDetails={clientDetails}
            csvDownloadFileName={"Clients_" + CurrentDate}
            onSetupClick={onSetupClick}
            onConfigureClick={onConfigureClick}
            setup={setupPermission}
            configure={configurationPermission}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
          <div className="client-listing-grid">
            {refresh && isLoading ? (
              <LoaderWithLabel />
            ) : (
              <ClientGrid
                clientDetails={filteredClientDetails}
                editItem={editItem}
                setEditItem={setEditItem}
                rowLength={rowLength}
                searchField={searchField}
                editPermission={editPermission}
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ClientList;
