import { toast } from "react-toastify";
import { put, takeEvery } from "redux-saga/effects";
import { getAllPermissionsAPI, getAllProductPermissionsAPI } from "../../apis";
import { getAllPermissionsSlice } from "../Slices/PermissionSlice";
import { GET_ALL_PERMISSIONS, GET_ALL_PRODUCTS_PERMISSIONS } from "../Types";

export function* getAllPermissionsSaga(action) {
  try {
    const permissions = yield getAllPermissionsAPI(
      action.payload?.org_id,
      action.payload?.product_id
    );
    yield put(getAllPermissionsSlice(permissions.data));
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
}

export function* getAllProductPermissionsSaga(action) {
  try {
    const permissions = yield getAllProductPermissionsAPI(action.payload);
    yield put(getAllPermissionsSlice(permissions.data.data));
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
}

export function* watchPermissionsSaga() {
  yield takeEvery(GET_ALL_PERMISSIONS, getAllPermissionsSaga);
  yield takeEvery(GET_ALL_PRODUCTS_PERMISSIONS, getAllProductPermissionsSaga);
}
