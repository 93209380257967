import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import ErrorPage from "../globalComponent/ErrorPage/Error";
import { GET_USER } from "../helper/localstorageReaderHelper";
import BusinessUnitCreation from "../pages/BusinessUnit/BusinessUnitCreation/BusinessUnitCreation";
import BusinessUnitEdit from "../pages/BusinessUnit/BusinessUnitEdit/BusinessUnitEdit";
import BusinessUnitPage from "../pages/BusinessUnit/BusinessUnitPage";
import ClientCreation from "../pages/Client/ClientCreation/ClientCreation";
import Client from "../pages/Client/ClientPage";
import ConfigurationPage from "../pages/Configuration/ConfigurationPage";
import RoleCreation from "../pages/Role/RoleCreation/RoleCreation";
import RoleList from "../pages/Role/RoleList/RoleList";
import TeamCreation from "../pages/Team/TeamCreation/TeamCreation";
import TeamEdit from "../pages/Team/TeamEdit/TeamEdit";
import TeamPage from "../pages/Team/TeamPage";
import UserCreation from "../pages/User/UserCreation/UserCreation";
import UserEdit from "../pages/User/UserEdit/UserEdit";
import UserList from "../pages/User/UserList/UserList";

const Routing = () => {
  let navigate = useNavigate();
  const user = GET_USER();
  useEffect(() => {
    if (isEmpty(user)) {
      navigate("/error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route path="/client" element={<Client />} />
        <Route path="/client/edit" element={<ClientCreation />} />
        <Route path="/setup" element={<BusinessUnitPage />} />
        <Route path="/configuration" element={<ConfigurationPage />} />
        <Route path="/setup/configuration" element={<ConfigurationPage />} />
        <Route path="/setup/create" element={<BusinessUnitCreation />} />
        <Route path="/setup/edit" element={<BusinessUnitEdit />} />
        <Route path="/setup/team" element={<TeamPage />} />
        <Route path="/setup/team/create" element={<TeamCreation />} />
        <Route path="/setup/team/edit" element={<TeamEdit />} />
        <Route path="/setup/team/user" element={<UserList />} />
        <Route path="/user" element={<UserList />} />
        <Route path="/user/create" element={<UserCreation />} />
        <Route path="/user/edit" element={<UserEdit />} />
        <Route path="/role" element={<RoleList />} />
        <Route path="/role/edit" element={<RoleCreation />} />

        {/* Protected routes */}

        {/* Wildcard route */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default Routing;
