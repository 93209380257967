import { Grid, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { getClientByIdAPI } from "../../../apis";
import CustomFieldValidator from "../../../components/CustomFieldValidator";
import MultiSelect from "../../../components/MultiSelect";
import LoaderWithLabel from "../../../components/ProcessingLoader/LoaderWithLabel";
import { userRole } from "../../../config/get-client-detail";
import InputField from "../../../globalComponent/InputField";
import { GET_USER } from "../../../helper/localstorageReaderHelper";
import {
  CREATE_ROLE,
  GET_ALL_PERMISSIONS,
  GET_ALL_PRODUCTS_PERMISSIONS,
  UPDATE_ROLE,
} from "../../../store/Types";
import { getProductOptions } from "../../../utils";
import { createThemeSelect, nameValidation, role } from "../../../utils/Constants";

const validator = new SimpleReactValidator();

const clientOption = ({
  tenantId,
  clients
}) => {
  return isEmpty(tenantId) &&
    clients?.map((item) => ({
      label: item.name,
      value: item.id,
      products_enabled: item.products_enabled
    })).sort((a, b) => {
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      return 0;
    })
}


const RoleCreation = () => {
  const offsetHeight = window.innerHeight - 170;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const org_id = useSelector((state) => state.RoleReducer.org_id);
  const roleState = useSelector((state) => state.RoleReducer);
  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");

  const standardRole = userRole?.includes(role.superAdmin);


  useEffect(() => {
    org_id && roleState?.product_id &&
      dispatch({
        type: GET_ALL_PERMISSIONS,
        payload: {
          org_id: org_id?.value,
          product_id: roleState?.product_id,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allPermissions = useSelector(
    (state) => state.PermissionReducer.permissions
  );

  const editValue = get(location, "state", "");

  const roleError = useSelector((state) => state.RoleReducer.isError);
  const roleLoader = useSelector((state) => state.RoleReducer.loader);
  const roleSuccess = useSelector((state) => state.RoleReducer.apiSuccess);
  const clientProducts = useSelector((state) => state.ClientReducer.products);
  const clients = useSelector((state) => state.ClientReducer.clients);

  const [formData, setFormData] = useState({
    name: "",
    standard: false,
  });
  const [type, setType] = useState("");
  const [roleType, setRoleType] = useState("");
  const [permission, setPermission] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [, forceUpdate] = useState();
  const [product_enabled, setProductEnabled] = useState("");
  const productOptions = getProductOptions(clientProducts);
  const [clientProductOptions, setClientProductOptions] = useState([]);

  const [selectClient, setSelectClient] = useState("");
  const [client, setClient] = useState("");

  const clientOptions = clientOption({ tenantId, clients });
  useEffect(() => {
    if (!isEmpty(tenantId)) {
      getClientByIdAPI(tenantId)
        .then((res) => {
          const { status, data } = res;
          if (status === 200) {
            setClientProductOptions(
              getProductOptions(data?.product_enabled, "product")
            );
            setClient(data?.id);
          }
        })
        .catch((err) => {
          setClient("");
          setClientProductOptions([]);
        });
    }
  }, [tenantId]);

  useEffect(() => {
    if (isSubmitted) {
      if (roleSuccess && !roleError) {
        navigate("/role");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleLoader, roleError, isSubmitted]);

  useEffect(() => {
    if (!isEmpty(editValue)) {

      const options = editValue.permissions.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      const standard = editValue.standard === true ? "Standard" : "Custom";

      setRoleType({ label: standard, value: standard.toLowerCase() });
      if (!editValue.standard) {
        setClient(editValue?.value);
        const selectedOrganization = clients.find((elem) => {
          return elem.id === editValue?.value;
        });
        if (selectedOrganization) {
          setSelectClient({
            label: selectedOrganization?.name,
            value: selectedOrganization?.id,
            products_enabled: selectedOrganization?.products_enabled
          })
          setClientProductOptions(getProductOptions(selectedOrganization.products_enabled, "product"));
        }
      }

      setFormData({
        ...formData,
        name: editValue.name,
        standard: editValue.standard,
      });

      setSelectedPermissions(options);
      setType(standard.toLowerCase());
    } else if (!isEmpty(tenantId)) {
      setType("custom");
      setRoleType({ label: "Custom", value: "custom" });
    }
    return () => {
      // to remove error message on mount
      validator.hideMessages()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const permissionsOptions = allPermissions.map((item) => ({
    label: item?.user_permission_name || item?.name,
    value: item.user_permission || item?.id,
  }));

  const typeOptions = [
    { value: "standard", label: "Standard" },
    { value: "custom", label: "Custom" },
  ];

  const addPermission = () => {
    let firstIndex = 0;
    let lastIndex = selectedPermissions?.length - 1;
    while (firstIndex <= lastIndex) {
      for (let i = 0; i < permission?.length; i++) {
        if (selectedPermissions[firstIndex]?.value === permission[i]?.value || selectedPermissions[lastIndex]?.value === permission[i]?.value) {
          toast.error(`${permission[i]?.label} Permission already added`);
          return;
        }
      }
      firstIndex++;
      lastIndex--;
    }
    setSelectedPermissions([...selectedPermissions, ...permission]);
    setPermission([]);
  };

  const removePermission = (item) => {
    const filteredPermissions = selectedPermissions.filter(
      (elem) => elem.value !== item.value
    );
    setSelectedPermissions(filteredPermissions);
  };

  const onSubmit = () => {
    if (validator.allValid()) {
      const permissionValues = selectedPermissions.map((item) => {
        return item.value;
      });
      const orgId = formData.standard ? "" : org_id?.value || tenantId;
      const body = {
        roleData: {
          name: formData.name,
          standard: formData.standard,
          permissions: permissionValues,
        },
        org_id: org_id?.value || tenantId,
      };
      dispatch({ type: "roles/resetIsError" });
      dispatch({ type: "roles/setLoaderTrue" });
      dispatch({ type: "roles/resetApiSuccess" });
      dispatch({ type: CREATE_ROLE, payload: body });
      setIsSubmitted(true);
    } else {
      validator.showMessages();
      forceUpdate(1);
      return false;
    }
  };

  const onUpdate = () => {
    if (validator.allValid()) {
      const permissionValues = selectedPermissions.map((item) => {
        return item.value;
      });

      const body = {
        roleData: {
          name: formData.name,
          standard: formData.standard,
          permissions: permissionValues,
          organization: null
        },
        org_id: formData.standard ? "" : editValue.organization,
        role_id: editValue.id,
      };
      dispatch({ type: "roles/resetIsError" });
      dispatch({ type: "roles/setLoaderTrue" });
      dispatch({ type: "roles/resetApiSuccess" });
      dispatch({ type: UPDATE_ROLE, payload: body });
      setIsSubmitted(true);
    } else {
      validator.showMessages();
      forceUpdate(1);
      return false;
    }
  };

  const handleMultiSelectChange = (selected, product_enabled) => {
    setPermission(selected.map((item) => ({ ...item, product: product_enabled?.label })));
  };


  return (
    <section
      className="bu-creation creation-container"
      style={{ height: `${offsetHeight}px` }}
    >
      {roleLoader && <LoaderWithLabel />}
      <h3 className="section-heading">
        {editValue ? t("Update Role") : t("Create Role")}
      </h3>
      <div className="section-breadcrumbs">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/role">
                <span className="section-breadcrumbs-a">Roles</span>
              </Link>
            </li>
            {!isEmpty(editValue) && (
              <li className="breadcrumb-item">
                <span className="section-breadcrumbs-a">
                  {editValue.name}
                </span>
              </li>
            )}
            <li className="breadcrumb-item active" aria-current="page">
              {editValue ? t("Update Role") : t("Create Role")}
            </li>
          </ol>
        </nav>
      </div>

      <div className="bu-creation-container">
        <div className="mb-4">
          <Grid container spacing={2.5}>
            <Grid item md={3} sm={3} xs={3}>
              <div>
                <InputField
                  label={"Role"}
                  name={"role"}
                  value={formData.name}
                  onChange={(event) =>
                    setFormData({ ...formData, name: event.target.value })
                  }
                />
                <CustomFieldValidator
                  validator={validator}
                  field={'Role'}
                  inputValue={formData.name}
                  validations={nameValidation}
                />
                {isEmpty(tenantId) && (
                  <Box sx={{ width: "100%", marginTop: "16px" }}>
                    <FormControl fullWidth>
                      <Select
                        theme={createThemeSelect}
                        value={roleType}
                        onChange={(value) => {
                          dispatch({
                            type: "permissions/resetPermissions",
                          });
                          setRoleType(value);
                          setType(value.value);
                          setFormData({
                            ...formData,
                            standard: value.value === "standard",
                          });

                          if (value.value === "standard") {
                            setClient("");
                            setSelectClient("");
                            dispatch({
                              type: "roles/setOrgIdSlice",
                              payload: null,
                            });
                          } else {
                            setProductEnabled("");
                          }
                          setPermission([]);
                          setSelectedPermissions([]);
                          setProductEnabled("");
                        }}
                        options={typeOptions}
                        isOptionDisabled={(option) => editValue && option}
                        classNamePrefix="mySelect"
                        placeholder="Role Type"
                        name="role_type"
                      />
                    </FormControl>
                    <CustomFieldValidator
                      validator={validator}
                      field={'Role Type'}
                      inputValue={type}
                      validations={["required"]}
                    />
                  </Box>
                )}
              </div>
            </Grid>
            {standardRole && (
              roleType?.value === "custom" && (
                <Grid item md={12} sm={12} xs={12}>
                  <Grid container spacing={2.5}>
                    <Grid item md={3} sm={3} xs={3}>
                      <Box sx={{ width: "100%" }}>
                        <Select
                          theme={createThemeSelect}
                          id="show"
                          name="slect_client"
                          placeholder="Select Client"
                          options={clientOptions}
                          isOptionDisabled={(option) => editValue && option}
                          value={selectClient}
                          onChange={(value) => {
                            dispatch({
                              type: "permissions/resetPermissions",
                            });
                            setClient(value?.value);
                            setSelectClient(value);
                            setClientProductOptions(getProductOptions(value?.products_enabled, "product"));
                            setProductEnabled("");
                            setPermission([]);
                            setSelectedPermissions([]);
                            dispatch({
                              type: "roles/setOrgIdSlice",
                              payload: value,
                            });
                            // dispatch({
                            //   type: GET_ALL_PERMISSIONS,
                            //   payload: value?.value,
                            // });
                          }}
                          classNamePrefix="mySelect"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}
            {((roleType?.value === "standard" || (roleType?.value === "custom" && selectClient)) || !standardRole) && (
              <Grid item md={12} sm={12} xs={12}>
                <Grid container spacing={2.5}>
                  <Grid item md={3} sm={3} xs={3}>
                    <Box sx={{ width: "100%" }}>
                      <Select
                        theme={createThemeSelect}
                        id="show"
                        name="select_product"
                        placeholder="Select Product"
                        options={roleType?.value === "standard" ? productOptions : clientProductOptions}
                        value={product_enabled}
                        onChange={(value) => {
                          setProductEnabled(value);
                          roleType?.value === "standard" ? dispatch({
                            type: GET_ALL_PRODUCTS_PERMISSIONS,
                            payload: value?.value,
                          }) : dispatch({
                            type: GET_ALL_PERMISSIONS,
                            payload: {
                              org_id: client,
                              product_id: value?.value,
                            },
                          });
                          dispatch({
                            type: "permissions/resetPermissions",
                          });
                          setPermission([]);
                        }}
                        classNamePrefix="mySelect"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {(product_enabled) && (
              <Grid item md={12} sm={12} xs={12}>
                <Grid container spacing={2.5}>
                  <Grid item md={3} sm={3} xs={3}>
                    <Box sx={{ width: "100%" }}>
                      {permissionsOptions?.length > 0 ? <MultiSelect
                        value={permission}
                        onChange={(selected) => handleMultiSelectChange(selected, product_enabled)}
                        options={permissionsOptions}
                        classNamePrefix="mySelect"
                        placeholder="Select Permission"
                        isSelectAll={true}
                      /> : "Loading..."}
                      <CustomFieldValidator
                        validator={validator}
                        field={'Permissions'}
                        inputValue={selectedPermissions}
                        validations={["required"]}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sm={3}
                    xs={3}
                    className="d-flex align-items-center"
                  >
                    {permission?.length > 0 && (
                      <button
                        data-testid="add_permission"
                        className="btn primary-button"
                        onClick={() => {
                          addPermission();
                        }}
                      >
                        <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      </button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item md={12} sm={12} xs={12}>
              <Grid container spacing={2.5}>
                <Grid item md={12} sm={12} xs={12}>
                  <div>
                    {selectedPermissions.map((item, key) => {
                      return (
                        <Tooltip key={`role-creation${key.toString()}`} title={item?.product} arrow placement="top">
                          <div className="permission-container">
                            <span className="roles-permissions">
                              {item.label}
                            </span>
                            <div className="permission-clear-button">
                              <i
                                className="fa fa-times permission-clear"
                                aria-hidden="true"
                                onClick={() => removePermission(item)}
                              ></i>
                            </div>
                          </div>
                        </Tooltip>
                      );
                    })}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="bu-creation-submit-container mt-4 mb-4 text-end">
        {!editValue ? (
          <button className="btn primary-button" onClick={onSubmit}>
            {t("Create")}
          </button>
        ) : (
          <button className="btn primary-button" onClick={onUpdate}>
            {t("Update")}
          </button>
        )}
        <button
          className="btn cancel-button ml-20"
          onClick={() => navigate("/role")}
        >
          {t("Cancel")}
        </button>
      </div>
    </section>
  );
};

export default RoleCreation;
