import React from "react";
import ClientList from './ClientList/ClientList';

const ClientPage = () => {
  return (
    <>
      <div>
        <ClientList />
      </div>
    </>
  );
};
export default ClientPage;
