import { get, isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { GET_USER } from "../../helper/localstorageReaderHelper";
import { GET_CLIENT_CONFIG } from "../../store/Types";
import { configPath } from "../../utils/Constants";
import ConfigurationCreation from "./ConfigurationCreation/ConfigurationCreation";
import "./ConfigurationCreation/ConfigurationCreation.scss";

const ConfigurationPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");
  const menuConfig = location.pathname === configPath;
  const tenantClient = useSelector((state) => state.ClientReducer.tenantClient);

  useEffect(() => {
    if (tenantId && menuConfig) {
      dispatch({ type: "client/resetClientError" });
      dispatch({
        type: GET_CLIENT_CONFIG,
        payload: tenantId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedClient = useSelector(
    (state) => state.ClientReducer.selectedClient
  );

  const clientConfiguration = useSelector(
    (state) => state.ClientReducer.clientConfiguration
  );

  const currentClient = isEmpty(tenantId) ? selectedClient : tenantClient;

  return (
    <>
      <section className="client-configure">
        <h3 className="section-heading">Client Configuration</h3>
        <ConfigurationCreation
          selectedClient={currentClient}
          clientConfiguration={clientConfiguration}
        />
      </section>
    </>
  );
};

export default ConfigurationPage;
