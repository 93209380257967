//Client
export const GET_ALL_CLIENTS = "GET_ALL_CLIENTS";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const CREATE_CLIENT = "CREATE_CLIENT";
export const GET_TENANT_CLIENT = "GET_TENANT_CLIENT";
export const GET_CLIENT_CONFIG = "GET_CLIENT_CONFIG";
export const GET_CLIENT_CONFIG_BY_BU = "GET_CLIENT_CONFIG_BY_BU";
export const UPDATE_CLIENT_CONFIG = "UPDATE_CLIENT_CONFIG";
export const UPDATE_CLIENT_CONFIG_BY_BU = "UPDATE_CLIENT_CONFIG_BY_BU";
export const EDIT_CLIENT = "EDIT_CLIENT";
export const CLIENT_ERROR = "CLIENT_ERROR";
export const CLEAR_CLIENT_STATE = "CLEAR_CLIENT_STATE";

export const GET_ALL_BUSINESS_UNITS = "GET_ALL_BUSINESS_UNITS";
export const CREATE_BUSINESSUNIT = "CREATE_BUSINESSUNIT";
export const EDIT_BUSINESSUNIT = "EDIT_BUSINESSUNIT";
export const UPLOAD_Business_CSV_DATA = "UPLOAD_Business_CSV_DATA";
export const BU_SELECT_CLIENT = "BU_SELECTED_CLIENT";
export const GET_BY_ID_BUSINESS_UNITS = "GET_BY_ID_BUSINESS_UNITS";

//Team
export const BU_GET_TEAMS_UNIT = "BU_GET_TEAMS_UNIT";

// Create Team
export const CREATE_TEAMS_UNIT = "CREATE_TEAMS_UNIT";
export const EDIT_TEAM_UNIT = "EDIT_TEAM_UNIT";

// Users
export const CREATE_USER = "CREATE_USER";
export const EDIT_USER = "EDIT_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_BY_TEAMS = "GET_ALL_USERS_BY_TEAMS";

//Roles
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";

//Permissions
export const GET_ALL_PERMISSIONS = "GET_ALL_PERMISSIONS";
export const GET_ALL_PRODUCTS_PERMISSIONS = "GET_ALL_PRODUCTS_PERMISSIONS";