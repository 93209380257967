import { process } from "@progress/kendo-data-query";
import { GridColumn as Column, Grid } from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import { isEmpty } from "lodash";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EDIT_CLIENT, GET_ALL_CLIENTS } from "../../../../store/Types";
import ActivateModal from "./../../../../components/ActivateModal/ActivateModal";
import "./ClientGrid.scss";
import ActivateText from "../../../../components/ProcessingLoader/ActivateText";

const ClientGrid = (props) => {
  const dispatch = useDispatch();
  const {
    setEditItem,
    rowLength,
    clientDetails,
    editItem,
    searchField,
    editPermission,
  } = props;

  const initialDataState = {
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
    take: rowLength,
    skip: 0,
  };

  const [dataState, setDataState] = useState(initialDataState);
  const [resultClient, setResultClient] = useState(null);
  const [isActive, SetIsActive] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setDataState({ ...dataState, take: rowLength });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowLength]);

  const handleActiveToggle = (active, e) => {
    SetIsActive(active);
    setVisible(true);
  };

  useEffect(() => {
    if (searchField) {
      setDataState({ ...dataState, skip: 0, take: rowLength });
      const filteredClientDetails = clientDetails.filter((client) => {
        return client.name.toLowerCase().includes(searchField.toLowerCase());
      });

      setResultClient(filteredClientDetails);
    } else {
      setResultClient(clientDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField, clientDetails, rowLength]);

  const handleClick = () => {
    if (isActive.is_active) {
      const deactivatedClient = {
        ...isActive,
        is_active: false,
        isStatus: true,
      };
      delete deactivatedClient.logo;
      dispatch({ type: EDIT_CLIENT, payload: deactivatedClient });
      setVisible(!visible);
    } else {
      const activatedClient = { ...isActive, is_active: true, isStatus: true };
      delete activatedClient.logo;
      dispatch({ type: EDIT_CLIENT, payload: activatedClient });
      setVisible(!visible);
    }
    setEditItem([]);

    setTimeout(() => {
      dispatch({ type: GET_ALL_CLIENTS });
    }, 500);
  };
  return (
    <div className="tablegrid">
      <ActivateModal
        active={isActive}
        open={visible}
        setOpen={setVisible}
        handleClick={handleClick}
      />
      {!isEmpty(resultClient) ? (
        <Grid
          pageable={true}
          sortable={true}
          data={process(resultClient, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.dataState);
          }}
        >
          <Column
            headerClassName="tablegrid-heading tablegrid-heading-no-sorting"
            width={50}
            cell={(props) => {
              const client = props.dataItem;
              return (
                <td className="p-0">
                  <input
                    type="checkbox"
                    className="d-flex m-auto"
                    value={editItem}
                    checked={editItem.includes(client)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setEditItem([client]);
                      } else {
                        setEditItem(
                          editItem.filter((item) => item.id !== client.id)
                        );
                      }
                    }}
                  />
                </td>
              );
            }}
          />
          <Column
            field="name"
            title="Client Name"
            headerClassName="tablegrid-heading"
            cell={(props) => {
              return (
                <td>
                  <span>{props.dataItem.name}</span>
                </td>
              );
            }}
          />
          <Column
            field="created_on"
            title="Created On"
            headerClassName="tablegrid-heading"
            cell={(props) => {
              return (
                <td>
                  <span>
                    {Moment(props.dataItem.created_on).format("DD MMM YYYY")}
                  </span>
                </td>
              );
            }}
          />
          <Column
            field="modified_on"
            title="Modified On"
            headerClassName="tablegrid-heading"
            cell={(props) => {
              return (
                <td>
                  <span>
                    {Moment(props.dataItem.modified_on).format("DD MMM YYYY")}
                  </span>
                </td>
              );
            }}
          />
          <Column
            field="status"
            title="Status"
            headerClassName="tablegrid-heading"
            cell={(props) => {
              return (
                <td className="tablegrid-status">
                  <span
                    className={
                      props.dataItem.is_active
                        ? "tablegrid-status-active"
                        : "tablegrid-status-inactive"
                    }
                  >
                    {props.dataItem.is_active ? "Active" : "In Active"}
                  </span>
                </td>
              );
            }}
          />
          <Column
            field="is_active"
            title={<ActivateText/>}
            headerClassName="tablegrid-heading"
            cell={(props) => {
              return (
                <td className="tablegrid-switch">
                  <span>
                    <Switch
                      onLabel={""}
                      offLabel={""}
                      size="small"
                      disabled={!editPermission}
                      checked={props.dataItem.is_active}
                      onChange={(e) => handleActiveToggle(props.dataItem, e)}
                    />
                  </span>
                </td>
              );
            }}
          />
        </Grid>
      ) : (
        <div className="text-center w-100">No records found</div>
      )}
    </div>
  );
};

export default ClientGrid;
