import { toast } from "react-toastify";
import { put, takeEvery } from "redux-saga/effects";
import {
  createUserAPI,
  editUserAPI,
  getAllUsersAPI,
  getAllUsersByTeamsAPI,
} from "../../apis";
import { createErrorToastMessages } from "../../utils/errorHandlingUtility";
import {
  createUsersSlice,
  editUserSlice,
  getAllUsersErrorSlice,
  getAllUsersSlice,
  loadingUserUnit,
  setUserError,
} from "../Slices/UserSlice";
import {
  CREATE_USER,
  EDIT_USER,
  GET_ALL_USERS,
  GET_ALL_USERS_BY_TEAMS,
} from "../Types";

export function* getAllUsersSaga(action) {
  try {
    yield put(loadingUserUnit(true));
    const users = yield getAllUsersAPI(action.payload);
    yield put(getAllUsersSlice(users.data.results));
  } catch (error) {
    toast.error(error.message);
    yield put(loadingUserUnit(true));
    yield put(getAllUsersErrorSlice());
  }
}

export function* getAllUsersByTeamSaga(action) {
  try {
    yield put(loadingUserUnit(true));
    const users = yield getAllUsersByTeamsAPI(action.payload);
    yield put(getAllUsersSlice(users.data.results));
  } catch (error) {
    toast.error(error.message);
    yield put(loadingUserUnit(true));
    yield put(getAllUsersErrorSlice());
  }
}

export function* createUserSaga(action) {
  try {
    const response = yield createUserAPI(action.payload);
    yield put(createUsersSlice(response.data));
    toast.success("User(s) Added Sucessfully");
  } catch (error) {
    console.log("user create error", error);
    createErrorToastMessages(error);
    yield put(setUserError());
  }
}

export function* editUserSaga(action) {
  try {
    const response = yield editUserAPI(action.payload);
    yield put(editUserSlice(response.data));
    toast.success(
      `Users ${action.payload?.isStatus
        ? action.payload?.is_active
          ? "Activated"
          : "Deactivated"
        : "Updated"
      } Sucessfully`
    );
  } catch (error) {
    createErrorToastMessages(error);
    yield put(setUserError());
  }
}

export function* watchUsersSaga() {
  yield takeEvery(CREATE_USER, createUserSaga);
  yield takeEvery(GET_ALL_USERS, getAllUsersSaga);
  yield takeEvery(GET_ALL_USERS_BY_TEAMS, getAllUsersByTeamSaga);
  yield takeEvery(EDIT_USER, editUserSaga);
}
