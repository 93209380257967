import { combineReducers } from "redux";
import ClientReducer from "../Slices/ClientSlice";
import BusinessUnitReducer from "../Slices/BusinessUnitSlice";
import TeamReducer from "../Slices/TeamSlice";
import UserReducer from "../Slices/UserSlice";
import RoleReducer from "../Slices/RoleSlice";
import PermissionReducer from '../Slices/PermissionSlice';
import ProgressReducer from "../Slices/loading";
export const rootReducer = combineReducers({
  ClientReducer,
  BusinessUnitReducer,
  TeamReducer,
  UserReducer,
  RoleReducer,
  PermissionReducer,
  ProgressReducer
});
