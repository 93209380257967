import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import CSVFileValidator from "csv-file-validator";
import isEmpty from "lodash/isEmpty";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import ContextProvider from "../ContextProvider";

export const useUploadFileHook = ({
  uploadFileToggle,
  handleCSVUpload,
  menuUser,
  handleBack,
  csvHeaderConfig,
  templateDataHandlerCallback,
  templateFileName,
}) => {
  const { t } = useTranslation();
  const { fullScreenEnabled } = useContext(ContextProvider);

  const [CsvUploadData, setCsvUploadData] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const [csvError, setcsvError] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const config = {
        headers: csvHeaderConfig, // required
        isHeaderNameOptional: true, // default (optional)
      };
      console.log("acceptedFiles[0]", acceptedFiles[0]);
      CSVFileValidator(acceptedFiles[0], config)
        .then((csvData) => {
          console.log("csvData", csvData);
          setCsvUploadData(csvData.data);
          // Array of error messages
          setcsvError(
            csvData.inValidData?.filter(
              (d) =>
                !(
                  typeof d.message == "string" &&
                  d.message.includes("Number of fields mismatch")
                )
            )
          );
        })
        .catch((err) => {
          console.log({ err });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const errorContent = () => {
    if (!isEmpty(csvError)) {
      return (
        <div className="bu-csv-error-container">
          {!isEmpty(acceptedFiles) &&
            !isEmpty(CsvUploadData) &&
            acceptedFiles[0].type === "text/csv" ? (
            <>
              <h5 className="text-center warning">
                {csvError.length} Errors Found
              </h5>{" "}
              <br />
              {csvError.map((item, index) => (
                <>
                  <span
                    key={`bu-csv-error-container-list${index.toString()}`}
                    className="warning"
                  >
                    {index + 1}. *{item.message}
                  </span>
                  <br />
                  <br />
                </>
              ))}
            </>
          ) : (
            <span className="warning">
              *Invalid format!, Please upload .csv file
            </span>
          )}
        </div>
      );
    }
  };

  const checkCsv = () => {
    let disabled = true;
    if (!isEmpty(files) && !isEmpty(CsvUploadData)) {
      if (isEmpty(csvError) && acceptedFiles[0].size > 0) {
        disabled = false;
      }
    }
    return disabled;
  };

  const removeAll = () => {
    setMyFiles([]);
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
  };

  const closeUploadDialog = () => {
    removeAll();
    setcsvError([]);
    setCsvUploadData([]);
    uploadFileToggle();
  };
  return (
    <Dialog id="window" appendTo={fullScreenEnabled ? null : document.body}>
      <span className="span1">Upload CSV</span>
      <span
        className="k-icon k-i-close k-btn1"
        id="close-upload-dialog"
        onClick={closeUploadDialog}
      ></span>
      <DialogActionsBar>
        <p className="border2">
          <div className="div2">
            <section className="container">
              <div {...getRootProps({ className: "dropzone" })}>
                <input
                  {...getInputProps()}
                  accept={".csv"}
                  type="file"
                  name="file"
                  data-testid="excle-file-upload"
                  id="upload-file"
                />
                <span
                  style={{ fontSize: "50px" }}
                  className="k-icon k-i-file-csv k-span"
                ></span>
                <h4 className="kh4">Select a CSV file to upload</h4>
                <p className="kp">or drag or drop it here</p>
              </div>
              <aside>
                <ul>{!isEmpty(myFiles) && files}</ul>
              </aside>

              {!isEmpty(myFiles) && errorContent()}

              {isEmpty(CsvUploadData) && !isEmpty(files) && (
                <span className="text-center warning">*Csv file Empty </span>
              )}
            </section>
          </div>
        </p>
      </DialogActionsBar>

      <DialogActionsBar>
        <CSVLink
          data={templateDataHandlerCallback()}
          filename={templateFileName}
        >
          <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base download-template-btn">
            {t("Download Template")}
            <span className="k-icon k-i-download"></span>
          </button>
        </CSVLink>

        {menuUser && (
          <button
            style={{
              fontWeight: "bold",
              fontSize: "1rem",
              borderRadius: ".65rem",
              padding: ".85rem",
              position: "relative",
              left: "8.4rem"
            }}
            className="btn cancel-button"
            onClick={handleBack}
          >
            {t("Back")}
          </button>
        )}

        <button
          data-testid="upload-file"
          className={`k-button k-button-md k-rounded-md k-button-solid k-button-solid-base download-csv-btn ${checkCsv() && "k-disabled"
            } `}
          onClick={() => {
            handleCSVUpload(CsvUploadData);
            setTimeout(() => removeAll(), 3000);
          }}
        >
          {t("Upload")}
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};
