import { toast } from "react-toastify";
import { put, takeEvery } from "redux-saga/effects";
import {
  createClientAPI,
  editClientAPI,
  getClientByIdAPI,
  getClientConfigAPI,
  getClientConfigByBUAPI,
  getClientsAPI,
  getProductsAPI,
  updateClientConfigAPI,
  updateClientConfigByBUAPI,
} from "../../apis";
import {
  addClientSlice,
  editClientSlice,
  getAllClientsSlice,
  getAllProductsSlice,
  getClientConfigError,
  getClientConfigSlice,
  lodingClientList,
  resetClientStateSlice,
  setClientError,
  tenantClientErrorSlice,
  tenantClientSlice,
} from "../Slices/ClientSlice";
import {
  CREATE_CLIENT,
  EDIT_CLIENT,
  GET_ALL_CLIENTS,
  GET_ALL_PRODUCTS,
  GET_CLIENT_CONFIG,
  GET_CLIENT_CONFIG_BY_BU,
  GET_TENANT_CLIENT,
  UPDATE_CLIENT_CONFIG,
  UPDATE_CLIENT_CONFIG_BY_BU,
} from "../Types";

const findErrorMessageType = (error) => {

  if (typeof error?.response?.data?.messages === "object") {
    return "object";
  } else if (
    typeof error?.response?.data?.message === "undefined" ||
    typeof error?.response?.data === "undefined"
  ) {
    return "undefined";
  }
};

const findErrorMessageTypeForCreation = (error) => {
  console.log("error?.response?.data !== undefined", error?.response?.data);
  console.log("error?.response?.data?.messages", error?.response?.data?.messages);
  console.log("error", error);
  let errorType;
  if (
    typeof error?.response?.data !== "undefined" &&
    typeof error?.response?.data?.messages === "object"
  ) {
    errorType = "object";
  } else if (Object.keys(error?.response?.data)?.length > 0) {
    errorType = "data-object";
  } else if (
    typeof error?.response?.data === "undefined" ||
    typeof error?.response?.data?.message === "undefined"
  ) {
    errorType = "undefined";
  } else {
    errorType = "none";
  }
  return errorType;
};

export function* getClientsSaga() {
  try {
    yield put(lodingClientList({ isLoading: true, msg: "" }));
    const clients = yield getClientsAPI();
    yield put(getAllClientsSlice(clients.data));
  } catch (error) {
    let errorMessageType = findErrorMessageType(error);
    if (errorMessageType === "object") {
      yield put(resetClientStateSlice());
      error.response.data.messages.map((item) => {
        return toast.error(`${item.message} `);
      });
    } else if (errorMessageType === "undefined") {
      toast.error(error.message);
      yield put(resetClientStateSlice());
    }
    yield put(lodingClientList({ isLoading: false, msg: "error" }));
    toast.error(error?.response?.data?.message);
    yield put(resetClientStateSlice());
  }
}
export function* getProductsSaga() {
  try {
    const products = yield getProductsAPI();
    yield put(getAllProductsSlice(products?.data?.results));
  } catch (error) {
    let errorMessageType = findErrorMessageType(error);
    if (errorMessageType === "object") {
      yield put(resetClientStateSlice());
      error.response.data.messages.map((item) => {
        return toast.error(`${item.message} `);
      });
    } else if (errorMessageType === "undefined") {
      toast.error(error.message);
      yield put(resetClientStateSlice());
    }
    toast.error(error.response.data.message);
    yield put(resetClientStateSlice());
  }
}

export function* createClientSaga(action) {
  try {
    const response = yield createClientAPI(action.payload);
    yield put(addClientSlice(response.data));
    toast.success("Client Added Sucessfully");
  } catch (error) {
    let errorMessageType = findErrorMessageTypeForCreation(error);
    if (errorMessageType === "object") {
      yield put(setClientError());
      error?.response?.data?.messages?.map((item) => {
        return toast.error(`${item.message} `);
      });
    } else if (errorMessageType === "data-object") {
      Object.keys(error?.response?.data)?.forEach((item) => {
        return toast.error(`${error?.response?.data[item]}`);
      });
      yield put(setClientError());
    } else if (errorMessageType === "undefined") {
      toast.error(`${error?.message}`);
      yield put(setClientError());
    } else {
      toast.error(`${error?.response?.data?.message}`);
      yield put(setClientError());
    }
  }
}

export function* editClientSaga(action) {
  try {
    const response = yield editClientAPI(action.payload);
    yield put(editClientSlice(response.data));
    toast.success(
      `Client ${action.payload?.isStatus
        ? action.payload?.is_active
          ? "Activated"
          : "Deactivated"
        : "Updated"
      }  Sucessfully`
    );
  } catch (error) {
    let errorMessageType = findErrorMessageTypeForCreation(error);

    if (errorMessageType === "object") {
      yield put(setClientError());
      error.response.data.messages.map((item) => {
        return toast.error(`${item.message} `);
      });
    } else if (errorMessageType === "data-object") {
      Object.keys(error.response.data).forEach((item) => {
        return toast.error(`${error.response.data[item]}`);
      });

      yield put(setClientError());
    } else if (errorMessageType === "undefined") {
      toast.error(`${error.message}`);
      yield put(setClientError());
    } else {
      toast.error(`${error.response.data.message}`);
      yield put(setClientError());
    }
  }
}

export function* getClientConfig(action) {
  try {
    const response = yield getClientConfigAPI(action.payload);
    yield put(getClientConfigSlice(response.data.responseData.response));
  } catch (error) {
    yield put(getClientConfigError());
    toast.error(`No Client Configuration data found`);
  }
}

export function* getClientConfigByBUSaga(action) {
  try {
    const response = yield getClientConfigByBUAPI(action.payload);
    yield put(getClientConfigSlice(response.data.responseData.response));
  } catch (error) {
    yield put(getClientConfigError());
    toast.error(`No Business Unit Configuration data found`);
  }
}

export function* getTenantClientSaga(action) {
  try {
    const response = yield getClientByIdAPI(action.payload);
    yield put(tenantClientSlice(response.data));
  } catch (error) {
    yield put(tenantClientErrorSlice());
    toast.error(`Client not found, try after some time`);
  }
}

export function* updateClientConfig(action) {
  try {
    yield put(lodingClientList({ isLoading: true, msg: "" }));
    yield updateClientConfigAPI(action.payload);
    yield put(lodingClientList({ isLoading: false, msg: "" }));
    toast.success(
      `The ${action.payload.config} configurations have been updated successfully!`
    );
  } catch (error) {
    yield put(lodingClientList({ isLoading: false, msg: "" }));
    yield put(setClientError());
    toast.error(`${error.message}, Please try after sometime`);
  }
}

export function* updateClientConfigByBU(action) {
  try {
    yield put(lodingClientList({ isLoading: true, msg: "" }));
    yield updateClientConfigByBUAPI(action.payload);
    yield put(lodingClientList({ isLoading: false, msg: "" }));
    toast.success(
      `The ${action.payload.config} configurations have been updated successfully!`
    );
  } catch (error) {
    yield put(lodingClientList({ isLoading: false, msg: "" }));
    yield put(setClientError());
    toast.error(`${error.message}, Please try after sometime`);
  }
}

export function* watchClientSaga() {
  yield takeEvery(GET_ALL_CLIENTS, getClientsSaga);
  yield takeEvery(GET_ALL_PRODUCTS, getProductsSaga);
  yield takeEvery(CREATE_CLIENT, createClientSaga);
  yield takeEvery(EDIT_CLIENT, editClientSaga);
  yield takeEvery(GET_CLIENT_CONFIG, getClientConfig);
  yield takeEvery(UPDATE_CLIENT_CONFIG, updateClientConfig);
  yield takeEvery(GET_TENANT_CLIENT, getTenantClientSaga);
  yield takeEvery(GET_CLIENT_CONFIG_BY_BU, getClientConfigByBUSaga);
  yield takeEvery(UPDATE_CLIENT_CONFIG_BY_BU, updateClientConfigByBU);
}
