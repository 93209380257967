import { Grid } from "@mui/material";
import { Button } from "@progress/kendo-react-buttons";
import { Switch } from "@progress/kendo-react-inputs";
import { get, groupBy, isEmpty } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ActivateModal from "../../../components/ActivateModal/ActivateModal";
import { GET_USER } from "../../../helper/localstorageReaderHelper";
import { GET_CLIENT_CONFIG_BY_BU } from "../../../store/Types";
import { permission } from "../../../utils/Constants";
import { firstLetterCapitalInWord } from "../../../utils/commonFun";
const BusinessUnitAccordian = (businessData) => {
  const [isActive, SetIsActive] = useState(null);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");
  const permissions = get(user, "permissions", []);
  const viewTeamPermission = permissions.includes(permission.teamView);
  const configurationPermission = permissions.includes(permission.buConfigure);
  const editPermission = permissions.includes(permission.buEdit);

  const currentClient = useSelector(
    (state) => state.BusinessUnitReducer.selectedClient
  );

  const tenantClient = useSelector((state) => state.ClientReducer.tenantClient);

  const selectedClient = isEmpty(tenantId) ? currentClient : tenantClient;

  const configureRedirect = (item) => {
    dispatch({
      type: "teams/selectedBUSlice",
      payload: item,
    });
    dispatch({ type: "client/setLoaderTrue" });
    dispatch({ type: "client/resetClientError" });

    const payload = {
      id: selectedClient?.id,
      bu_id: item.id,
    };

    dispatch({
      type: GET_CLIENT_CONFIG_BY_BU,
      payload: payload,
    });

    setTimeout(() => {
      navigate("/setup/configuration");
    }, 1000);
  };

  const onViewClick = (obj) => {
    dispatch({
      type: "teams/selectedBUSlice",
      payload: obj,
    });

    setTimeout(() => {
      navigate("/setup/team");
    }, 1000);
  };

  const onEditClick = (obj) => {
    navigate("/setup/edit", { state: obj });
  };
  const handleClick = () => {
    businessData.handleOnClick(isActive);
    setVisible(false);
  };
  const groupByCountry = groupBy(businessData.businessData, "country");
  const [toggleItem, setToggleItem] = useState([]);

  return (
    <div className="client-listing-grid">
      <ActivateModal
        active={isActive}
        open={visible}
        setOpen={setVisible}
        handleClick={handleClick}
      />
      <div className="panelcountry">
        <Grid container>
          {businessData?.businessData?.length > 0 ? (
            <>
              <div className="w-100 h-100">
                <table className="table table-bordered setup-table">
                  <thead>
                    <tr>
                      <th>Country</th>
                      <th>BU</th>
                      <th>Team</th>
                      <th>User</th>
                      <th className="BU-active-width">Activate/Deactivate</th>
                      <th colSpan={2} className="BU-action-width">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(groupByCountry)
                      .sort()
                      .map((item) => {
                        let team_count = 0;
                        let user_count = 0;

                        const dropRows = groupByCountry[item].map(
                          (elem, index) => {
                            team_count = team_count + elem.teams_count;
                            user_count = user_count + elem.users_count;
                            const hiddleClass = toggleItem.includes(item)
                              ? ""
                              : "d-none";
                            return (
                              <tr
                                key={`panelcountry${index.toString()}`}
                                id="1"
                                className={hiddleClass}
                              >
                                <td></td>
                                <td className="sub-td">{elem.name}</td>
                                <td className="sub-td">{elem.teams_count}</td>
                                <td className="sub-td">{elem.users_count}</td>
                                <td className="sub-td align-item-center">
                                  <Switch
                                    onLabel={""}
                                    id={`switch-container-${index}`}
                                    offLabel={""}
                                    checked={elem.is_active}
                                    size="small"
                                    onChange={() => {
                                      SetIsActive(elem);
                                      setVisible(true);
                                    }}
                                  />
                                </td>

                                <td colSpan={2} className="sub-td">
                                  {" "}
                                  {viewTeamPermission && (
                                    <Button
                                      className="setup-actions-button"
                                      onClick={() => onViewClick(elem)}
                                    >
                                      View
                                    </Button>
                                  )}
                                  {configurationPermission && (
                                    <Button
                                      className="setup-actions-button"
                                      onClick={() => configureRedirect(elem)}
                                    >
                                      Configure
                                    </Button>
                                  )}
                                  {editPermission && (
                                    <Button
                                      className="setup-actions-button"
                                      onClick={() => onEditClick(elem)}
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        );
                        return (
                          <>
                            <tr
                              onClick={() =>
                                setToggleItem((prevState) =>
                                  prevState.includes(item)
                                    ? prevState.filter((elem) => elem !== item)
                                    : [...prevState, item]
                                )
                              }
                            >
                              <td>
                                {toggleItem.includes(item) ? (
                                  <span className="setup-con-actions">
                                    <i className="fa fa-minus-square" />
                                  </span>
                                ) : (
                                  <span className="setup-con-actions">
                                    <i className="fa fa-plus-square" />
                                  </span>
                                )}

                                {firstLetterCapitalInWord(item)}
                              </td>
                              <td>{groupByCountry[item].length}</td>
                              <td>{team_count}</td>
                              <td>{user_count}</td>
                              <td></td>
                              <td colSpan={2}></td>
                            </tr>
                            {dropRows}
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="text-center w-100">No Records Found</div>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default BusinessUnitAccordian;
