import EditIcon from "@mui/icons-material/Edit";
import { process } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { GridColumn as Column, Grid } from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ActivateModal from "../../../../components/ActivateModal/ActivateModal";
import { GET_USER } from "../../../../helper/localstorageReaderHelper";
import { UPDATE_ROLE } from "../../../../store/Types";
import ActivateText from "../../../../components/ProcessingLoader/ActivateText";

const RoleGrid = (props) => {
  const { roleDetails, rowLength, searchField, editItem, setEditItem, editPermission, selectClient } =
    props;
  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");

  const data = roleDetails;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialDataState = {
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
    take: rowLength,
    skip: 0,
  };

  const [dataState, setDataState] = useState(initialDataState);
  const [isActive, SetIsActive] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setDataState({ ...dataState, take: rowLength });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowLength]);

  useEffect(() => {
    if (searchField) {
      setDataState({ ...dataState, skip: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField, data]);

  const handleClick = () => {
    const permissionValues = isActive.permissions.map((item) => {
      return item.id;
    });

    const body = {
      roleData: {
        name: isActive.name,
        standard: isActive.standard,
        permissions: permissionValues,
        organization: null,
      },
      org_id: isActive.organization,
      role_id: isActive.id,
    };

    if (isActive.is_active) {
      body.roleData.is_active = false;
      body.roleData.isStatus = true;
      dispatch({ type: UPDATE_ROLE, payload: body });
      setVisible(!visible);
    } else {
      body.roleData.is_active = true;
      body.roleData.isStatus = true;
      dispatch({ type: UPDATE_ROLE, payload: body });
      setVisible(!visible);
    }
  };

  const onEditClick = (role) => {
    navigate("/role/edit", { state: { ...role, ...selectClient } });
  };

  const handleActiveToggle = (active, e) => {
    SetIsActive(active);
    setVisible(true);
  };

  return (
    <div className="tablegrid">
      <ActivateModal
        active={isActive}
        open={visible}
        setOpen={setVisible}
        handleClick={handleClick}
      />
      {!isEmpty(roleDetails) ? (
        <Grid
          pageable={true}
          sortable={true}
          data={process(roleDetails, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.dataState);
          }}
        >

          <Column
            field="name"
            title="Role"
            headerClassName="tablegrid-heading"
          />

          <Column
            field="permissions"
            title="Permission"
            headerClassName="tablegrid-heading"
            width={"450px"}
            cell={(props) => {
              return (
                <td>
                  {props.dataItem.permissions.map((item, idx) => {
                    return (
                      <span key={idx.toString()} className="roles-permissions">{item.name} </span>
                    );
                  })}
                </td>
              );
            }}
          />

          <Column
            field="role_type"
            title="Role Type"
            headerClassName="tablegrid-heading"
            cell={(props) => {
              return <td>{props.dataItem.standard ? "Standard" : "Custom"}</td>;
            }}
          />
          {editPermission && (
            <Column
              title="Action"
              headerClassName="tablegrid-heading"
              cell={(props) => {
                const role = props.dataItem;
                return (
                  <td className="tablegrid-switch">
                    <span>
                      <Button
                        style={!props.dataItem.standard ? { background: "primary" } : isEmpty(tenantId) ? { background: "primary" } : { background: "primary", display: 'none' }}
                        onClick={() => onEditClick(role)}
                      >
                        <EditIcon className="editteam" />
                      </Button>
                    </span>
                  </td>
                );
              }}
            />
          )}

          <Column
            field="is_active"
            title={<ActivateText/>}
            headerClassName="tablegrid-heading"
            cell={(props) => {
              return (
                <td className="tablegrid-switch">
                  <Switch
                    onLabel={""}
                    offLabel={""}
                    size="small"
                    disabled={!editPermission || (props.dataItem.standard && !isEmpty(tenantId))}
                    checked={props.dataItem.is_active}
                    onChange={(e) => handleActiveToggle(props.dataItem, e)}
                  />
                </td>
              );
            }}
          />
        </Grid>
      ) : (
        <span className="text-center d-flex justify-content-center w-100">
          No records found
        </span>
      )}
    </div>
  );
};

export default RoleGrid;
