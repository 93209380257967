import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  error: false,
  errorMsg: "",
  isEdit: false,
  clientEdit: null,
  clients: [],
  selectedClient: null,
  clientConfiguration: null,
  tenantClient: null,
  apiSuccess: false,
  products: [],
};

export const ClientSlice = createSlice({
  name: "client",
  initialState: initialState,
  reducers: {
    lodingClientList: (state, action) => {
      state.isLoading = action?.payload?.isLoading ?? false;
      state.msg = action?.payload?.msg ?? "";
    },
    getAllClientsSlice: (state, action) => {
      state.clients = action.payload;
      state.loader = false;
      state.isLoading = false;
      state.msg = "success";
      return state;
    },
    getAllProductsSlice: (state, action) => {
      state.products = action.payload;
      state.loader = false;
      return state;
    },

    addClientSlice: (state, action) => {
      state.clients = [action.payload, ...state.clients];
      state.loader = false;
      state.apiSuccess = true;
      state.error = false;
      return state;
    },
    editClientSlice: (state, action) => {
      state.clients = state.clients.map((i) =>
        i.id === action.payload.id ? action.payload : i
      );
      state.loader = false;
      state.apiSuccess = true;
      state.error = false;
      return state;
    },
    resetClientStateSlice: (state) => {
      state.clients = [];
      state.products = [];
      state.error = true;
      return state;
    },
    selectedClientSlice: (state, action) => {
      state.selectedClient = action.payload;
      return state;
    },
    tenantClientSlice: (state, action) => {
      state.tenantClient = action.payload;
      return state;
    },
    tenantClientErrorSlice: (state) => {
      state.tenantClient = null;
      return state;
    },
    getClientConfigSlice: (state, action) => {
      state.clientConfiguration = action.payload;
      state.loader = false;
      return state;
    },
    initiateClientConfigSlice: (state) => {
      state.clientConfiguration = null;
      return state;
    },
    updateClientConfigSlice: (state, action) => {
      state.loader = false;
      return state;
    },
    getClientConfigError: (state) => {
      state.clientConfiguration = null;
      state.error = true;
      return state;
    },
    setLoaderTrue: (state) => {
      state.loader = true;
    },
    setClientError: (state) => {
      state.error = true;
      state.loader = false;
      return state;
    },
    resetClientError: (state) => {
      state.error = false;
      return state;
    },
    resetApiSuccess: (state) => {
      state.apiSuccess = false;
      return state;
    },
  },
});

export const {
  lodingClientList,
  getAllClientsSlice,
  resetClientStateSlice,
  addClientSlice,
  editClientSlice,
  selectedClientSlice,
  getClientConfigSlice,
  setLoaderTrue,
  getClientConfigError,
  updateClientConfigSlice,
  resetClientError,
  setClientError,
  initiateClientConfigSlice,
  tenantClientSlice,
  tenantClientErrorSlice,
  getAllProductsSlice,
} = ClientSlice.actions;

export default ClientSlice.reducer;
