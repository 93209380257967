import { toast } from "react-toastify";
import { put, takeEvery } from "redux-saga/effects";
import { createTeamUnitAPI, editTeamAPI, getAllTeamUnitAPI } from "../../apis";
import { createErrorToastMessages } from "../../utils/errorHandlingUtility";
import {
  createTeamUnitSlice,
  editTeamSlice,
  getAllTeamUnitSlice,
  loadingTeamUnit,
  resetTeamsSlice,
  setTeamError,
} from "../Slices/TeamSlice";
import { BU_GET_TEAMS_UNIT, CREATE_TEAMS_UNIT, EDIT_TEAM_UNIT } from "../Types";

export function* getTeamSaga(action) {
  try {
    yield put(loadingTeamUnit(true));
    console.log("getTeamSaga call", action.payload);
    const teams = yield getAllTeamUnitAPI(action.payload);
    yield put(getAllTeamUnitSlice(teams.data));
  } catch (error) {
    console.log("getTeamSaga error", error);
    yield put(loadingTeamUnit(false));
    yield put(resetTeamsSlice());
    toast.error(error.message);
  }
}

export function* createTeamSaga(action) {
  try {
    const response = yield createTeamUnitAPI(action.payload);
    yield put(createTeamUnitSlice(response.data));
    toast.success("Team Unit(s) Added Sucessfully");
  } catch (error) {
    createErrorToastMessages(error);
    yield put(setTeamError());
  }
}

export function* editTeamSaga(action) {
  try {
    yield editTeamAPI(action.payload);
    yield put(editTeamSlice(action.payload));
    toast.success(
      `Team Unit(s) ${action.payload?.isStatus
        ? action.payload?.is_active
          ? "Activated"
          : "Deactivated"
        : "Updated"
      } Sucessfully`
    );
  } catch (error) {
    createErrorToastMessages(error);
    yield put(setTeamError());
  }
}

export function* watchTeamSaga() {
  yield takeEvery(BU_GET_TEAMS_UNIT, getTeamSaga);
  yield takeEvery(CREATE_TEAMS_UNIT, createTeamSaga);
  yield takeEvery(EDIT_TEAM_UNIT, editTeamSaga);
}
