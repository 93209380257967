import React from "react";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { phoneInputValidateMsg } from "../../utils/Constants";

export const convertLocalNumber = (num) => {
  let localNumber = formatPhoneNumber(num);
  localNumber = localNumber.replace(/^0+/, "");
  localNumber = localNumber.replaceAll(" ", "");
  localNumber = localNumber.replaceAll("-", "");
  localNumber = localNumber.replaceAll("(", "");
  localNumber = localNumber.replaceAll(")", "");
  return localNumber;
};

export const getPhoneCountryCode = (num) => {
  const interNational = formatPhoneNumberIntl(num);
  const localNumberArray = interNational.split(" ");
  return localNumberArray[0];
};

const CustomPhoneInput = ({ placeholder, value, onChange, className }) => {
  return (
    <>
      <PhoneInput
        placeholder={placeholder}
        value={value}
        withCountryCallingCode={true}
        onChange={onChange}
        international
        defaultCountry="IN"
        className={className}
      />
      <span className="bu-helper-text-phone-number">{phoneInputValidateMsg}</span>
    </>
  );
};

export default CustomPhoneInput;
