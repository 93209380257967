import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  error: false,
  errorMsg: "",
  ErrorCode: "",
  isEdit: false,
  selectedBU: null,
  selectedTeam: null,
  createdUsers: 0,
  selectedUser: null,
  users: [],
  menuUser: false,
  selectedClientId: "",
  apiSuccess: false,
};

export const UsersSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    loadingUserUnit: (state, action) => {
      state.isLoading = action.payload;
    },
    getAllUsersSlice: (state, action) => {
      state.users = action.payload;
      state.loader = false;
      state.isLoading = false;
      return state;
    },
    selectedTeamSlice: (state, action) => {
      state.selectedTeam = action.payload;
      state.isLoading = true;
      return state;
    },
    resetSelectedTeamSlice: (state) => {
      state.selectedTeam = null;
      state.isLoading = false;
      return state;
    },
    createUsersSlice: (state, action) => {
      state.users = [...state.users, ...action.payload];
      state.loader = false;
      state.apiSuccess = true;
      state.isLoading = false;
      state.error = false;
      state.createdUsers = action.payload.length;
      return state;
    },
    editUserSlice: (state, action) => {
      state.users = state.users.map((i) =>
        i.id === action.payload.id ? action.payload : i
      );
      state.loader = false;
      state.apiSuccess = true;
      state.isLoading = false;
      state.error = false;
      return state;
    },
    selectedUserSlice: (state, action) => {
      state.selectedUser = action.payload;
      state.isLoading = false;
      return state;
    },
    userCSVUploadInitiate: (state) => {
      state.csvUpload = false;
      return state;
    },
    userCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.csvUploadResponseData = action.payload;
      return state;
    },
    userCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      return state;
    },
    getAllUsersErrorSlice: (state) => {
      state.users = [];
      state.error = true;
      state.loader = true;
      return state;
    },
    setLoaderTrue: (state) => {
      state.loader = true;
      state.isLoading = true;
      return state;
    },
    setUserError: (state) => {
      state.error = true;
      state.loader = false;
      state.isLoading = false;
      return state;
    },
    resetUsersError: (state) => {
      state.error = false;
      return state;
    },
    setMenuUser: (state, action) => {
      state.menuUser = action.payload;
      return state;
    },
    resetApiSuccess: (state) => {
      state.apiSuccess = false;
      return state;
    },
    setSelectedClientId: (state, action) => {
      state.selectedClientId = action.payload;
      return state;
    },
    resetSelectedClientId: (state) => {
      state.selectedClientId = null;
      return state;
    },
    resetAllUsers: (state) => {
      state.users = [];
      return state;
    },
  },
});

export const {
  getAllUsersSlice,
  createUsersSlice,
  getAllUsersErrorSlice,
  editUserSlice,
  userCSVUploadInitiate,
  userCSVUploadComplete,
  userCSVUploadError,
  selectedTeamSlice,
  selectedUserSlice,
  setUserError,
  setLoaderTrue,
  resetUsersError,
  setSelectedClientId,
  setMenuUser,
  loadingUserUnit
} = UsersSlice.actions;

export default UsersSlice.reducer;
