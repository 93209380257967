import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import Breadcrumb from "../../../components/Breadcrumb";
import CustomFieldValidator from "../../../components/CustomFieldValidator";
import CustomPhoneInput from "../../../components/CustomPhoneInput";
import LoaderWithLabel from "../../../components/ProcessingLoader/LoaderWithLabel";
import InputField from "../../../globalComponent/InputField";
import {
  getUpdatedFields,
  handleChangeAccordion,
  handleFormChange,
  handleInput,
  handleOptionsChange,
  updateAccordionState,
} from "../../../helper/commonHelper";
import { GET_USER } from "../../../helper/localstorageReaderHelper";
import { useCommonHook } from "../../../hooks/useCommonHook";
import {
  BU_GET_TEAMS_UNIT,
  CREATE_USER,
  GET_ALL_BUSINESS_UNITS,
  GET_ALL_ROLES,
} from "../../../store/Types";
import { updatedCities, updatedCountries, updatedStates } from "../../../utils";
import {
  createThemeSelect,
  cryptoRandom,
  nameValidation,
} from "../../../utils/Constants";
import "./UserCreation.scss";
const validator = new SimpleReactValidator();

const UserCreation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");
  const [expandedAccordion, setExpandedAccordion] = useState([]);

  const selectedTeam = useSelector((state) => state.UserReducer.selectedTeam);
  const menuUser = useSelector((state) => state.UserReducer.menuUser);

  const currentClient = useSelector((state) => state.BusinessUnitReducer.selectedClient);

  const businessUnits = useSelector((state) => state.BusinessUnitReducer.businessUnits);

  const allTeams = useSelector((state) => state.TeamReducer.teams);
  const allRoles = useSelector((state) => state.RoleReducer.roles);
  const fromMenu = isEmpty(selectedTeam);

  const tenantClient = useSelector((state) => state.ClientReducer.tenantClient);
  const selectedClient = isEmpty(tenantId) ? currentClient : tenantClient;
  useEffect(() => {
    dispatch({ type: GET_ALL_BUSINESS_UNITS, payload: selectedClient });
    dispatch({ type: GET_ALL_ROLES, payload: selectedClient.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  const [inputFields, setInputFields] = useState([
    {
      id: cryptoRandom(),
      organization: isEmpty(tenantId) ? selectedClient.id : tenantId,
      first_name: "",
      last_name: "",
      email: "",
      mobile_country_code: "",
      mobile: "",
      team: fromMenu ? "" : selectedTeam.id,
      teamObject: fromMenu ? null : selectedTeam,
      business_unit: fromMenu ? "" : selectedTeam.business_unit.id,
      roles: [],
      address_line1: "",
      address_line2: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    },
  ]);

  const [options, setOptions] = useState([
    {
      teamOptions: [],
    },
  ]);

  const [, forceUpdate] = useState();

  const [formIndex, SetFormIndex] = useState(0);

  const offsetHeight = window.innerHeight - 100;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const userError = useSelector((state) => state.UserReducer.error);
  const userLoader = useSelector((state) => state.UserReducer.loader);
  const userSuccess = useSelector((state) => state.UserReducer.apiSuccess);

  useEffect(() => {

    if (isSubmitted && userSuccess && !userError) {
      menuUser ? navigate("/user") : navigate("/setup/team/user");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSuccess, userError, isSubmitted]);

  useCommonHook(inputFields, setExpandedAccordion);
  const { values, setValues } = useFormik({
    initialValues: [
      {
        country: "India",
        state: null,
        city: null,
      },
    ],
  });

  const businessUnitOptions =
    fromMenu &&
    businessUnits
      .filter((item) => item.is_active)
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));

  const teamsOptions =
    fromMenu &&
    allTeams
      .filter((item) => item.is_active)
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));

  useEffect(() => {
    let optionsData = [...options];
    let data = [...inputFields];
    if (data[formIndex]["business_unit"] && teamsOptions) {
      optionsData[formIndex]["teamOptions"] = [...teamsOptions];
      setOptions(optionsData);
    }

    return () => {
      // to remove error message on mount
      validator.hideMessages()
    }
  }, [allTeams]);

  const rolesOptions = allRoles
    .filter((item) => item.is_active)
    .map((item) => ({
      label: item.name,
      value: item.id,
    }));

  const handleBUDropdown = (bu_id, index) => {
    const selectedBU = businessUnits.find((obj) => obj.id === bu_id);
    dispatch({ type: BU_GET_TEAMS_UNIT, payload: selectedBU });
    let data = [...inputFields];
    data[index]["business_unit"] = bu_id;
    data[index]["team"] = "";
    data[index]["teamObject"] = null;
    setInputFields(data);
    SetFormIndex(index);
  };

  const handleTeamsDropdown = (team, index) => {
    let data = [...inputFields];
    data[index]["team"] = team.value;
    data[index]["teamObject"] = team;
    setInputFields(data);
  };

  const handleRoleChange = (value, index) => {
    const roles = value.map((item) => item.value);
    let data = [...inputFields];
    data[index]["roles"] = roles;
    setInputFields(data);
  };

  const onSubmit = () => {
    if (validator.allValid()) {
      const updatedInputFields = getUpdatedFields(inputFields);
      const payload = {
        formData: updatedInputFields,
        org_id: selectedClient.id,
      };

      dispatch({ type: "users/setLoaderTrue" });
      dispatch({ type: "users/resetUsersError" });
      dispatch({ type: "users/resetApiSuccess" });

      dispatch({ type: CREATE_USER, payload: payload });
      setIsSubmitted(true);
    } else {
      validator.showMessages();
      forceUpdate(1);
      updateAccordionState(inputFields, setExpandedAccordion);
      return false;
    }
  };
  const addFields = () => {
    let newfield = {
      id: cryptoRandom(),
      organization: isEmpty(tenantId) ? selectedClient.id : tenantId,
      first_name: "",
      last_name: "",
      email: "",
      mobile_country_code: "",
      mobile: "",
      team: fromMenu ? "" : selectedTeam.id,
      teamObject: fromMenu ? null : selectedTeam,
      business_unit: fromMenu ? "" : selectedTeam.business_unit.id,
      roles: [],
      address_line1: "",
      address_line2: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    };
    setInputFields([...inputFields, newfield]);

    let newOptionsFields = {
      country: "India",
      state: null,
      city: null,
    };
    setValues([...values, newOptionsFields]);
    let newOptions = {
      teamOptions: [],
    };
    setOptions([...options, newOptions]);
  };

  const deleteForm = (input, e) => {
    e.preventDefault();
    setInputFields((prevState) => prevState.filter((e) => e !== input));
  };

  const isEmptyTeanantIdBreadCrumbs =
    !menuUser && isEmpty(tenantId)
      ? [
        {
          name: "Clients",
          path: "/client",
        },
        selectedClient.name,
      ]
      : [];

  const breadCrumbsList = menuUser
    ? [
      {
        name: "Users",
        path: "/user",
      },
      "Create User",
    ]
    : [
      ...isEmptyTeanantIdBreadCrumbs,
      {
        name: "Setup",
        path: "/setup",
      },
      selectedTeam.business_unit.country,
      selectedTeam.business_unit.name,
      {
        name: "Teams",
        path: "/setup/team",
      },
      selectedTeam.name,
      {
        name: "Users",
        path: "/setup/team/user",
      },
      "Create User",
    ];



  return (
    <>
      <section
        className="bu-creation creation-container"
        style={{ height: `${offsetHeight}px` }}
      >
        {userLoader && <LoaderWithLabel />}
        <h3 className="section-heading">{t("Create User")}</h3>
        <Breadcrumb list={breadCrumbsList} />
        <h3 className="section-subheading">{t("User Information")}</h3>
        <div>
          <form>
            {inputFields.map((input, index) => {
              return (
                <div
                  key={`User Information${index.toString()}`}
                  className="mb-2 accordion-container"
                >
                  <Accordion
                    defaultExpanded
                    expanded={expandedAccordion.includes(input?.id)}
                    onChange={handleChangeAccordion(input?.id)}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{ color: "white", fontSize: "1.25rem" }}
                        />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        borderRadius: "0.5rem",
                        minHeight: "3rem",
                        maxHeight: "3rem",
                        "&.Mui-expanded": {
                          minHeight: "3rem",
                          maxHeight: "3rem",
                        },
                      }}
                    >
                      <Typography>{`User ${index + 1}*`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="bu-creation-container">
                        <div className="mb-4">
                          <Grid container spacing={2.5}>
                            <Grid item md={3} sm={6} xs={12}>
                              <InputField
                                label={"First Name*"}
                                name={"first_name"}
                                value={input.first_name}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputFields,
                                    setInputFields
                                  )
                                }
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"First Name"}
                                inputValue={input.first_name}
                                validations={nameValidation}
                              />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                              <InputField
                                label={"Last Name*"}
                                name={"last_name"}
                                value={input.last_name}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputFields,
                                    setInputFields
                                  )
                                }
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"Last Name"}
                                inputValue={input.last_name}
                                validations={nameValidation}
                              />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                              <InputField
                                label={"Email ID*"}
                                name={"email"}
                                value={input.email}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputFields,
                                    setInputFields
                                  )
                                }
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"Email"}
                                inputValue={input.email}
                                validations={[
                                  "required",
                                  { max: "125" },
                                  { email: "email" },
                                ]}
                              />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                              <CustomPhoneInput
                                placeholder="Contact Number**"
                                value={input.mobile}
                                withCountryCallingCode={true}
                                onChange={(val) =>
                                  handleInput(
                                    index,
                                    val,
                                    "mobile",
                                    inputFields,
                                    setInputFields
                                  )
                                }
                                className="phone-input"
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"Contact Number"}
                                inputValue={input.mobile}
                                validations={[
                                  "required",
                                  "phone",
                                  { max: "13" },
                                ]}
                              />
                            </Grid>

                            <Grid item md={3} sm={6} xs={12}>
                              <InputField
                                label={"Client"}
                                name={"client"}
                                disabled={true}
                                value={selectedClient.name}
                              />
                            </Grid>

                            <Grid item md={3} sm={6} xs={12}>
                              {fromMenu ? (
                                <>
                                  <Select
                                    theme={createThemeSelect}
                                    id="bu"
                                    name="bu"
                                    placeholder="Select Business Unit*"
                                    options={businessUnitOptions}
                                    onChange={(value) => {
                                      handleBUDropdown(value.value, index);
                                    }}
                                    classNamePrefix="mySelect"
                                  />
                                  <CustomFieldValidator
                                    validator={validator}
                                    field={"Business Unit"}
                                    inputValue={input.business_unit}
                                    validations={["required"]}
                                  />
                                </>
                              ) : (
                                <InputField
                                  label={"Business Unit"}
                                  disabled={true}
                                  name={"bu"}
                                  value={selectedTeam.business_unit.name}
                                />
                              )}
                            </Grid>

                            <Grid item md={3} sm={6} xs={12}>
                              {fromMenu ? (
                                <>
                                  <Select
                                    theme={createThemeSelect}
                                    id="team"
                                    name="team"
                                    placeholder="Select Team*"
                                    value={input.teamObject}
                                    options={options[index].teamOptions}
                                    onChange={(value) => {
                                      handleTeamsDropdown(value, index);
                                    }}
                                    classNamePrefix="mySelect"
                                  />
                                  <CustomFieldValidator
                                    validator={validator}
                                    field={"Team"}
                                    inputValue={input.team}
                                    validations={["required"]}
                                  />
                                </>
                              ) : (
                                <InputField
                                  label={"Team"}
                                  disabled={true}
                                  sx={{ width: "100%" }}
                                  name={"team"}
                                  value={selectedTeam.name}
                                />
                              )}
                            </Grid>

                            <Grid item md={3} sm={6} xs={12}>
                              <Select
                                theme={createThemeSelect}
                                id="role"
                                name="role"
                                isMulti={true}
                                native
                                placeholder="Select Role"
                                options={rolesOptions}
                                onChange={(value) => {
                                  handleRoleChange(value, index);
                                }}
                                classNamePrefix="mySelect"
                                className="product-select"
                              />
                            </Grid>

                            <Grid item md={3} sm={6} xs={12}>
                              <InputField
                                label={"Address Line 1*"}
                                name={"address_line1"}
                                value={input.address_line1}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputFields,
                                    setInputFields
                                  )
                                }
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"Address Line 1"}
                                inputValue={input.address_line1}
                                validations={["required", { max: "500" }]}
                              />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                              <InputField
                                label={"Address Line 2*"}
                                name={"address_line2"}
                                value={input.address_line2}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputFields,
                                    setInputFields
                                  )
                                }
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"Address Line 2"}
                                inputValue={input.address_line2}
                                validations={["required", { max: "500" }]}
                              />
                            </Grid>

                            <Grid item md={3} sm={6} xs={12}>
                              {" "}
                              <Select
                                theme={createThemeSelect}
                                classNamePrefix="mySelect"
                                placeholder="Country*"
                                label="Country*"
                                className="bu-creation-select"
                                id="country"
                                name="country"
                                options={updatedCountries}
                                value={input.country}
                                onChange={(value) => {
                                  handleInput(
                                    index,
                                    value,
                                    "country",
                                    inputFields,
                                    setInputFields
                                  );
                                  handleInput(
                                    index,
                                    null,
                                    "city",
                                    inputFields,
                                    setInputFields
                                  );
                                  handleOptionsChange(
                                    index,
                                    value,
                                    "country",
                                    values,
                                    setValues
                                  );
                                  handleInput(
                                    index,
                                    null,
                                    "state",
                                    inputFields,
                                    setInputFields
                                  );
                                }}
                              />{" "}
                              <div className="mt-3">
                                <CustomFieldValidator
                                  validator={validator}
                                  inputValue={input.country}
                                  validations={["required"]}
                                  field={"Country"}
                                />
                              </div>
                            </Grid>

                            <Grid item md={3} sm={6} xs={12}>
                              {" "}
                              <Select
                                theme={createThemeSelect}
                                classNamePrefix="mySelect"
                                className="bu-creation-select"
                                options={updatedStates(
                                  values[index].country
                                    ? values[index].country.value
                                    : null
                                )}
                                id="state"
                                name="state"
                                placeholder="State*"
                                value={input.state}
                                onChange={(value) => {
                                  handleInput(
                                    index,
                                    value,
                                    "state",
                                    inputFields,
                                    setInputFields
                                  );
                                  handleOptionsChange(
                                    index,
                                    value,
                                    "state",
                                    values,
                                    setValues
                                  );
                                  handleInput(
                                    index,
                                    null,
                                    "city",
                                    inputFields,
                                    setInputFields
                                  );
                                }}
                              />
                              <div className="mt-3">
                                <CustomFieldValidator
                                  validator={validator}
                                  inputValue={input.state}
                                  validations={["required"]}
                                  field={"State"}
                                />
                              </div>
                            </Grid>

                            <Grid item md={3} sm={6} xs={12}>
                              <Select
                                theme={createThemeSelect}
                                classNamePrefix="mySelect"
                                className="bu-creation-select"
                                id="city"
                                name="city"
                                placeholder="City"
                                options={updatedCities(
                                  values[index].state
                                    ? values[index].state.value
                                    : null
                                )}
                                value={input.city}
                                onChange={(value) => {
                                  handleInput(
                                    index,
                                    value,
                                    "city",
                                    inputFields,
                                    setInputFields
                                  );
                                  handleOptionsChange(
                                    index,
                                    value,
                                    "city",
                                    values,
                                    setValues
                                  );
                                }}
                              />
                            </Grid>

                            <Grid item md={3} sm={6} xs={12}>
                              <InputField
                                label={"PIN Code*"}
                                name={"pincode"}
                                value={input.pincode}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputFields,
                                    setInputFields
                                  )
                                }
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"Pincode"}
                                inputValue={input.pincode}
                                validations={"required|numeric|min:4|max:125"}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        {index !== 0 && (
                          <div>
                            <button
                              className="bu-create-delete"
                              onClick={(e) => deleteForm(input, e)}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </div>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </form>
        </div>
        <div className="bu-create-container creation-container-body m-1 mt-2">
          <button className="btn primary-button" onClick={addFields}>
            {" "}
            {t("Add New User")}
          </button>
        </div>
        <div className="bu-creation-submit-container mt-4 text-end">
          <button className="btn primary-button" onClick={onSubmit}>
            {t("Create")}
          </button>
          <button
            className="btn cancel-button ml-20"
            onClick={() =>
              menuUser ? navigate("/user") : navigate("/setup/team/user")
            }
          >
            {t("Cancel")}
          </button>
        </div>
      </section>
    </>
  );
};

export default UserCreation;
