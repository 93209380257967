import "font-awesome/css/font-awesome.min.css";
import { createBrowserHistory } from "history";
import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContextProvider from "./ContextProvider";
import Routing from "./routers/Routing";
import "./style/sass/main.scss";

const defaultHistory = createBrowserHistory();

function App() {
  const [fullScreenEnabled, setfullScreenEnabled] = useState(
    !!document.fullscreenElement
  );

  document.addEventListener("fullscreenchange", () => {
    setfullScreenEnabled(!!document.fullscreenElement);
  });

  return (
    <div className="App">
      <BrowserRouter history={defaultHistory}>
        <ContextProvider.Provider value={{ fullScreenEnabled }}>
          <Routing />
          <ToastContainer style={{ marginTop: "10rem", marginRight: "5rem" }} />
        </ContextProvider.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
