import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  errorMsg: "",
  ErrorCode: "",
  isError: false,
  roles: [],
  org_id: null,
  product_id: null,
  apiSuccess: false,
};

export const RolesSlice = createSlice({
  name: "roles",
  initialState: initialState,
  reducers: {
    setOrgIdSlice: (state, action) => {
      state.org_id = action.payload;
      return state;
    },
    getAllRolesSlice: (state, action) => {
      state.roles = action.payload;
      state.loader = false;
      return state;
    },
    getAllRolesErrorSlice: (state) => {
      state.roles = [];
      state.isError = true;
      return state;
    },
    createRoleSlice: (state, action) => {
      state.roles = [action.payload, ...state.roles];
      state.loader = false;
      state.apiSuccess = true;
      state.isError = false;
      return state;
    },
    updateRoleSlice: (state, action) => {
      state.roles = state.roles.map((i) =>
        i.id === action.payload.id ? action.payload : i
      );
      state.loader = false;
      state.apiSuccess = true;
      state.isError = false;
      return state;
    },

    updateRoleErrorSlice: (state, action) => {
      state.isError = true;
      state.loader = false;
      state.errorMsg = action.payload;
      return state;
    },
    createRoleSliceError: (state, action) => {
      state.isError = true;
      state.loader = false;
      state.errorMsg = action.payload;
      return state;
    },
    setLoaderTrue: (state) => {
      state.loader = true;
      return state;
    },
    resetIsError: (state) => {
      state.isError = false;
      return state;
    },
    resetApiSuccess: (state) => {
      state.apiSuccess = false;
      return state;
    },
  },
});

export const {
  getAllRolesSlice,
  getAllRolesErrorSlice,
  createRoleSlice,
  createRoleSliceError,
  updateRoleSlice,
  updateRoleErrorSlice,
  setOrgIdSlice,
  setLoaderTrue,
} = RolesSlice.actions;

export default RolesSlice.reducer;
