import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactJson from "react-json-view";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import LoaderWithLabel from "../../../components/ProcessingLoader/LoaderWithLabel";
import { GET_USER } from "../../../helper/localstorageReaderHelper";
import {
  GET_CLIENT_CONFIG,
  GET_CLIENT_CONFIG_BY_BU,
  UPDATE_CLIENT_CONFIG,
  UPDATE_CLIENT_CONFIG_BY_BU,
} from "../../../store/Types";
import { configPath, permission } from "../../../utils/Constants";
import TabChangeModel from "./../../../components/TabChangeModel/TabChangeModel";
import "./ConfigurationCreation.scss";

const ConfigurationCreation = (props) => {
  const { selectedClient, clientConfiguration } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const config = [];
  const [editMode, setEditMode] = useState(false);
  const [tabs, setTabs] = useState(config[0]);
  const [visible, setVisible] = useState(false);
  const menuConfig = location.pathname === configPath;
  const confirmationMessage = "You have unsaved changes. Continue?";
  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");
  const selectedBU = useSelector((state) => state.TeamReducer.selectedBU);
  const [isEdited, setIsEdited] = useState(null);
  const permissions = get(user, "permissions", []);
  const { loader } = useSelector((state) => state.ClientReducer);
  const editPermission = menuConfig
    ? permissions.includes(permission.clientEditConfiguration)
    : permissions.includes(permission.buEditConfiguration);

  const useConfirmTabClose = (isUnsafeTabClose) => {
    useEffect(() => {
      const handleBeforeUnload = (event) => {
        if (isUnsafeTabClose) {
          event.returnValue = confirmationMessage;
          return confirmationMessage;
        }
      };

      window.addEventListener("beforeunload", handleBeforeUnload);
      return () =>
        window.removeEventListener("beforeunload", handleBeforeUnload);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUnsafeTabClose, isEdited]);
  };

  useConfirmTabClose(editMode);
  !isEmpty(clientConfiguration) &&
    Object.keys(clientConfiguration).forEach((item) => {
      config.push(item);
    });

  const currentTab =
    !isEmpty(clientConfiguration) && clientConfiguration[`${tabs}`];

  const [currentConfig, setCurrentConfig] = useState(currentTab);
  const [initialSet, setInitialSetup] = useState(true);

  useEffect(() => {
    if (!isEmpty(clientConfiguration) && initialSet) {
      setCurrentConfig(clientConfiguration[`${config[0]}`]);
      initialSet && setTabs(config[0]);
      setInitialSetup(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientConfiguration, config]);

  const onEditConfig = (e) => {
    setIsEdited(e.new_value);
    setCurrentConfig(e.updated_src);
  };

  const jsonOperations = editMode
    ? {
      onEdit: (edit) => onEditConfig(edit),
      onDelete: (e) => onEditConfig(e),
      onAdd: (add) => onEditConfig(add),
      theme: "harmonic",
    }
    : {};

  const handleTabClick = (item) => {
    setTabs(item);
    setCurrentConfig(clientConfiguration[`${item}`]);
    setEditMode(false);
  };

  const onCancel = () => {
    setEditMode(false);
    setCurrentConfig(clientConfiguration[`${tabs}`]);
  };

  const onSave = () => {
    dispatch({ type: "client/setLoaderTrue" });
    const bu_id = isEmpty(selectedBU) ? "" : selectedBU.id;

    const body = {
      id: selectedClient.id,
      config: tabs,
      formData: currentConfig,
    };

    const updateBody = {
      id: selectedClient.id,
      bu_id: bu_id,
      config: tabs,
      formData: currentConfig,
    };

    const payload = {
      id: selectedClient.id,
      bu_id: bu_id,
    };

    dispatch({ type: "client/resetClientError" });

    menuConfig
      ? dispatch({ type: UPDATE_CLIENT_CONFIG, payload: body })
      : dispatch({ type: UPDATE_CLIENT_CONFIG_BY_BU, payload: updateBody });

    setTimeout(() => {
      dispatch({ type: "client/resetClientError" });
      menuConfig
        ? dispatch({ type: GET_CLIENT_CONFIG, payload: selectedClient.id })
        : dispatch({
          type: GET_CLIENT_CONFIG_BY_BU,
          payload: payload,
        });

      setEditMode(false);
    }, 1000);
  };

  return (
    <>
      <TabChangeModel open={visible} setOpen={setVisible} />
      {loader && <LoaderWithLabel />}
      <div className="section-breadcrumbs">
        <nav aria-label="breadcrumb">
          {isEmpty(tenantId) ? (
            menuConfig ? (
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/client" className="section-breadcrumbs-a">
                    Clients
                  </a>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  {selectedClient.name}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Configuration
                </li>
              </ol>
            ) : (
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/client" className="section-breadcrumbs-a">
                    Clients
                  </a>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  {selectedClient.name}
                </li>
                <li className="breadcrumb-item">
                  <Link className="section-breadcrumbs-a" to="/setup">
                    Setup
                  </Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  {selectedBU.country}
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  {selectedBU.name}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Configuration
                </li>
              </ol>
            )
          ) : menuConfig ? (
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Configuration
              </li>
            </ol>
          ) : (
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="section-breadcrumbs-a" to="/setup">
                  Setup
                </Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                {selectedBU.country}
              </li>
              <li className="breadcrumb-item" aria-current="page">
                {selectedBU.name}
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Configuration
              </li>
            </ol>
          )}
        </nav>
      </div>

      {!isEmpty(clientConfiguration) ? (
        <div className="client-configure-main ">
          <div className="client-configure-main-tabs mt-2">
            {config.map((item) => {
              const activeClass =
                tabs === item ? "primary-button" : "light-button";
              return (
                <span
                  key={item}
                  className={`btn ${activeClass} m-2`}
                  onClick={() => {
                    if (editMode) {
                      setVisible(true);
                    } else {
                      handleTabClick(item);
                    }
                  }}
                >
                  {item}
                </span>
              );
            })}
          </div>
          <div className="client-configure-main-content">
            <div className="client-configure-main-content-header">
              <div className="config-title">{t(`${tabs}`)}</div>
              <div className="config-edit">
                {editPermission && (
                  <i
                    className="fa fa-pencil"
                    onClick={() => {
                      setEditMode(!editMode);
                    }}
                  ></i>
                )}
              </div>
            </div>

            <div className="client-configure-main-content-editor">
              <ReactJson
                src={currentConfig}
                {...jsonOperations}
                className="styletest"
              />
            </div>

            {editMode && (
              <div className="client-configure-main-content-submit-buttons">
                <button className="btn primary-button" onClick={() => onSave()}>
                  Save
                </button>
                <button
                  className="btn cancel-button ml-5"
                  onClick={() => onCancel()}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className="text-center w-100"
          style={{ background: "white", padding: "1rem" }}
        >
          No records found
        </div>
      )}
    </>
  );
};

export default ConfigurationCreation;
