import React from "react"
import Typography from "@mui/material/Typography";
import { Dialog } from "@progress/kendo-react-dialogs";
import "./style.scss";
import { useSelector } from "react-redux";
import { selectProgress } from "../../store/Slices/loading";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import LoaderGif from "../../assets/Loader.gif";
import ContextProvider from "../../ContextProvider";
import { useContext } from "react";

const duration = 1000;

export default function LoaderWithLabel() {
  const { isProgress, progressValue } = useSelector(selectProgress);
  const { fullScreenEnabled } = useContext(ContextProvider);
  const progress = Math.abs(progressValue > 5 ? progressValue - 1 : progressValue);
  return (
    <Dialog closeIcon={false} className={"custom-dialog-loader"} appendTo={fullScreenEnabled ? null : document.body}>
      {isProgress ? (
        <>
          <img src={LoaderGif} alt="loader" />
          <div className="progress-div">
            <div>
              <ProgressBar
                labelVisible={false}
                value={progress}
                animation={{
                  duration: duration,
                }}
              />
            </div>
            <Typography
              className="typography"
              variant="caption"
              component="div"
            >
              {`${Math.round(progress)}%`}
            </Typography>
          </div>
        </>
      ) : (
        <img src={LoaderGif} alt="loader" />
      )}
    </Dialog>
  );
}
