import { put, takeEvery } from "redux-saga/effects";
import {
  createBUAPI,
  editBUAPI,
  getAllBusinessUnitsAPI,
  uploadCSVDataAPI,
} from "../../apis";
import {
  addBUSlice,
  businessCSVUploadError,
  editBUSlice,
  getAllBusinessUnitSlice,
  lodingBusinessUnit,
  resetBusinessUnitStateSlice,
  setBUError,
  setLoaderFalse,
} from "../Slices/BusinessUnitSlice";

import { toast } from "react-toastify";
import { createErrorToastMessages } from "../../utils/errorHandlingUtility";
import {
  CREATE_BUSINESSUNIT,
  EDIT_BUSINESSUNIT,
  GET_ALL_BUSINESS_UNITS,
  UPLOAD_Business_CSV_DATA,
} from "../Types";

export function* getBusinessUnitSaga(action) {
  try {
    yield put(lodingBusinessUnit(true));
    const business = yield getAllBusinessUnitsAPI(action.payload);
    yield put(getAllBusinessUnitSlice(business.data));
  } catch (error) {
    console.log("error", error);
    yield put(lodingBusinessUnit(false));
    if (typeof error?.response?.data?.messages === "object") {
      yield put(resetBusinessUnitStateSlice());
      error.response.data.messages.map((item) => {
        return toast.error(`${item.message} `);
      });
    } else if (
      typeof error?.response?.data?.message === "undefined" ||
      typeof error?.response?.data === "undefined"
    ) {
      yield put(resetBusinessUnitStateSlice());
      toast.error(error.message);
    }
    yield put(resetBusinessUnitStateSlice());
    toast.error(error?.response?.data?.message);
  }
}

export function* createBUSaga(action) {
  try {
    const response = yield createBUAPI(action.payload);
    yield put(addBUSlice(response.data));
    yield put(setLoaderFalse(false));
    toast.success("Business Unit(s) Added Sucessfully");
  } catch (error) {
    createErrorToastMessages(error);
    yield put(setBUError());
  }
}

export function* editBUSaga(action) {
  try {
    const response = yield editBUAPI(action.payload);
    yield put(editBUSlice(response.data));
    if (action.payload?.isStatus)
      yield getBusinessUnitSaga({
        payload: action.payload?.selectedClient,
      });
    toast.success(
      `Business Unit ${action.payload?.isStatus
        ? action.payload?.formData?.is_active
          ? "Activated"
          : "Deactivated"
        : "Updated"
      }  Sucessfully`
    );
  } catch (error) {
    yield put(setBUError());
    toast.error(`${error.response.data.non_field_errors}`);
  }
}

export function* uploadBusinessCSVSaga(action) {
  try {
    const res = yield uploadCSVDataAPI(action.payload);
  } catch (error) {
    yield put(businessCSVUploadError(error?.response?.data?.responseData));
  }
}

export function* watchBusinessUnitSaga() {
  yield takeEvery(GET_ALL_BUSINESS_UNITS, getBusinessUnitSaga);
  yield takeEvery(CREATE_BUSINESSUNIT, createBUSaga);
  yield takeEvery(EDIT_BUSINESSUNIT, editBUSaga);
  yield takeEvery(UPLOAD_Business_CSV_DATA, uploadBusinessCSVSaga);
}
