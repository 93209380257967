import EditIcon from "@mui/icons-material/Edit";
import { process } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { GridColumn as Column, Grid } from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ActivateModal from "../../../../components/ActivateModal/ActivateModal";
import { EDIT_USER } from "../../../../store/Types";
import { userPath } from "../../../../utils/Constants";
import ActivateText from "../../../../components/ProcessingLoader/ActivateText";

const UserGrid = (props) => {
  const { userDetail, rowLength, searchField, editPermission } = props;
  const data = userDetail;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialDataState = {
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
    take: rowLength,
    skip: 0,
  };

  const [dataState, setDataState] = useState(initialDataState);
  const [isActive, SetIsActive] = useState(null);
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const menuUser = location.pathname === userPath;

  useEffect(() => {
    setDataState({ ...dataState, take: rowLength });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowLength]);

  useEffect(() => {
    if (searchField) {
      setDataState({ ...dataState, skip: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField, data]);

  const onEditClick = (user) => {
    dispatch({
      type: "users/selectedUserSlice",
      payload: user,
    });

    setTimeout(() => {
      navigate("/user/edit");
    }, 1000);
  };

  const handleClick = () => {
    const editedUser = {
      ...isActive,
    };

    delete editedUser.roles;
    delete editedUser.team;

    const payload = {
      ...editedUser,
      business_unit: isActive.business_unit.id,
      organization: isActive.organization.id,
      team: isActive.team.id,
    };
    dispatch({ type: "users/setLoaderTrue" });
    if (isActive.is_active) {
      const deactivatedUser = { ...payload, is_active: false, isStatus: true };

      dispatch({ type: EDIT_USER, payload: deactivatedUser });
      setVisible(!visible);
    } else {
      const activatedUser = { ...payload, is_active: true, isStatus: true };
      dispatch({ type: EDIT_USER, payload: activatedUser });
      setVisible(!visible);
    }
  };

  return (
    <div className="tablegrid">
      <ActivateModal
        active={isActive}
        open={visible}
        setOpen={setVisible}
        handleClick={handleClick}
      />

      {!isEmpty(userDetail) ? (
        <Grid
          pageable={true}
          sortable={true}
          data={process(userDetail, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.dataState);
          }}
        >
          <Column
            field="first_name"
            title="First Name"
            headerClassName="tablegrid-heading"
          />
          <Column
            field="last_name"
            title="Last Name"
            headerClassName="tablegrid-heading"
          />
          <Column
            field="email"
            title="Email Id"
            headerClassName="tablegrid-heading"
          />
          {menuUser && (
            <Column
              field="business_unit.name"
              title="BU"
              headerClassName="tablegrid-heading"
            />
          )}
          {menuUser && (
            <Column
              field="team.name"
              title="Team"
              headerClassName="tablegrid-heading"
            />
          )}
          <Column
            field=""
            title="Contact"
            headerClassName="tablegrid-heading"
            cell={(props) => {
              return (
                <td className="tablegrid-switch">
                  <span>
                    {props.dataItem.mobile_country_code} {props.dataItem.mobile}
                  </span>
                </td>
              );
            }}
          />

          <Column
            field="roles[0].name"
            title="Roles"
            headerClassName="tablegrid-heading"
            cell={(props) => {
              const role = props.dataItem.roles;
              return (
                <td className="tablegrid-switch">
                  <span>
                    {role.map((item, index) => {
                      return <> {item.name}{role?.length !== ++index && ","}</>;
                    })}
                  </span>
                </td>
              );
            }}
          />

          <Column
            field="is_active"
            title={<ActivateText/>}
            headerClassName="tablegrid-heading"
            cell={(props) => {
              return (
                <td className="tablegrid-switch">
                  <span>
                    <Switch
                      onLabel={""}
                      offLabel={""}
                      size="small"
                      checked={props.dataItem.is_active}
                      disabled={!editPermission}
                      onChange={() => {
                        SetIsActive(props.dataItem);
                        setVisible(true);
                      }}
                    />
                  </span>
                </td>
              );
            }}
          />
          {editPermission && (
            <Column
              title="Action"
              headerClassName="tablegrid-heading"
              cell={(props) => {
                const team = props.dataItem;
                return (
                  <td className="tablegrid-switch">
                    <span>
                      <Button
                        style={{ background: "primary" }}
                        onClick={() => onEditClick(team)}
                      >
                        <EditIcon className="editteam" />
                      </Button>
                    </span>
                  </td>
                );
              }}
            />
          )}
        </Grid>
      ) : (
        <span className="text-center d-flex justify-content-center w-100">
          No records found
        </span>
      )}
    </div>
  );
};

export default UserGrid;
