export const getItem = (key) => {
  if (key) {
    const res = localStorage.getItem(key);
    return res && JSON.parse(res);
  }
  return {};
};

export const user = getItem("user");

export const userRole = user?.roles || [];
