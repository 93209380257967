import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import CustomFieldValidator from "../../../components/CustomFieldValidator";
import CustomPhoneInput, {
  convertLocalNumber,
  getPhoneCountryCode,
} from "../../../components/CustomPhoneInput";
import LoaderWithLabel from "../../../components/ProcessingLoader/LoaderWithLabel";
import InputField from "../../../globalComponent/InputField";
import { GET_USER } from "../../../helper/localstorageReaderHelper";
import { BU_GET_TEAMS_UNIT, EDIT_USER } from "../../../store/Types";
import { updatedCities, updatedCountries, updatedStates } from "../../../utils";
import { createThemeSelect, nameValidation } from "../../../utils/Constants";
import "./UserEdit.scss";
const validator = new SimpleReactValidator();

const UserEdit = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, forceUpdate] = useState();

  const [editCountry, SetEditCountry] = useState("");
  const [editState, SetEditState] = useState("");
  const [editCity, SetEditCity] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const selectedUser = useSelector((state) => state.UserReducer.selectedUser);
  const userError = useSelector((state) => state.UserReducer.error);
  const userLoader = useSelector((state) => state.UserReducer.loader);
  const userSuccess = useSelector((state) => state.UserReducer.apiSuccess);
  const menuUser = useSelector((state) => state.UserReducer.menuUser);
  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");

  useEffect(() => {
    if (isSubmitted) {
      if (userSuccess && !userError) {
        menuUser ? navigate("/user") : navigate("/setup/team/user");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoader, userError, isSubmitted]);

  const contactNumber = selectedUser.mobile_country_code.concat(
    selectedUser.mobile
  );

  const businessUnits = useSelector(
    (state) => state.BusinessUnitReducer.businessUnits
  );

  const allTeams = useSelector((state) => state.TeamReducer.teams);
  let teamsOptions = [];
  let businessUnitOptions = [];
  if (menuUser) {
    businessUnitOptions = businessUnits
      .filter((item) => item.is_active)
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));
    teamsOptions = allTeams
      .filter((item) => item.is_active)
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));
  }

  const businessUnit = {
    label: selectedUser.business_unit.name,
    value: selectedUser.business_unit.id,
  };

  const team = { label: selectedUser.team.name, value: selectedUser.team.id };

  const [formData, setFormData] = useState({
    organization: selectedUser.organization.id,
    first_name: selectedUser.first_name,
    last_name: selectedUser.last_name,
    email: selectedUser.email,
    mobile_country_code: selectedUser.mobile_country_code,
    mobile:
      (isEmpty(convertLocalNumber(contactNumber)) ||
        isEmpty(getPhoneCountryCode(contactNumber)))
        ? ""
        : contactNumber,
    team: menuUser ? team : selectedUser.team.id,
    business_unit: menuUser ? businessUnit : selectedUser.business_unit.id,
    address_line1: selectedUser.address_line1,
    address_line2: selectedUser.address_line2,
    country: selectedUser.country,
    state: selectedUser.state,
    city: selectedUser.city,
    pincode: selectedUser.pincode,
    roles: [],
  });

  const [roles, setRoles] = useState([]);

  const allRoles = useSelector((state) => state.RoleReducer.roles);

  const handleBUDropdown = (value) => {
    const selectedBU = businessUnits.find((obj) => obj.id === value.value);
    dispatch({ type: BU_GET_TEAMS_UNIT, payload: selectedBU });
    setFormData({
      ...formData,
      business_unit: value,
    });
  };

  const handleTeamsDropdown = (value) => {
    setFormData({ ...formData, team: value });
  };

  const updatedRoles = (selectedUser, allRoles) => {
    let updatedArray = [];
    selectedUser.roles.forEach((item) => {
      let obj = {};
      allRoles.map((roles) => {
        if (item && item.id.toString() === roles.id.toString()) {
          obj.value = roles.id;
          obj.label = roles.name;
        }
        return null;
      });
      updatedArray.push(obj);
    });
    return updatedArray;
  };

  useEffect(() => {
    const updatedRoleOption = updatedRoles(selectedUser, allRoles);
    setRoles(updatedRoleOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rolesOptions = allRoles
    .filter((item) => item.is_active)
    .map((item) => ({
      label: item.name,
      value: item.id,
    }));

  useEffect(() => {
    if (!isEmpty(selectedUser)) {
      const contactNumber = selectedUser.mobile_country_code.concat(
        selectedUser.mobile
      );

      let localNumber = convertLocalNumber(contactNumber);
      let countryCode = getPhoneCountryCode(contactNumber);

      const selectedBU = businessUnits.find(
        (obj) => obj.id === selectedUser.business_unit.id
      );
      dispatch({ type: BU_GET_TEAMS_UNIT, payload: selectedBU });

      const data = {
        ...formData,
        organization: selectedUser.organization.id,
        first_name: selectedUser.first_name,
        last_name: selectedUser.last_name,
        email: selectedUser.email,
        mobile_country_code: selectedUser.mobile_country_code,
        mobile: countryCode + localNumber,
        business_unit: menuUser ? businessUnit : selectedUser.business_unit.id,
        address_line1: selectedUser.address_line1,
        address_line2: selectedUser.address_line2,
        country: selectedUser.country,
        state: selectedUser.state,
        city: selectedUser.city,
        pincode: selectedUser.pincode,
      };
      setFormData(data);

      const selectedCountry = updatedCountries.find((elem) => {
        return elem.label === selectedUser.country;
      });

      const selectedStates = !isEmpty(selectedCountry)
        ? updatedStates(selectedCountry.id)
        : [];

      const selectedState = !isEmpty(selectedCountry)
        ? selectedStates.find((elem) => {
          return elem.label === selectedUser.state;
        })
        : [];

      SetEditCountry({ label: selectedUser.country });
      SetEditState({ label: selectedUser.state });
      SetEditCity({ label: selectedUser.city });
      setValues(
        {
          country: selectedCountry,
          state: selectedState,
          city: selectedUser.city,
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const addressFromik = useFormik({
    initialValues: {
      country: "India",
      state: null,
      city: null,
    },
  });

  const { values, setFieldValue, setValues } = addressFromik;

  const offsetHeight = window.innerHeight - 100;

  useEffect(() => {
    const roleData = roles.map((item) => item.value);
    setFormData({ ...formData, roles: roleData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  const onSubmit = () => {
    if (validator.allValid()) {
      //formatting phone number input
      let localNumber = convertLocalNumber(formData.mobile);
      let countryCode = getPhoneCountryCode(formData.mobile);
      const payload = { ...formData };
      payload.mobile = localNumber;
      payload.mobile_country_code = countryCode;
      payload.business_unit = formData.business_unit.value;
      payload.team = menuUser ? formData.team.value : selectedUser.team.id;

      const editedUser = { ...selectedUser, ...payload };

      dispatch({ type: "users/resetUsersError" });
      dispatch({ type: "users/setLoaderTrue" });
      dispatch({ type: "users/resetApiSuccess" });

      dispatch({ type: EDIT_USER, payload: editedUser });
      setIsSubmitted(true);
    } else {
      validator.showMessages();
      forceUpdate(1);
      return false;
    }
  };

  return (
    <section className="bu-creation" style={{ height: `${offsetHeight}px` }}>
      {userLoader && <LoaderWithLabel />}
      <h3 className="section-heading"> {t("Update User")}</h3>
      <div className="section-breadcrumbs">
        <nav aria-label="breadcrumb">
          {menuUser && (
            <ol className="breadcrumb">
              <li className="breadcrumb-item" aria-current="page">
                <Link
                  to={menuUser ? "/user" : "/setup/team/user"}
                  className="section-breadcrumbs-a"
                >
                  Users
                </Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                {selectedUser.name}
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {t("Update User")}
              </li>
            </ol>
          )}

          {!menuUser && (
            <ol className="breadcrumb">
              {isEmpty(tenantId) && (
                <>
                  <li className="breadcrumb-item">
                    <Link to="/client" className="section-breadcrumbs-a">
                      Clients
                    </Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {selectedUser.organization.name}
                  </li>
                </>
              )}
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/setup" className="section-breadcrumbs-a">
                  Setup
                </Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                {selectedUser.business_unit.country}
              </li>
              <li className="breadcrumb-item" aria-current="page">
                {selectedUser.business_unit.name}
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to="/setup/team" className="section-breadcrumbs-a">
                  Teams
                </Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                {selectedUser.team.name}
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link
                  to={menuUser ? "/user" : "/setup/team/user"}
                  className="section-breadcrumbs-a"
                >
                  Users
                </Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                {selectedUser.name}
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {t("Update User")}
              </li>
            </ol>
          )}
        </nav>
      </div>
      <div className="bu-create-container m-1">
        <h3 className="section-subheading">{t("User Information")}</h3>
      </div>
      <div className="bu-creation-container">
        <div className="mb-4">
          <Grid container spacing={2.5}>
            <Grid item md={3} sm={6} xs={12}>
              <InputField
                label={"First Name*"}
                name={"first_name"}
                value={formData.first_name}
                onChange={(event) => onChange(event)}
              />
              <CustomFieldValidator
                validator={validator}
                field={"First Name"}
                inputValue={formData.first_name}
                validations={nameValidation}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputField
                label={"Last Name*"}
                name={"last_name"}
                value={formData.last_name}
                onChange={(event) => onChange(event)}
              />
              <CustomFieldValidator
                validator={validator}
                field={"Last Name"}
                inputValue={formData.last_name}
                validations={nameValidation}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputField
                label={"Email ID*"}
                disabled={true}
                name={"email"}
                value={formData.email}
                onChange={(event) => onChange(event)}
              />
              <CustomFieldValidator
                validator={validator}
                field={"Email"}
                inputValue={formData.email}
                validations={["required", { max: "125" }, { email: "email" }]}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <CustomPhoneInput
                placeholder="Contact Number**"
                value={formData.mobile}
                withCountryCallingCode={true}
                onChange={(val) => setFormData({ ...formData, mobile: val })}
                className="phone-input"
              />
              <CustomFieldValidator
                validator={validator}
                field={"Contact Number"}
                inputValue={formData.mobile}
                validations={["required", "phone", { max: "13" }]}
              />
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              {" "}
              <InputField
                label={"Client"}
                name={"password"}
                disabled={true}
                value={selectedUser.organization.name}
                onChange={(event) => onChange(event)}
              />
              <CustomFieldValidator
                validator={validator}
                field={"Country"}
                inputValue={formData.organization}
                validations={["required"]}
              />
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              {menuUser ? (
                <>
                  <Select
                    theme={createThemeSelect}
                    id="show"
                    name="show"
                    placeholder="Select Business Unit"
                    options={businessUnitOptions}
                    value={formData.business_unit}
                    onChange={(value) => {
                      handleBUDropdown(value);
                    }}
                    classNamePrefix="mySelect"
                  />
                  <CustomFieldValidator
                    validator={validator}
                    field={"Business Unit"}
                    inputValue={formData.business_unit}
                    validations={["required"]}
                  />
                </>
              ) : (
                <>
                  <InputField
                    label={"Business Unit"}
                    disabled={true}
                    name={"team"}
                    value={selectedUser.business_unit.name}
                    onChange={(event) => onChange(event)}
                  />
                  <CustomFieldValidator
                    validator={validator}
                    field={"Business Unit"}
                    inputValue={formData.business_unit}
                    validations={["required"]}
                  />
                </>
              )}
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              {menuUser ? (
                <>
                  <Select
                    theme={createThemeSelect}
                    id="show"
                    name="show"
                    placeholder="Select Team"
                    value={formData.team}
                    options={teamsOptions}
                    onChange={(value) => {
                      handleTeamsDropdown(value);
                    }}
                    classNamePrefix="mySelect"
                  />
                  <CustomFieldValidator
                    validator={validator}
                    field={"Team"}
                    inputValue={formData.team}
                    validations={["required"]}
                  />
                </>
              ) : (
                <>
                  <InputField
                    label={"Team"}
                    disabled={true}
                    name={"team"}
                    value={selectedUser.team.name}
                    onChange={(event) => onChange(event)}
                  />
                  <CustomFieldValidator
                    validator={validator}
                    field={"Team"}
                    inputValue={formData.team}
                    validations={["required"]}
                  />
                </>
              )}
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <Select
                theme={createThemeSelect}
                id="show"
                name="show"
                isMulti={true}
                native
                placeholder="Select Role"
                options={rolesOptions}
                value={roles}
                onChange={setRoles}
                classNamePrefix="mySelect"
                className="scrollable-select product-select"
              />
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <InputField
                label={"Address Line 1*"}
                name={"address_line1"}
                value={formData.address_line1}
                onChange={(event) => onChange(event)}
              />
              <CustomFieldValidator
                validator={validator}
                field={"Address Line 1"}
                inputValue={formData.address_line1}
                validations={["required", { max: "400" }]}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <InputField
                label={"Address Line 2*"}
                name={"address_line2"}
                value={formData.address_line2}
                onChange={(event) => onChange(event)}
              />
              <CustomFieldValidator
                validator={validator}
                field={"Address Line 2"}
                inputValue={formData.address_line2}
                validations={["required", { max: "400" }]}
              />
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              {" "}
              <Select
                theme={createThemeSelect}
                classNamePrefix="mySelect"
                className="bu-creation-select"
                id="country"
                name="country"
                placeholder="Country*"
                label="Country*"
                options={updatedCountries}
                value={editCountry}
                onChange={(value) => {
                  SetEditCountry();
                  setFormData({
                    ...formData,
                    country: value.name,
                    state: "",
                    city: "",
                  });
                  SetEditState(null);
                  SetEditCity(null);
                  setValues(
                    {
                      country: value,
                      state: null,
                      city: null,
                    },
                    false
                  );
                }}
              />{" "}
              <CustomFieldValidator
                validator={validator}
                field={"Country"}
                inputValue={formData.country}
                validations={["required"]}
              />
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              {" "}
              <Select
                theme={createThemeSelect}
                classNamePrefix="mySelect"
                className="bu-creation-select"
                id="state"
                name="state"
                placeholder="State*"
                options={updatedStates(values?.country?.value)}
                value={editState}
                onChange={(value) => {
                  SetEditState();
                  SetEditCity(null);
                  setFormData({ ...formData, state: value.name, city: "" });
                  setValues({ ...values, state: value, city: null }, false);
                }}
              />{" "}
              <CustomFieldValidator
                validator={validator}
                field={"State"}
                inputValue={formData.state}
                validations={["required"]}
              />
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <Select
                theme={createThemeSelect}
                classNamePrefix="mySelect"
                className="bu-creation-select"
                id="city"
                name="city"
                placeholder="City"
                options={updatedCities(values?.state?.value)}
                value={editCity}
                onChange={(value) => {
                  SetEditCity();
                  setFormData({ ...formData, city: value.name });
                  setFieldValue("city", value);
                }}
              />
            </Grid>

            <Grid item md={3} sm={6} xs={12}>
              <InputField
                label={"PIN Code*"}
                name={"pincode"}
                value={formData.pincode}
                onChange={(event) => onChange(event)}
              />
              <CustomFieldValidator
                validator={validator}
                field={"Pincode"}
                inputValue={formData.pincode}
                validations={"required|numeric|min:4|max:125"}
              />
            </Grid>
          </Grid>
        </div>
        <div className="bu-creation-submit-container mt-4 text-end">
          <button className="btn primary-button" onClick={onSubmit}>
            {t("Update")}
          </button>
          <button
            className="btn cancel-button ml-20"
            onClick={() =>
              menuUser ? navigate("/user") : navigate("/setup/team/user")
            }
          >
            {t("Cancel")}
          </button>
        </div>
      </div>
    </section>
  );
};

export default UserEdit;
