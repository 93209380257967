import axios from "axios";
import { isEmpty } from "lodash";
import { GET_USER_TOKEN } from "../helper/localstorageReaderHelper";

const IAM_API = process.env.REACT_APP_IAM_API_ENDPOINT;
const configurationAPI = process.env.REACT_APP_CONFIGURATION_API_ENDPOINT;
const token = GET_USER_TOKEN();

const config = {
  headers: { Authorization: `Bearer ${token}` },
  params: {},
};

export const uploadCSVDataAPI = async (body) => {
  const { csvFile } = body;
  const uploadData = new FormData();
  uploadData.append("csvFile", csvFile);
};

// Clients API
export const getClientsAPI = async () =>
  axios.get(`${IAM_API}/organizations/`, config);

export const getProductsAPI = async () =>
  axios.get(`${IAM_API}/product/`, config);

export const getClientByIdAPI = async (id) =>
  axios.get(`${IAM_API}/organizations/${id}`, config);

export const createClientAPI = async (client) =>
  await axios.post(`${IAM_API}/organizations/`, client, config);

export const editClientAPI = async (client) => {
  return await axios.put(
    `${IAM_API}/organizations/${client.id}`,
    client,
    config
  );
};

export const getClientConfigAPI = async (id) =>
  await axios.get(
    `${configurationAPI}/configuration/v1?tenant_id=${id}`,
    config
  );

export const updateClientConfigAPI = async (payload) => {
  return await axios.put(
    `${configurationAPI}/configuration/v1/${payload.config}/?tenant_id=${payload.id}`,
    payload.formData,
    config
  );
};

// Get config by BU API
export const getClientConfigByBUAPI = async (payload) =>
  await axios.get(
    `${configurationAPI}/configuration/v1?tenant_id=${payload.id}&bu_id=${payload.bu_id}`,
    config
  );

export const updateClientConfigByBUAPI = async (payload) => {
  return await axios.put(
    `${configurationAPI}/configuration/v1/${payload.config}/?tenant_id=${payload.id}&bu_id=${payload.bu_id}`,
    payload.formData,
    config
  );
};

// Business Unit API
export const getAllBusinessUnitsAPI = async (selectedClient) =>
  axios.get(`${IAM_API}/org/${selectedClient.id}/business-unit/`, config);

export const getByIdBusinessUnitsAPI = async (body) =>
  axios.post(`${IAM_API}/org/${body.id}/business-unit/`, body, config);

export const createBUAPI = async (payload) =>
  await axios.post(
    `${IAM_API}/org/${payload.org_id}/business-unit/`,
    payload.formData,
    config
  );

export const editBUAPI = async (payload) =>
  axios.patch(
    `${IAM_API}/org/${payload.org_id}/business-unit/${payload.business_id}`,
    payload.formData,
    config
  );

// Teams API
export const getAllTeamUnitAPI = async (selectedBU) => {
  return await axios.get(
    `${IAM_API}/org/${selectedBU.organization}/team/?business_unit=${selectedBU.id}`,
    config
  );
};

export const createTeamUnitAPI = async (body) => {
  return await axios.post(
    `${IAM_API}/org/${body.org_id}/team/`,
    body.formData,
    config
  );
};

export const editTeamAPI = async (body) => {
  return await axios.put(
    `${IAM_API}/org/${body.organization}/team/${body.id}`,
    body,
    config
  );
};

// Users API

export const getAllUsersAPI = async (org_id) => {
  return await axios.get(`${IAM_API}/org/${org_id}/users/`, config);
};

export const getAllUsersByTeamsAPI = async (team) => {
  return await axios.get(
    `${IAM_API}/org/${team.organization}/users/?team=${team.id}`,
    config
  );
};

export const editUserAPI = async (body) => {
  return await axios.put(
    `${IAM_API}/org/${body.organization}/users/${body.id}`,
    body,
    config
  );
};

export const createUserAPI = async (payload) => {
  return await axios.post(
    `${IAM_API}/org/${payload.org_id}/users/`,
    payload.formData,
    config
  );
};

//Roles API
export const getAllRolesAPI = async (org_id) => {
  return await axios.get(
    `${IAM_API}/org/${org_id}/role-permissions/roles/`,
    config
  );
};

export const getAllStandardRolesAPI = async () => {
  return await axios.get(
    `${IAM_API}/role-permissions/roles/?standard=True`,
    config
  );
};

export const createRoleAPI = async (payload) => {
  const orgId = isEmpty(payload.org_id) ? `` : `org/${payload.org_id}/`;
  return await axios.post(
    `${IAM_API}/${orgId}role-permissions/roles/`,
    payload.roleData,
    config
  );
};

export const updateRoleAPI = async (payload) => {
  const orgId = isEmpty(payload.org_id) ? `` : `org/${payload.org_id}/`;
  return await axios.put(
    `${IAM_API}/${orgId}role-permissions/roles/${payload.role_id}`,
    payload.roleData,
    config
  );
};

//Permissons API

export const getAllPermissionsAPI = async (org_id, product_id) => {
  return await axios.get(
    `${IAM_API}/org/${org_id}/role-permissions/permissions/?product_id=${product_id}`,
    config
  );
};

export const getAllProductPermissionsAPI = async (product_id) => {
  return await axios.get(
    `${IAM_API}/product/permissions/?product=${product_id}`,
    config
  );
};
