import { Box, FormControl } from "@mui/material";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import LoaderWithLabel from "../../../components/ProcessingLoader/LoaderWithLabel";
import Toolbar from "../../../components/Toolbar/Toolbar";
import { GET_USER } from "../../../helper/localstorageReaderHelper";
import {
  GET_ALL_CLIENTS,
  GET_ALL_PRODUCTS,
  GET_ALL_ROLES,
} from "../../../store/Types";
import { getFilteredDetails } from "../../../utils";
import { createThemeSelect, defaultValueSelect, permission } from "../../../utils/Constants";
import RoleGrid from "./RoleGrid/RoleGrid";
import "./RoleList.scss";

const RoleList = () => {
  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const permissions = get(user, "permissions", []);
  const editPermission = permissions.includes(permission.roleEdit);

  const getAllClientsDispatch = () => {
    dispatch({ type: "client/resetClientError" });
    dispatch({ type: "roles/setLoaderTrue" });
    dispatch({ type: GET_ALL_CLIENTS });
    dispatch({ type: GET_ALL_PRODUCTS });
  };

  const [rowLength, setRowLength] = useState(10);
  const [pageSize, setPageSize] = useState(defaultValueSelect);
  const [editItem, setEditItem] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [client, setClient] = useState("");

  const [selectClient, setSelectClient] = useState("");

  const clients = useSelector((state) => state.ClientReducer.clients);
  const allRoles = useSelector((state) => state.RoleReducer.roles);
  const org_id = useSelector((state) => state.RoleReducer.org_id);
  const rolesLoader = useSelector((state) => state.RoleReducer.loader);
  useEffect(() => {
    if (isEmpty(tenantId)) {
      getAllClientsDispatch();
    } else {
      setClient(tenantId);
      dispatch({ type: "roles/setOrgIdSlice", payload: tenantId });
      tenantId &&
        dispatch({
          type: GET_ALL_ROLES,
          payload: tenantId,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(client)) {
      dispatch({ type: "roles/getAllRolesErrorSlice" });

      if (!isEmpty(org_id)) {
        setClient(org_id.value);
        setSelectClient(org_id);
      } else {
        if (isEmpty(tenantId)) {
          dispatch({
            type: GET_ALL_ROLES,
            payload: null,
          });
        }
      }
    } else {
      dispatch({
        type: GET_ALL_ROLES,
        payload: client,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch({
        type: "roles/setOrgIdSlice",
        payload: null,
      })
    }
  }, [client]);

  const refreshHandler = () => {
    client
      ? dispatch({
        type: GET_ALL_ROLES,
        payload: client,
      })
      : tenantId &&
      dispatch({
        type: GET_ALL_ROLES,
        payload: tenantId,
      });
    setPageSize(defaultValueSelect);
    setRefresh(true);
    setSearchField("");
    setTimeout(() => {
      setRefresh(false);
    }, 750);
  };

  const filteredRoles = getFilteredDetails(allRoles, searchField);

  const clientOptions =
    isEmpty(tenantId) &&
    clients.map((item) => ({
      label: item.name,
      value: item.id,
    })).sort((a, b) => {
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      return 0;
    });

  return (
    <div>
      {rolesLoader && <LoaderWithLabel />}
      <section className="client-listing">
        <h3 className="section-heading">{t("Roles")}</h3>
        <div className="section-breadcrumbs">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Role List
              </li>
            </ol>
          </nav>
        </div>

        {isEmpty(tenantId) && (
          <div>
            <Box sx={{ width: "20%", margin: "16px 0", background: "white" }}>
              <FormControl fullWidth>
                <Select
                  theme={createThemeSelect}
                  id="show"
                  name="client_list"
                  placeholder="Select Client"
                  options={clientOptions}
                  value={selectClient}
                  onChange={(value) => {
                    setClient(value?.value);
                    setSelectClient(value);
                    dispatch({
                      type: "roles/setOrgIdSlice",
                      payload: value,
                    });
                    setPageSize(defaultValueSelect);
                    setSearchField("");
                  }}
                  classNamePrefix="mySelect"
                />
              </FormControl>
            </Box>
          </div>
        )}

        <div className="client-listing-content">
          <Toolbar
            searchLabel="Role"
            addNew={"Add New Role"}
            addNewPath="/role/edit"
            setSearchField={setSearchField}
            setRowLength={setRowLength}
            refreshHandler={refreshHandler}
            searchField={searchField}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />

          <div className="client-listing-grid">
            {refresh ? (
              <LoaderWithLabel />
            ) : (
              <RoleGrid
                roleDetails={filteredRoles}
                rowLength={rowLength}
                searchField={searchField}
                editItem={editItem}
                setEditItem={setEditItem}
                client={client}
                pageSize={pageSize}
                editPermission={editPermission}
                selectClient={selectClient}
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default RoleList;
