import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  isLoading: false,
  error: true,
  errorMsg: "",
  ErrorCode: "",
  isEdit: false,
  selectedBU: null,
  selectedTeam: null,
  teams: [],
  apiSuccess: false,
};

export const TeamSlice = createSlice({
  name: "teams",
  initialState: initialState,
  reducers: {
    loadingTeamUnit: (state, action) => {
      state.isLoading = action.payload;
    },
    getAllTeamUnitSlice: (state, action) => {
      state.teams = action.payload;
      state.loader = false;
      state.isLoading = false;
      return state;
    },
    resetTeamsSlice: (state) => {
      state.teams = [];
      state.error = true;
      return state;
    },
    resetAllTeamsSlice: (state) => {
      state.teams = [];
      return state;
    },
    selectedBUSlice: (state, action) => {
      state.selectedBU = action.payload;
      return state;
    },
    selectedTeamSlice: (state, action) => {
      state.selectedTeam = action.payload;
      return state;
    },
    createTeamUnitSlice: (state, action) => {
      state.teams = [...state.teams, ...action.payload];
      state.loader = false;
      state.apiSuccess = true;
      state.error = false;
      return state;
    },
    editTeamSlice: (state, action) => {
      state.teams = state.teams.map((i) =>
        i.id === action.payload.id ? action.payload : i
      );
      state.loader = false;
      state.apiSuccess = true;
      state.error = false;
      return state;
    },
    teamCSVUploadInitiate: (state) => {
      state.csvUpload = false;
      return state;
    },
    teamCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.csvUploadResponseData = action.payload;
      return state;
    },
    teamCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      return state;
    },
    setLoaderTrue: (state) => {
      state.loader = true;
    },
    setTeamError: (state) => {
      state.error = true;
      state.loader = false;
      return state;
    },
    resetTeamError: (state) => {
      state.error = false;
      return state;
    },
    resetApiSuccess: (state) => {
      state.apiSuccess = false;
      return state;
    },
  },
});

export const {
  getAllTeamUnitSlice,
  setLoaderTrue,
  resetTeamError,
  setTeamError,
  selectedBUSlice,
  createTeamUnitSlice,
  editTeamSlice,
  teamCSVUploadInitiate,
  teamCSVUploadComplete,
  teamCSVUploadError,
  selectedTeamSlice,
  resetTeamsSlice,
  loadingTeamUnit,
  resetApiSuccess,
  resetAllTeamsSlice,
} = TeamSlice.actions;

export default TeamSlice.reducer;
