import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import Breadcrumb from "../../../components/Breadcrumb";
import CustomFieldValidator from "../../../components/CustomFieldValidator";
import CustomPhoneInput from "../../../components/CustomPhoneInput";
import LoaderWithLabel from "../../../components/ProcessingLoader/LoaderWithLabel";
import InputField from "../../../globalComponent/InputField";
import {
  getUpdatedFields,
  handleChangeAccordion,
  handleFormChange,
  handleInput,
  handleOptionsChange,
  updateAccordionState,
} from "../../../helper/commonHelper";
import { GET_USER } from "../../../helper/localstorageReaderHelper";
import { useCommonHook } from "../../../hooks/useCommonHook";
import { CREATE_TEAMS_UNIT } from "../../../store/Types";
import { updatedCities, updatedCountries, updatedStates } from "../../../utils";
import {
  createThemeSelect,
  cryptoRandom,
  nameValidation,
} from "../../../utils/Constants";
import "./TeamCreation.scss";

const validator = new SimpleReactValidator();

const TeamCreation = () => {
  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");
  const [expandedAccordion, setExpandedAccordion] = useState([]);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const createteamBU = useSelector((state) => state.TeamReducer.selectedBU);

  const selectedBUClient = useSelector(
    (state) => state.BusinessUnitReducer.selectedClient
  );

  const tenantClient = useSelector((state) => state.ClientReducer.tenantClient);

  const selectedClient = !isEmpty(tenantId) ? tenantClient : selectedBUClient;

  const [inputFields, setInputFields] = useState([
    {
      id: cryptoRandom(),
      name: "",
      contact_person_name: "",
      contact_person_number: "",
      contact_person_country_code: "",
      email: "",
      address_line1: "",
      address_line2: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      business_unit: createteamBU.id,
    },
  ]);

  const [, forceUpdate] = useState();

  const offsetHeight = window.innerHeight - 100;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const teamError = useSelector((state) => state.TeamReducer.error);
  const teamSuccess = useSelector((state) => state.TeamReducer.apiSuccess);
  const teamLoader = useSelector((state) => state.TeamReducer.loader);
  useEffect(() => {

    if (isSubmitted && teamSuccess && !teamError) {
      navigate("/setup/team");
    }


    return () => {
      // to remove error message on mount
      validator.hideMessages()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamLoader, teamError, isSubmitted]);

  useCommonHook(inputFields, setExpandedAccordion);
  const { values, setValues } = useFormik({
    initialValues: [
      {
        country: "India",
        state: null,
        city: null,
      },
    ],
  });

  const onSubmit = () => {
    if (validator.allValid()) {
      const updatedInputFields = getUpdatedFields(inputFields);
      const payload = {
        formData: updatedInputFields,
        org_id: createteamBU.organization,
        organization: createteamBU.organization,
        id: createteamBU.id,
      };
      dispatch({ type: "teams/resetTeamError" });
      dispatch({ type: "teams/setLoaderTrue" });
      dispatch({ type: "teams/resetApiSuccess" });

      dispatch({ type: CREATE_TEAMS_UNIT, payload: payload });
      setIsSubmitted(true);
    } else {
      validator.showMessages();
      forceUpdate(1);
      updateAccordionState(inputFields, setExpandedAccordion);
      return false;
    }
  };

  const addFields = () => {
    let newfield = {
      id: cryptoRandom(),
      name: "",
      contact_person_name: "",
      contact_person_number: "",
      contact_person_country_code: "+91",
      email: "",
      address_line1: "",
      address_line2: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      business_unit: createteamBU.id,
    };
    setInputFields([...inputFields, newfield]);

    let newOptionsFields = {
      country: "India",
      state: null,
      city: null,
    };
    setValues([...values, newOptionsFields]);
  };

  const deleteForm = (input, e) => {
    e.preventDefault();
    setInputFields((prevState) => prevState.filter((e) => e !== input));
  };

  return (
    <>
      <section
        className="team-creation"
        style={{ height: `${offsetHeight}px` }}
      >
        {teamLoader && <LoaderWithLabel />}
        <h3 className="section-heading">{t("Create Team")}</h3>
        <Breadcrumb
          list={[
            isEmpty(tenantId) && {
              name: t("Clients"),
              path: "/client",
            },
            isEmpty(tenantId) && selectedClient.name,
            {
              name: t("Setup"),
              path: "/setup",
            },
            createteamBU.country,
            createteamBU.name,
            {
              name: t("Teams"),
              path: "/setup/team",
            },
            t("Create Team"),
          ]}
        />

        <div className="team-create-container m-1">
          <h3 className="section-subheading">{t("Team Information")}</h3>
        </div>
        <div>
          <form>
            {inputFields.map((input, index) => {
              return (
                <div key={index} className="mb-2 accordion-container">
                  <Accordion
                    defaultExpanded
                    expanded={expandedAccordion.includes(input?.id)}
                    onChange={handleChangeAccordion(
                      input?.id,
                      setExpandedAccordion,
                      expandedAccordion
                    )}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{ color: "white", fontSize: "1.25rem" }}
                        />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        // background: "#292666",
                        borderRadius: "0.5rem",
                        minHeight: "3rem",
                        maxHeight: "3rem",
                        "&.Mui-expanded": {
                          minHeight: "3rem",
                          maxHeight: "3rem",
                        },
                      }}
                    >
                      <Typography>{`Team ${index + 1}*`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="team-creation-container">
                        <div className="mb-4">
                          <Grid container spacing={2.5}>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                              <InputField
                                label={"Team Name*"}
                                name={"name"}
                                value={input.name}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputFields,
                                    setInputFields
                                  )
                                }
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"Team Name"}
                                inputValue={input.name}
                                validations={nameValidation}
                              />
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                              <InputField
                                value={createteamBU.name}
                                label={"Team BU Name*"}
                                disabled={true}
                                name={"Team BU Name"}
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"Team BU Name"}
                                inputValue={input.name}
                                validations={nameValidation}
                              />
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                              <InputField
                                label={"Team Contact Person Name*"}
                                name={"contact_person_name"}
                                value={input.contact_person_name}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputFields,
                                    setInputFields
                                  )
                                }
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"Team Contact Person Name"}
                                inputValue={input.contact_person_name}
                                validations={nameValidation}
                              />
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                              <CustomPhoneInput
                                placeholder="BU Contact Person Number*"
                                value={input.contact_person_number}
                                withCountryCallingCode={true}
                                onChange={(val) =>
                                  handleInput(
                                    index,
                                    val,
                                    "contact_person_number",
                                    inputFields,
                                    setInputFields
                                  )
                                }
                                className="phone-input"
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"Contact Person Number"}
                                inputValue={input.contact_person_number}
                                validations={[
                                  "required",
                                  "phone",
                                  { max: "13" },
                                ]}
                              />
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                              <InputField
                                label={"Enter Email Address*"}
                                name={"email"}
                                value={input.email}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputFields,
                                    setInputFields
                                  )
                                }
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"Email"}
                                inputValue={input.email}
                                validations={"required|email"}
                              />
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                              <InputField
                                label={"Address Line 1*"}
                                name={"address_line1"}
                                value={input.address_line1}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputFields,
                                    setInputFields
                                  )
                                }
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"Address Line 1"}
                                inputValue={input.address_line1}
                                validations={["required"]}
                              />
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                              <InputField
                                label={"Address Line 2*"}
                                name={"address_line2"}
                                value={input.address_line2}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputFields,
                                    setInputFields
                                  )
                                }
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"Address Line 2"}
                                inputValue={input.address_line2}
                                validations={["required"]}
                              />
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                              {" "}
                              <Select
                                theme={createThemeSelect}
                                classNamePrefix="mySelect"
                                className="bu-creation-select"
                                id="country"
                                name="country"
                                placeholder="Country*"
                                label="Country*"
                                options={updatedCountries}
                                value={input.country}
                                onChange={(value) => {
                                  handleInput(
                                    index,
                                    value,
                                    "country",
                                    inputFields,
                                    setInputFields
                                  );
                                  handleInput(
                                    index,
                                    null,
                                    "state",
                                    inputFields,
                                    setInputFields
                                  );
                                  handleInput(
                                    index,
                                    null,
                                    "city",
                                    inputFields,
                                    setInputFields
                                  );
                                  handleOptionsChange(
                                    index,
                                    value,
                                    "country",
                                    values,
                                    setValues
                                  );
                                }}
                              />{" "}
                              <div className="mt-3">
                                <CustomFieldValidator
                                  validator={validator}
                                  field={"Country"}
                                  inputValue={input.country}
                                  validations={["required"]}
                                />
                              </div>
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                              {" "}
                              <Select
                                theme={createThemeSelect}
                                classNamePrefix="mySelect"
                                className="bu-creation-select"
                                id="state"
                                name="state"
                                placeholder="State*"
                                options={updatedStates(values[index]?.country?.value)}
                                value={input.state}
                                onChange={(value) => {
                                  handleInput(
                                    index,
                                    value,
                                    "state",
                                    inputFields,
                                    setInputFields
                                  );
                                  handleInput(
                                    index,
                                    null,
                                    "city",
                                    inputFields,
                                    setInputFields
                                  );
                                  handleOptionsChange(
                                    index,
                                    value,
                                    "state",
                                    values,
                                    setValues
                                  );
                                }}
                              />{" "}
                              <div className="mt-3">
                                <CustomFieldValidator
                                  validator={validator}
                                  field={"State"}
                                  inputValue={input.state}
                                  validations={["required"]}
                                />
                              </div>
                            </Grid>

                            <Grid item lg={3} md={4} sm={6} xs={12}>
                              <Select
                                theme={createThemeSelect}
                                classNamePrefix="mySelect"
                                className="team-creation-select"
                                id="city"
                                name="city"
                                placeholder="City"
                                options={updatedCities(
                                  values[index].state
                                    ? values[index].state.value
                                    : null
                                )}
                                value={input.city}
                                onChange={(value) => {
                                  handleInput(
                                    index,
                                    value,
                                    "city",
                                    inputFields,
                                    setInputFields
                                  );
                                  handleOptionsChange(
                                    index,
                                    value,
                                    "city",
                                    values,
                                    setValues
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item lg={3} md={4} sm={6} xs={12}>
                              <InputField
                                label={"PIN Code*"}
                                name={"pincode"}
                                value={input.pincode}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    inputFields,
                                    setInputFields
                                  )
                                }
                              />
                              <CustomFieldValidator
                                validator={validator}
                                field={"Pincode"}
                                inputValue={input.pincode}
                                validations={"required|numeric|min:4|max:125"}
                              />
                            </Grid>
                          </Grid>
                        </div>
                        {index !== 0 && (
                          <div>
                            <button
                              className="team-create-delete"
                              onClick={(e) => deleteForm(input, e)}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </div>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </form>
        </div>
        <div className="team-create-container m-1">
          <button className="btn primary-button" onClick={addFields}>
            {" "}
            Add New Team
          </button>
        </div>
        <div className="team-creation-submit-container mt-4 text-end">
          <button className="btn primary-button" onClick={onSubmit}>
            {t("Create")}
          </button>
          <button
            className="btn cancel-button ml-20"
            onClick={() => navigate("/setup/team")}
          >
            {t("Cancel")}
          </button>
        </div>
      </section>
    </>
  );
};

export default TeamCreation;
