export const SHOW_ROWS = [10, 25, 50, 100];
//tan regex
export let tanRegex = /^([A-Z]){4}([0-9]){5}([A-Z]){1}?$/;

export const userPath = "/user";
export const configPath = "/configuration";

export const permission = {
  setup: "BU_VIEW",
  clientConfigure: "CLIENT_VIEWCONFIGURATION",
  clientEdit: "CLIENT_EDIT",
  clientAdd: "CLIENT_CREATE",
  clientEditConfiguration: "CLIENT_CREATEEDITCONFIGURATION",
  userView: "USER_VIEW",
  userAdd: "USER_CREATE",
  userEdit: "USER_EDIT",
  buEdit: "BU_EDIT",
  buAdd: "BU_CREATE",
  teamView: "TEAM_VIEW",
  buConfigure: "BU_VIEWCONFIGURATION",
  buEditConfiguration: "BU_CREATEEDITCONFIGURATION",
  teamEdit: "TEAM_EDIT",
  teamAdd: "TEAM_CREATE",
  roleAdd: "ROLE_CREATE",
  roleEdit: "ROLE_EDIT",
};

export const role = {
  clientAdmin: "Client Admin",
  superAdmin: "3SCAdmin",
};

export const tanNoValidationMessage = `*Tan Number format eg: "ABCD12345E"`;
export const tanNoErrorMessage = "The Tan Number is not in specified format";
export const entitiesNameValidation = [
  "required",
  "alpha_num_dash_space",
  { max: "128" },
];
export const nameValidation = [
  "required",
  "alpha_num_dash_space",
  { max: "64" },
];

export const createThemeSelect = (theme) => {
  return {
    ...theme,
    borderRadius: 2,
    colors: {
      ...theme.colors,
      primary25: "#F2F2F2",
      primary: "#E94F1C",
    },
  };
};

export function cryptoRandom() {
  const typedArray = new Uint8Array(10);
  const randomValue = crypto.getRandomValues(typedArray);
  return randomValue.join("");
}

export const defaultValueSelect = { label: "Show 10 Rows", value: 10 };
export const getInputLabels = (type) => {
  const isBu = type === "BU";
  return {
    name: `${isBu ? "BU" : "Team"} Name*`,
    business_unit_name: "Team BU Name*",
    contact_person_name: `${isBu ? "BU" : "Team"} Contact Person Name*`,
    contact_person_number: `${isBu ? "BU" : "Team"} Contact Person Number*`,
    email: "Enter Email Address*",
    address_line1: "Address Line 1*",
    address_line2: "Address Line 2",
    country: "Country",
    state: "State",
    pincode: "PIN Code*",
    city: "City",
    entities_name: "BU Entities Name*",
    tan: "TAN Number*",
  };
};

export const ENUM_USER_TYPE = {
  BU: "BU",
  TEAM: "TEAM",
};


export const phoneInputValidateMsg = "This field is mandatory";
