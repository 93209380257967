import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isProgress: false,
  progressValue: 0,
};

export const ProgressSlice = createSlice({
  name: "progress",
  initialState: initialState,
  reducers: {
    loadingProgress: (state, action) => {
      state.isProgress = action?.payload?.isProgress ?? false;
      state.progressValue = action?.payload?.progressValue ?? 0;
    },
  },
});

export const { loadingProgress } = ProgressSlice.actions;

export default ProgressSlice.reducer;

export const selectProgress = (state) => state.ProgressReducer;
