import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  isLoading: false,
  errorMsg: null,
  error: false,
  ErrorCode: "",
  isEdit: false,
  selectedClient: null,
  businessUnits: [],
  createdBUs: 0,
  apiSuccess: false,
};

export const BusinessUnitSlice = createSlice({
  name: "businessUnit",
  initialState: initialState,
  reducers: {
    lodingBusinessUnit: (state, action) => {
      state.isLoading = action.payload;
    },
    getAllBusinessUnitSlice: (state, action) => {
      state.businessUnits = action.payload;
      state.loader = false;
      state.isLoading = false;
      return state;
    },
    resetBusinessUnitStateSlice: (state) => {
      state.businessUnits = [];
      state.loader = true;
      state.error = true;
      return state;
    },
    addBUSlice: (state, action) => {
      state.businessUnits = [...state.businessUnits, ...action.payload];
      state.createdBUs = action.payload.length;
      state.loader = false;
      state.apiSuccess = true;
      state.error = false;
      return state;
    },
    editBUSlice: (state) => {
      state.loader = false;
      state.apiSuccess = true;
      state.error = false;
      return state;
    },
    resetCreatedBUs: (state) => {
      state.createdBUs = 0;
      return state;
    },
    addBUErrorSlice: (state) => {
      return state;
    },
    businessCSVUploadInitiate: (state) => {
      state.csvUpload = false;
      state.isLoading = true;
      return state;
    },
    businessCSVUploadComplete: (state, action) => {
      state.csvUpload = true;
      state.isLoading = false;
      state.csvUploadResponseData = action.payload;
      return state;
    },
    businessCSVUploadError: (state, action) => {
      state.csvUploadError = action.payload;
      state.isLoading = false;
      return state;
    },
    selectedClientSlice: (state, action) => {
      state.selectedClient = action.payload;
      return state;
    },
    resetSelectedClientSlice: (state) => {
      state.selectedClient = null;
      return state;
    },
    setLoaderTrue: (state) => {
      state.loader = true;
      state.isLoading = true;
      return state;
    },
    setLoaderFalse: (state) => {
      state.loader = false;
      state.isLoading = false;
      return state;
    },
    setBUError: (state) => {
      state.error = true;
      state.loader = false;
      state.isLoading = false;
      return state;
    },
    resetBUError: (state) => {
      state.error = false;
      return state;
    },
    resetApiSuccess: (state) => {
      state.apiSuccess = false;
      return state;
    },
  },
});

export const {
  getAllBusinessUnitSlice,
  addBUSlice,
  businessCSVUploadError,
  businessCSVUploadComplete,
  businessCSVUploadInitiate,
  selectedClientSlice,
  addBUErrorSlice,
  resetCreatedBUs,
  resetBusinessUnitStateSlice,
  setBUError,
  resetBUError,
  editBUSlice,
  setLoaderTrue,
  setLoaderFalse,
  lodingBusinessUnit,
} = BusinessUnitSlice.actions;

export default BusinessUnitSlice.reducer;
