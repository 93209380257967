import React from "react";
import "./input-icon.scss";

const InputWithIcon = (props) => {
  return (
    <>
      <div className="inputField">
        <div className="login-box">
          <i>{props.icon}</i>
          <input
            type="text"
            required="required"
            name={props.name}
            onChange={props.onChange}
            value={props.value}
            className={props.disabled && "disabled"}
          />
          <label>{props.label}</label>
        </div>
      </div>
    </>
  );
};

export default InputWithIcon;
