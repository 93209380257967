import { Divider, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import CustomFieldValidator from "../../../components/CustomFieldValidator";
import CustomPhoneInput, {
  convertLocalNumber,
  getPhoneCountryCode,
} from "../../../components/CustomPhoneInput";
import LoaderWithLabel from "../../../components/ProcessingLoader/LoaderWithLabel";
import InputField from "../../../globalComponent/InputField";
import { CREATE_CLIENT, EDIT_CLIENT } from "../../../store/Types";
import {
  convertBase64,
  getProductOptions,
  updatedCities,
  updatedCountries,
  updatedStates,
} from "../../../utils";
import {
  createThemeSelect,
  nameValidation,
  tanNoErrorMessage,
  tanNoValidationMessage,
  tanRegex,
} from "../../../utils/Constants";
import "./ClientCreation.scss";

const validator = new SimpleReactValidator();

const styles = {
  input1: {
    "&::placeholder": {
      textAlign: "center",
    },
  },
};

const ClientCreation = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const editValue = get(location, "state", "");

  const [file, setFile] = useState(null);

  const clientError = useSelector((state) => state.ClientReducer.error);
  const clientSuccess = useSelector((state) => state.ClientReducer.apiSuccess);
  const clientLoader = useSelector((state) => state.ClientReducer.loader);
  const clientProducts = useSelector((state) => state.ClientReducer.products);

  const [formData, setFormData] = useState({
    clientId: "",
    clientName: "",
    mobileNumber: "",
    countryCode: "",
    tanNumber: "",
    registrationNumber: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    adminFirstName: "",
    adminLastName: "",
    adminMailId: "",
    adminMobile: "",
    adminMobileCountryCode: "",
    logo: "",
    isActive: true,
  });

  const {
    clientName,
    mobileNumber,
    tanNumber,
    registrationNumber,
    addressLine1,
    addressLine2,
    pincode,
    adminFirstName,
    adminLastName,
    adminMailId,
    adminMobile,
    country,
    state,
    city,
    logo,
    isActive,
  } = formData;

  const [isSubmitted, setIsSubmitted] = useState(false);
  const inputFile = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSubmitted) {
      if (clientSuccess && !clientError) {
        navigate("/client");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientLoader, clientError, isSubmitted]);

  const [, forceUpdate] = useState();

  const [editCountry, SetEditCountry] = useState("");
  const [editState, SetEditState] = useState("");
  const [editCity, SetEditCity] = useState("");
  const [product_enabled, setProductEnabled] = useState([]);
  const productOptions = getProductOptions(clientProducts);

  const mobileNo = "mobileNumber";
  const tanNo = "tanNumber";

  const clientItems = [
    {
      name: "clientName",
      label: "Client Name*",
      value: clientName,
      required: nameValidation,
    },
    {
      name: mobileNo,
      label: "Mobile Number*",
      value: mobileNumber,
      required: true,
    },
    {
      name: tanNo,
      label: "TAN Number*",
      value: tanNumber,
      required: ["required", { regex: tanRegex }],
    },
    {
      name: "registrationNumber",
      label: "Registration Number",
      value: registrationNumber,
      required: ["alpha_num"],
    },
    {
      name: "addressLine1",
      label: "Address Line 1*",
      value: addressLine1,
      required: ["required"],
    },
    {
      name: "addressLine2",
      label: "Address Line 2*",
      value: addressLine2,
      required: ["required"],
    },
  ];

  const adminItems = [
    {
      name: "adminMailId",
      label: "Admin Mail ID*",
      value: adminMailId,
      required: "required|email",
    },
    {
      name: "adminFirstName",
      label: "Admin First Name*",
      value: adminFirstName,
      required: nameValidation,
    },
    {
      name: "adminLastName",
      label: "Admin Last Name*",
      value: adminLastName,
      required: nameValidation,
    },
    {
      name: "adminMobile",
      label: "Mobile Number*",
      value: adminMobile,
      required: "required",
    },
  ];

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const offsetHeight = window.innerHeight - 200;

  const addressFromik = useFormik({
    initialValues: {
      country: "India",
      state: null,
      city: null,
    },
  });

  const { values, setFieldValue, setValues } = addressFromik;

  const clientPhoneInputHandle = (val) => {
    setFormData({
      ...formData,
      mobileNumber: val,
    });
  };

  const onSubmit = () => {
    if (validator.allValid()) {
      //formatting phone number input
      let localNumber = convertLocalNumber(mobileNumber);
      let countryCode = getPhoneCountryCode(mobileNumber);

      let adminNumber = convertLocalNumber(adminMobile);
      let adminCountryCode = getPhoneCountryCode(adminMobile);

      const client = {
        organization: {
          name: clientName,
          tan: tanNumber,
          country_code: countryCode,
          phone_number: localNumber,
          registration_number: registrationNumber,
          address_line_1: addressLine1,
          address_line_2: addressLine2,
          country: country,
          state: state,
          city: city,
          pincode: pincode,
          logo: logo,
          is_active: isActive,
        },
        user: {
          first_name: adminFirstName,
          last_name: adminLastName,
          email: adminMailId,
          mobile: adminNumber,
          mobile_country_code: adminCountryCode,
        },
        product_enabled: product_enabled.map((item) => item.value),
      };
      dispatch({ type: "client/resetClientError" });
      dispatch({ type: "client/setLoaderTrue" });
      dispatch({ type: "client/resetApiSuccess" });
      dispatch({ type: CREATE_CLIENT, payload: client });
      setIsSubmitted(true);
    } else {
      validator.showMessages();
      forceUpdate(1);
      return false;
    }
  };

  const onUpdate = () => {
    if (validator.allValid()) {
      //formatting phone number input
      let localNumber = convertLocalNumber(mobileNumber);
      let countryCode = getPhoneCountryCode(mobileNumber);
      let adminLocalNumber = convertLocalNumber(adminMobile);
      let adminCountryCode = getPhoneCountryCode(adminMobile);
      const adminInformation = {
        ...editValue.admin,
        email: adminMailId,
        first_name: adminFirstName,
        last_name: adminLastName,
        mobile: adminLocalNumber,
        mobile_country_code: adminCountryCode,
      };
      const updatedClient = {
        ...editValue,
        id: editValue.id,
        name: clientName,
        tan: tanNumber,
        country_code: countryCode,
        phone_number: localNumber,
        registration_number: registrationNumber,
        address_line_1: addressLine1,
        address_line_2: addressLine2,
        country: country,
        state: state,
        city: city,
        pincode: pincode,
        logo: logo,
        is_active: isActive,
        admin: adminInformation,
        product_enabled: product_enabled.map((item) => item.value),
      };
      if (isEmpty(logo)) {
        delete updatedClient.logo;
      }
      dispatch({ type: "client/resetClientError" });
      dispatch({ type: "client/setLoaderTrue" });
      dispatch({ type: "client/resetApiSuccess" });
      dispatch({ type: EDIT_CLIENT, payload: updatedClient });
      setIsSubmitted(true);
    } else {
      validator.showMessages();
      forceUpdate(1);
      return false;
    }
  };

  const updatedProducts = (selectedClient, clientProducts) => {
    let updatedArray = [];
    selectedClient?.products_enabled &&
      selectedClient.products_enabled.forEach((item) => {
        let obj = {};
        clientProducts.map((product) => {
          if (item && item.product.toString() === product.id.toString()) {
            obj.value = product.id;
            obj.label = product.name;
          }
          return null;
        });
        updatedArray.push(obj);
      });
    return updatedArray;
  };

  const getEditValueLogo = (name, logo) => {
    let data = [];
    let metadata = {
      type: "image/jpg",
    };
    let file = new File([data], `${name}-logo.jpg`, metadata);
    !isEmpty(logo) && setFile(file);
  };

  useEffect(() => {
    if (!isEmpty(editValue)) {
      const updatedProductOptions = updatedProducts(editValue, clientProducts);
      setProductEnabled(updatedProductOptions);
      getEditValueLogo(editValue.name, editValue.logo);
      setFormData({
        ...formData,
        clientName: editValue.name,
        mobileNumber: editValue.country_code + editValue.phone_number,
        countryCode: editValue.country_code,
        tanNumber: editValue.tan,
        registrationNumber: editValue.registration_number,
        addressLine1: editValue.address_line_1,
        addressLine2: editValue.address_line_2,
        country: editValue.country,
        state: editValue.state,
        city: editValue.city,
        pincode: editValue.pincode,
        isActive: editValue.is_active,
        adminMailId: editValue.admin.email,
        adminFirstName: editValue.admin.first_name,
        adminLastName: editValue.admin.last_name,
        adminMobile:
          editValue.admin.mobile_country_code + editValue.admin.mobile,
        adminCountryCode: editValue.admin.mobile_country_code,
      });

      const selectedCountry = updatedCountries.find((elem) => {
        return elem.label === editValue.country;
      });

      if (selectedCountry) {
        const selectedStates = updatedStates(selectedCountry.id);

        const selectedState = selectedStates.find((elem) => {
          return elem.label === editValue.state;
        });

        SetEditCountry({ label: editValue.country });
        SetEditState({ label: editValue.state });
        SetEditCity(editValue.city ? { label: editValue.city } : "");
        setValues(
          {
            country: selectedCountry,
            state: selectedState,
            city: editValue.city,
          },
          false
        );
      }
    }
    return () => {
      // to remove error message on mount
      validator.hideMessages()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    const fileSize = file.size < 2000000;
    fileSize
      ? setFormData({ ...formData, logo: base64 })
      : toast.error("File size should be less than 2MB");
    setFile(e.target.files[0]);
  };

  const findLabel = (label) =>
    label?.endsWith("*") ? label?.slice(0, label?.length - 1) : label;

  return (
    <section
      className="client-creation"
      style={{ height: `${offsetHeight}px` }}
    >
      {clientLoader && <LoaderWithLabel />}
      <h3 className="section-heading">
        {" "}
        {!isEmpty(editValue) ? t("Update Client") : t("Create Client")}
      </h3>
      <div className="section-breadcrumbs">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/client">
                <span className="section-breadcrumbs-a">{t("Clients")}</span>
              </Link>
            </li>
            {!isEmpty(editValue) ? (
              <li className="breadcrumb-item">
                <span className="section-breadcrumbs-a">
                  {!isEmpty(editValue) ? editValue.name : ""}
                </span>
              </li>
            ) : (
              ""
            )}
            <li className="breadcrumb-item active" aria-current="page">
              {!isEmpty(editValue) ? t("Update Client") : t("Create Client")}
            </li>
          </ol>
        </nav>
      </div>
      <div className="client-creation-container">
        <div>
          <div className="mb-4">
            <h3 className="section-subheading mb-4">
              {t("Client Information")}
            </h3>
            <Grid container spacing={2.5}>
              {clientItems.map((item, index) => {
                return item.name !== mobileNo ? (
                  <Grid item md={3} sm={6} xs={12} key={index}>
                    <InputField
                      label={item.label}
                      name={item.name}
                      value={item.value}
                      onChange={onChange}
                    />
                    {item.name === tanNo && (
                      <span className="client-helper-text">
                        {tanNoValidationMessage}
                      </span>
                    )}
                    {item.required && (
                      <CustomFieldValidator
                        validator={validator}
                        field={findLabel(item.label)}
                        inputValue={item.value}
                        validations={item.required}
                        customMessage={
                          item.name === tanNo && item.value
                            ? tanNoErrorMessage
                            : ""
                        }
                      />
                    )}
                  </Grid>
                ) : (
                  <Grid item md={3} sm={6} xs={12} key={index}>
                    <CustomPhoneInput
                      placeholder="Enter phone number"
                      value={mobileNumber}
                      withCountryCallingCode={true}
                      onChange={(val) => clientPhoneInputHandle(val)}
                      international
                      defaultCountry="IN"
                      className="phone-input"
                    />
                    {item.required && (
                      <CustomFieldValidator
                        validator={validator}
                        field={findLabel(item.label)}
                        inputValue={item.value}
                        validations={["required", "phone", { max: "13" }]}
                      />
                    )}
                  </Grid>
                );
              })}

              <Grid item md={3} sm={6} xs={12}>
                {" "}
                <Select
                  theme={createThemeSelect}
                  classNamePrefix="mySelect"
                  className="client-creation-select"
                  id="country"
                  name="country"
                  placeholder="Country*"
                  label="Country*"
                  options={updatedCountries}
                  value={editCountry}
                  onChange={(value) => {
                    SetEditCountry();
                    SetEditState(null);
                    SetEditCity(null);
                    setFormData({
                      ...formData,
                      country: value.name,
                      state: "",
                      city: "",
                    });
                    setValues(
                      { country: value, state: null, city: null },
                      false
                    );
                  }}
                />
                <CustomFieldValidator
                  validator={validator}
                  field={"Country"}
                  inputValue={country}
                  validations={["required"]}
                />
              </Grid>

              <Grid item md={3} sm={6} xs={12}>
                {" "}
                <Select
                  theme={createThemeSelect}
                  classNamePrefix="mySelect"
                  className="client-creation-select"
                  id="state"
                  name="state"
                  placeholder="State*"
                  options={updatedStates(
                    values.country ? values.country.value : null
                  )}
                  value={editState}
                  onChange={(value) => {
                    SetEditState();
                    SetEditCity(null);
                    setFormData({ ...formData, state: value.name, city: "" });
                    setValues({ ...values, state: value, city: null }, false);
                  }}
                />{" "}
                <CustomFieldValidator
                  validator={validator}
                  field={"State"}
                  inputValue={state}
                  validations={["required"]}
                />
              </Grid>

              <Grid item md={3} sm={6} xs={12}>
                <Select
                  theme={createThemeSelect}
                  classNamePrefix="mySelect"
                  className="client-creation-select"
                  id="city"
                  name="city"
                  placeholder="City"
                  options={updatedCities(
                    values.state ? values.state.value : null
                  )}
                  value={editCity}
                  onChange={(value) => {
                    SetEditCity();
                    setFormData({ ...formData, city: value.name });
                    setFieldValue("city", value);
                  }}
                />
              </Grid>

              <Grid item md={3} sm={6} xs={12}>
                <InputField
                  label={"PIN Code*"}
                  name={"pincode"}
                  value={pincode}
                  onChange={onChange}
                />
                <CustomFieldValidator
                  validator={validator}
                  field={"Pincode"}
                  inputValue={pincode}
                  validations={"required|numeric|min:4|max:125"}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div
                  className="client-creation-input"
                  tabIndex="0"
                  onKeyDown={() => {
                    inputFile.current.click();
                  }}
                >
                  <span> {file ? file.name : "No File Chosen"}</span>
                  <div>
                    <label
                      htmlFor="file-upload"
                      className="client-creation-input-label"
                    >
                      {t("Choose File")}
                    </label>
                    <input
                      id="file-upload"
                      data-testid="file-upload"
                      type="file"
                      className="d-none"
                      ref={inputFile}
                      onChange={(e) => onFileChange(e)}
                      accept="image/*"
                    />
                  </div>
                </div>
                <span className="client-creation-helper-text">
                  {t("*Client Logo")}
                </span>
                <CustomFieldValidator
                  validator={validator}
                  field={"Logo"}
                  inputValue={file}
                  validations={["required"]}
                />
              </Grid>

              <Grid item md={3} sm={6} xs={12}>
                <Select
                  theme={createThemeSelect}
                  id="show"
                  name="product"
                  isMulti={true}
                  placeholder="Select Product*"
                  options={productOptions}
                  value={product_enabled}
                  onChange={setProductEnabled}
                  classNamePrefix="mySelect"
                  className="product-select"
                />
                <CustomFieldValidator
                  validator={validator}
                  field={"Product"}
                  inputValue={product_enabled}
                  validations={["required"]}
                />
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className="mt-4">
            <h3 className="section-subheading mb-4">
              {t("Admin Information")}
            </h3>
            <Grid container spacing={2.5}>
              {adminItems.map((item, index) => {
                return item.name !== "adminMobile" ? (
                  <Grid item md={3} sm={6} xs={12} key={index}>
                    <InputField
                      label={item.label}
                      name={item.name}
                      value={item.value}
                      onChange={onChange}
                    />
                    <CustomFieldValidator
                      validator={validator}
                      field={findLabel(item.label)}
                      inputValue={item.value}
                      validations={item.required}
                    />
                  </Grid>
                ) : (
                  <Grid item md={3} sm={6} xs={12} key={index}>
                    <CustomPhoneInput
                      placeholder={t("Enter phone number")}
                      value={adminMobile}
                      withCountryCallingCode={true}
                      onChange={(val) =>
                        setFormData({ ...formData, adminMobile: val })
                      }
                      international
                      defaultCountry="IN"
                      className="phone-input"
                    />{" "}
                    <CustomFieldValidator
                      validator={validator}
                      field={findLabel(item.label)}
                      inputValue={item.value}
                      validations={item.required}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>

        <div className="client-creation-submit-container mt-4">
          <button
            className="btn primary-button"
            onClick={!isEmpty(editValue) ? onUpdate : onSubmit}
          >
            {!isEmpty(editValue) ? t("Update") : t("Create")}
          </button>
          <button
            className="btn cancel-button ml-20"
            onClick={() => navigate("/client")}
          >
            {t("Cancel")}
          </button>
        </div>
      </div>
    </section>
  );
};

export default withStyles(styles)(ClientCreation);
