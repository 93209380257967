import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  errorMsg: "",
  ErrorCode: "",
  permissions: [],
};

export const PermissionSlice = createSlice({
  name: "permissions",
  initialState: initialState,
  reducers: {
    getAllPermissionsSlice: (state, action) => {
      state.permissions = action.payload;
      state.loader = false;
      return state;
    },
    resetPermissions: (state) => {
      state.permissions = [];
    },
  },
});

export const { getAllPermissionsSlice, resetPermissions } =
  PermissionSlice.actions;

export default PermissionSlice.reducer;
