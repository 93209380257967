import { isEmpty } from "lodash";
import {
    convertLocalNumber,
    getPhoneCountryCode,
} from "../components/CustomPhoneInput";

export const handleChangeAccordion = (panel, setExpandedAccordion, expandedAccordion) => (event, isExpanded) => {
    if (isExpanded) {
        setExpandedAccordion([...expandedAccordion, panel])
    } else {
        setExpandedAccordion(expandedAccordion.filter(item => item !== panel))
    }
};

export const getUpdatedFields = (inputFields) => {
    const result = [...inputFields].map((element) => {
        const payload = { ...element };
        let localNumber = convertLocalNumber(element.contact_person_number ?? element.mobile);
        let countryCode = getPhoneCountryCode(element.contact_person_number ?? element.mobile);
        payload[element.contact_person_number != undefined ? "contact_person_number" : "mobile"] = localNumber;
        payload[element.contact_person_country_code != undefined ? "contact_person_country_code" : "mobile_country_code"] = countryCode;
        payload.country = element.country.name;
        payload.state = element.state.name;
        payload.city = !isEmpty(element.city) ? element.city.name : "";
        return payload;
    });
    return result;
}

export const updateAccordionState = (inputFields, setExpandedAccordion) => {
    let newExpandedAccordion = inputFields.map(item => item.id);
    setExpandedAccordion([...newExpandedAccordion])
}

export const handleOptionsChange = (index, value, name, values, setValues) => {
    let data = [...values];
    data[index][name] = value;
    setValues(data, false);
};

export const handleFormChange = (index, event, inputFields, setInputFields) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
};

export const handleInput = (index, value, name, inputFields, setInputFields) => {
    let data = [...inputFields];
    data[index][name] = value;
    setInputFields(data);
};

