import { get, isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoaderWithLabel from "../../../components/ProcessingLoader/LoaderWithLabel";
import Toolbar from "../../../components/Toolbar/Toolbar";
import { GET_USER } from "../../../helper/localstorageReaderHelper";
import { useUploadFileHook } from "../../../hooks/useUploadFileHook";
import { BU_GET_TEAMS_UNIT, CREATE_TEAMS_UNIT } from "../../../store/Types";
import { permission } from "../../../utils/Constants";
import { requiredError, validate, validateError } from "../../../utils/commonFun";
import "../../Client/ClientList/ClientList.scss";
import TeamGrid from "./TeamGrid/TeamGrid";

const TeamList = () => {
  const selectedBU = useSelector((state) => state.TeamReducer.selectedBU);
  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");
  const permissions = get(user, "permissions", []);
  const addPermission = permissions.includes(permission.teamAdd);
  const editPermission = permissions.includes(permission.teamEdit);
  const viewUserPermission = permissions.includes(permission.userView);
  const currentClient = useSelector(
    (state) => state.BusinessUnitReducer.selectedClient
  );
  const tenantClient = useSelector((state) => state.ClientReducer.tenantClient);

  const selectedClient = isEmpty(tenantClient) ? currentClient : tenantClient;

  const teamDetail = useSelector((state) => state.TeamReducer.teams);
  const { isLoading } = useSelector((state) => state.TeamReducer);

  const [rowLength, setRowLength] = useState(10);
  const [pageSize, setPageSize] = useState({
    label: "Show 10 Rows",
    value: 10,
  });
  const dispatch = useDispatch();

  const [editItem, setEditItem] = useState([]);

  const [searchField, setSearchField] = useState("");

  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);

  const CurrentDate = moment().format("DD-MM-YYYY");

  const refreshHandler = () => {
    dispatch({ type: BU_GET_TEAMS_UNIT, payload: selectedBU });
    setPageSize({ label: "Show 10 Rows", value: 10 });
    setRefresh(true);
    setSearchField("");
    setTimeout(() => {
      setRefresh(false);
    }, 1000);
  };

  useEffect(() => {
    if (!isEmpty(selectedBU)) {
      dispatch({ type: BU_GET_TEAMS_UNIT, payload: selectedBU });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const templateCSVHeader = (type) => {
    let header = [
      [
        "Name",
        "Contact Person Name",
        "Contact Person Country Code",
        "Contact Person Number",
        "Email",
        "Address Line 1",
        "Address Line 2",
        "Country",
        "State",
        "City",
        "Pincode",
      ],
    ];
    if (type === "config") {
      header[0].push("Active/Inactive");
    }
    return header;
  };


  const uploadFileToggle = () => {
    setVisible(!visible);
  };

  const headerConfig = () => {
    const resultArr = [];
    templateCSVHeader("config")[0].forEach((item) => {
      const obj = {
        name: item,
        inputName: item,
        unique: item === "Name",
        required:
          item.trim().toUpperCase() !== "CITY" &&
          item.trim().toUpperCase() !== "ACTIVE/INACTIVE",
        requiredError,
        uniqueError: function (headerName, rowNumber) {
          return `${headerName} in the row number ${rowNumber} should be unique.`;
        },
        validate: (value) => validate(value, item),
        validateError,
      };
      resultArr.push(obj);
    });
    return resultArr;
  };

  const csvHeaderConfig = headerConfig();


  const handleCSVUpload = (uploadedData) => {
    console.log("uploadedData", uploadedData);
    const csvUploadArray = [];
    uploadedData?.forEach((item) => {
      const obj = {
        name: item["Name"],
        contact_person_name: item["Contact Person Name"],
        contact_person_number: item["Contact Person Number"],
        contact_person_country_code: `+${item["Contact Person Country Code"]}`,
        address_line1: item["Address Line 1"],
        address_line2: item["Address Line 2"],
        email: item["Email"],
        country: item.Country,
        state: item.State,
        city: item.City,
        pincode: item.Pincode,
        business_unit: selectedBU.id,
      };
      csvUploadArray.push(obj);
    });

    const body = {
      formData: csvUploadArray,
      org_id: selectedBU.organization,
    };
    dispatch({ type: "teams/teamCSVUploadInitiate" });
    dispatch({ type: "teams/resetTeamError" });
    dispatch({ type: "teams/setLoaderTrue" });
    dispatch({ type: "teams/resetApiSuccess" });

    dispatch({ type: CREATE_TEAMS_UNIT, payload: body });
    setTimeout(() => {
      toggleDialog();
      refreshHandler();
    }, 300);
  };

  const headers = [
    { label: "Name", key: "name" },
    { label: "Contact Person Name", key: "contact_person_name" },
    {
      label: "Contact Person Country Code",
      key: "contact_person_country_code",
    },
    { label: "Contact Person Number", key: "contact_person_number" },
    { label: "Email", key: "email" },
    { label: "Address Line 1", key: "address_line1" },
    { label: "Address Line 2", key: "address_line2" },
    { label: "Country", key: "country" },
    { label: "State", key: "state" },
    { label: "City", key: "city" },
    { label: "Pincode", key: "pincode" },
    { label: "Active/Inactive", key: "is_active" },
  ];

  const filteredteamDetail = teamDetail?.filter((team) => {
    return team;
  });

  const uploadDialog = useUploadFileHook({
    uploadFileToggle,
    handleCSVUpload,
    menuUser: false,
    handleBack: Function.prototype,
    csvHeaderConfig,
    templateDataHandlerCallback: templateCSVHeader,
    templateFileName: "Teams_Template.csv",
  });
  return (
    <div>
      <section className="client-listing">
        {isLoading && <LoaderWithLabel />}
        <h3 className="section-heading">Buisness Unit: {selectedBU.name}</h3>
        <div className="section-breadcrumbs">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {isEmpty(tenantId) && (
                <>
                  <li className="breadcrumb-item">
                    <Link to="/client" className="section-breadcrumbs-a">
                      Clients
                    </Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {selectedClient.name}
                  </li>
                </>
              )}

              <li className="breadcrumb-item" aria-current="page">
                <Link to="/setup" className="section-breadcrumbs-a">
                  Setup
                </Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                {selectedBU.country}
              </li>
              <li className="breadcrumb-item" aria-current="page">
                {selectedBU.name}
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Teams
              </li>
            </ol>
          </nav>
        </div>

        <div className="client-listing-content">
          <Toolbar
            searchLabel="Team Name / Email Address"
            addNew={addPermission && "Add New Team"}
            addNewPath="/setup/team/create"
            setSearchField={setSearchField}
            setRowLength={setRowLength}
            refreshHandler={refreshHandler}
            searchField={searchField}
            editItem={editItem}
            csvUpload={addPermission}
            csvuploadToggle={toggleDialog}
            csvDownload={true}
            csvDownloadHeaders={headers}
            csvDownloadDetails={teamDetail}
            csvDownloadFileName={"Team_" + CurrentDate}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
          <div className="client-listing-grid">
            {refresh && isLoading ? (
              <LoaderWithLabel />
            ) : (
              <TeamGrid
                teamDetail={isLoading ? [] : filteredteamDetail}
                editItem={editItem}
                setEditItem={setEditItem}
                rowLength={rowLength}
                searchField={searchField}
                editPermission={editPermission}
                viewUserPermission={viewUserPermission}
              />
            )}
          </div>
          {visible && uploadDialog}
        </div>
      </section>
    </div>
  );
};

export default TeamList;
