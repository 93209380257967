import React from "react";
import TeamList from "./TeamList/TeamList";

const TeamPage = () => {
  return (
    <>
      <TeamList />
    </>
  );
};
export default TeamPage;
