import { toast } from "react-toastify";
import { put, takeEvery } from "redux-saga/effects";
import {
  createRoleAPI,
  getAllRolesAPI,
  getAllStandardRolesAPI,
  updateRoleAPI,
} from "../../apis";
import {
  createRoleSlice,
  createRoleSliceError,
  getAllRolesErrorSlice,
  getAllRolesSlice,
  setLoaderTrue,
  updateRoleErrorSlice,
  updateRoleSlice,
} from "../Slices/RoleSlice";
import { CREATE_ROLE, GET_ALL_ROLES, UPDATE_ROLE } from "../Types";

export function* getAllRolesSaga(action) {
  try {
    yield put(setLoaderTrue(true));
    let roles = [];
    if (action.payload === null) roles = yield getAllStandardRolesAPI();
    else roles = yield getAllRolesAPI(action.payload);
    yield put(getAllRolesSlice(roles.data));
  } catch (error) {
    console.log(error);
    yield put(setLoaderTrue(false));
    yield put(getAllRolesErrorSlice());
    toast.error(error?.message);
  }
}

export function* createRoleSaga(action) {
  try {
    const role = yield createRoleAPI(action.payload);
    yield put(createRoleSlice(role.data));
    toast.success("Roles Created Sucessfully!");
  } catch (error) {
    if (typeof error?.response?.data === "object") {
      if (typeof error?.response?.data?.message !== "undefined") {
        toast.error(error?.response?.data?.message);
        yield put(createRoleSliceError(error.response.data.message));
      } else {
        yield put(createRoleSliceError(error.message));
        Object.keys(error?.response?.data).forEach((item) => {
          return toast.error(`${item} : ${error.response.data[item]} `);
        });
      }
    } else {
      toast.error(error?.message);
      yield put(createRoleSliceError(error?.message));
    }
  }
}

export function* updateRoleSaga(action) {
  try {
    yield put(setLoaderTrue(true));
    const role = yield updateRoleAPI(action.payload);
    yield put(updateRoleSlice(role.data));
    toast.success(
      `Roles ${action.payload?.roleData?.isStatus
        ? action.payload?.roleData?.is_active
          ? "Activated"
          : "Deactivated"
        : "Updated"
      } Sucessfully!`
    );
  } catch (error) {

    if (typeof error?.response?.data === "object") {
      if (
        typeof error?.response?.data?.message !== "undefined"
      ) {
        toast.error(error?.message);
        yield put(updateRoleErrorSlice(error?.message));
      } else {
        yield put(updateRoleErrorSlice(error?.message));
        Object.keys(error.response.data).forEach((item) => {
          return toast.error(`${item} : ${error.response.data[item]} `);
        });
      }

    } else {
      toast.error(error?.response?.data?.message);
      yield put(updateRoleErrorSlice(error?.response?.data?.message));
    }

  }
}

export function* watchRolesSaga() {
  yield takeEvery(GET_ALL_ROLES, getAllRolesSaga);
  yield takeEvery(CREATE_ROLE, createRoleSaga);
  yield takeEvery(UPDATE_ROLE, updateRoleSaga);
}
