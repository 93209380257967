import { Box, FormControl } from "@mui/material";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { get, isEmpty } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import ContextProvider from "../../../ContextProvider";
import Breadcrumb from "../../../components/Breadcrumb";
import LoaderWithLabel from "../../../components/ProcessingLoader/LoaderWithLabel";
import Toolbar from "../../../components/Toolbar/Toolbar";
import { GET_USER } from "../../../helper/localstorageReaderHelper";
import { useUploadFileHook } from "../../../hooks/useUploadFileHook";
import {
  createThemeSelect,
  permission,
  userPath,
} from "../../../utils/Constants";
import {
  requiredError,
  validate,
  validateError,
} from "../../../utils/commonFun";
import {
  BU_GET_TEAMS_UNIT,
  CREATE_USER,
  GET_ALL_BUSINESS_UNITS,
  GET_ALL_CLIENTS,
  GET_ALL_ROLES,
  GET_ALL_USERS,
  GET_ALL_USERS_BY_TEAMS,
  GET_TENANT_CLIENT,
} from "./../../../store/Types/index";
import TableGrid from "./UserGrid/UserGrid";
import "./UserList.scss";

const getOptions = ({ menuUser, businessUnits, allTeams, clients }) => {
  let clientOptions = [];
  let businessUnitOptions = [];
  let teamsOptions = [];
  if (menuUser) {
    clientOptions = clients.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    businessUnitOptions = businessUnits.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    teamsOptions = allTeams.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }
  return {
    clientOptions,
    businessUnitOptions,
    teamsOptions,
  };
};

const UserList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");
  const menuUser = location.pathname === userPath;
  const permissions = get(user, "permissions", []);
  const editPermission = permissions.includes(permission.userEdit);
  const addPermission = permissions.includes(permission.userAdd);

  const { fullScreenEnabled } = useContext(ContextProvider);

  const getTenantClientsDispatch = () => {
    if (!isEmpty(tenantId)) {
      dispatch({ type: "client/resetClientError" });
      dispatch({ type: GET_TENANT_CLIENT, payload: tenantId });
    }
  };

  useEffect(() => {
    if (isEmpty(tenantId)) {
      menuUser && dispatch({ type: GET_ALL_CLIENTS });
    } else {
      menuUser && dispatch({ type: GET_ALL_USERS, payload: tenantId });
      menuUser && dispatch({ type: GET_ALL_ROLES, payload: tenantId });
      getTenantClientsDispatch();
    }
  }, []);

  const userSlice = useSelector((state) => state.UserReducer.users);
  const [editItem, setEditItem] = useState([]);

  const [uploadValues, setUploadValues] = useState({
    business_unit: null,
    team: null,
  });

  const users = isEmpty(userSlice) ? [] : userSlice;

  const selectedTeam = useSelector((state) => state.UserReducer.selectedTeam);

  const curClient = useSelector(
    (state) => state.BusinessUnitReducer.selectedClient
  );

  const clients = useSelector((state) => state.ClientReducer.clients);

  const tenantClient = useSelector((state) => state.ClientReducer.tenantClient);

  const selectedClient = !isEmpty(tenantClient) ? tenantClient : curClient;

  const { isLoading, selectedClientId } = useSelector((state) => state.UserReducer);

  const businessUnits = useSelector(
    (state) => state.BusinessUnitReducer.businessUnits
  );

  const allTeams = useSelector((state) => state.TeamReducer.teams);
  const allRoles = useSelector((state) => state.RoleReducer.roles);
  const hasTentant = isEmpty(tenantId);
  const [chosenTeam, setChosenTeam] = useState(null);
  const [currentClient, setCurrentClient] = useState("");
  const [selectClient, setSelectClient] = useState("");
  const [goNext, setGoNext] = useState(true);
  const [rowLength, setRowLength] = useState(10);
  const [pageSize, setPageSize] = useState({
    label: "Show 10 Rows",
    value: 10,
  });

  const [searchField, setSearchField] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (menuUser) {
      setGoNext(false);
      if(!isEmpty(selectedClientId)) {
        setSelectClient(selectedClientId);
        clientPopulation(selectedClientId?.value);
      }
      dispatch({ type: "users/setMenuUser", payload: menuUser });
      dispatch({ type: "users/resetAllUsers" });
      dispatch({ type: "users/resetSelectedTeamSlice" });
      dispatch({ type: "businessUnit/resetSelectedClientSlice" });
      dispatch({ type: "users/resetSelectedClientId" });
    } else {
      dispatch({ type: "users/setMenuUser", payload: menuUser });
      dispatch({ type: "users/resetSelectedClientId" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuUser]);

  useEffect(()=> {
    return  () => {
      !menuUser && dispatch({ type:  "users/resetSelectedClientId" });
    }
  }, [dispatch, menuUser]);


  const { clientOptions, businessUnitOptions, teamsOptions } = getOptions({
    menuUser,
    businessUnits,
    allTeams,
    clients,
  });

  const CurrentDate = moment().format("DD-MM-YYYY");

  const clientPopulation = (value) => {
    setPageSize({ label: "Show 10 Rows", value: 10 });
    setSearchField("");
    const filteredClient = clients.find((obj) => obj.id === value);

    dispatch({
      type: "businessUnit/selectedClientSlice",
      payload: filteredClient,
    });

    dispatch({
      type: "users/setLoaderTrue",
    });

    dispatch({
      type: "teams/resetAllTeamsSlice",
    });

    dispatch({ type: GET_ALL_BUSINESS_UNITS, payload: filteredClient });

    dispatch({ type: GET_ALL_USERS, payload: value });
    dispatch({ type: GET_ALL_ROLES, payload: value });
  };

  const refreshHandler = () => {
    setPageSize({ label: "Show 10 Rows", value: 10 });
    setRefresh(true);
    setSearchField("");
    if (menuUser) {
      if (!isEmpty(selectedClient)) {
        dispatch({ type: GET_ALL_USERS, payload: selectedClient.id });
        dispatch({ type: GET_ALL_ROLES, payload: selectedClient.id });
      } else {
        !isEmpty(tenantId) &&
          dispatch({ type: GET_ALL_USERS, payload: tenantId });
        dispatch({ type: GET_ALL_ROLES, payload: tenantId });
      }
    } else {
      !isEmpty(selectedTeam) &&
        dispatch({ type: GET_ALL_USERS_BY_TEAMS, payload: selectedTeam });
      dispatch({ type: GET_ALL_ROLES, payload: selectedTeam.organization });
    }

    setTimeout(() => {
      setRefresh(false);
    }, 750);
  };

  const csvHeader1 = (type) => {
    const header = [
      [
        "First Name",
        "Last Name",
        "Email Id",
        "Role",
        "Country Code",
        "Contact",
        "Address Line 1",
        "Address Line 2",
        "Country",
        "State",
        "City",
        "Pincode",
        ...(type === "config"
          ? ["Business Unit", "Team", "Active/Inactive"]
          : []),
      ],
    ];

    return header;
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const uploadFileToggle = () => {
    setVisible(!visible);
    menuUser && setGoNext(false);
    setChosenTeam(null);
    dispatch({
      type: "teams/resetAllTeamsSlice",
    });
    setUploadValues({ ...uploadValues, business_unit: null, team: null });
  };

  const headerConfig = () => {
    const resultArr = [];
    csvHeader1("config")[0].forEach((item) => {
      const obj = {
        name: item,
        inputName: item,
        unique: item === "Email Id",
        uniqueError: function (headerName, rowNumber) {
          return `${headerName} in the row number ${rowNumber} should be unique.`;
        },
        required: !(
          item === "City" ||
          item === "Role" ||
          item === "Business Unit" ||
          item === "Team" ||
          item === "Active/Inactive"
        ),
        requiredError,
        validate: (value) => validate(value, item),
        validateError,
      };
      resultArr.push(obj);
    });
    return resultArr;
  };

  const csvHeaderConfig = headerConfig();

  useEffect(() => {
    if (pageSize) {
      setRowLength(pageSize.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  const handleCSVUpload = (uploadedData) => {
    const csvUploadArray = [];
    uploadedData?.forEach((item) => {
      const roleIdArray = [];
      allRoles.forEach((role) => {
        if (role.name.toLowerCase() === item["Role"].toLowerCase()) {
          roleIdArray.push(role.id);
        }
      });
      const obj = {
        organization: selectedClient.id,
        first_name: item["First Name"],
        last_name: item["Last Name"],
        mobile: item["Contact"],
        team: menuUser ? chosenTeam.id : selectedTeam.id,
        business_unit: menuUser
          ? chosenTeam?.business_unit.id
          : selectedTeam?.business_unit.id,
        mobile_country_code: `+${item["Country Code"]}`,
        address_line1: item["Address Line 1"],
        address_line2: item["Address Line 2"],
        email: item["Email Id"],
        roles: roleIdArray,
        country: item.Country,
        state: item.State,
        city: item.City,
        pincode: item.Pincode,
      };
      csvUploadArray.push(obj);
    });

    const body = {
      formData: csvUploadArray,
      org_id: selectedClient.id,
    };

    dispatch({ type: "users/setLoaderTrue" });
    dispatch({ type: "users/resetUsersError" });
    dispatch({ type: "users/resetApiSuccess" });

    dispatch({ type: CREATE_USER, payload: body });
    setTimeout(() => {
      toggleDialog();
    }, 300);
  };

  const headers = [
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email Id", key: "email" },
    { label: "Role", key: "roles" },
    { label: "Country Code", key: "mobile_country_code" },
    { label: "Contact", key: "mobile" },
    { label: "Address Line 1", key: "address_line1" },
    { label: "Address Line 2", key: "address_line2" },
    { label: "Country", key: "country" },
    { label: "State", key: "state" },
    { label: "City", key: "city" },
    { label: "Pincode", key: "pincode" },
    { label: "Business Unit", key: "business_unit.name" },
    { label: "Team", key: "team.name" },
    { label: "Active/Inactive", key: "is_active" },
  ];

  const getFilteredDetails = (items, searchField) => {
    if (isEmpty(searchField)) {
      return items;
    } else {
      return items.filter((item) => {
        return (
          item?.name.toLowerCase().includes(searchField?.toLowerCase()) ||
          item?.email.toLowerCase().includes(searchField?.toLowerCase()) ||
          item?.mobile.toLowerCase().includes(searchField?.toLowerCase()) ||
          item?.business_unit.name
            .toLowerCase()
            .includes(searchField?.toLowerCase()) ||
          item?.team.name.toLowerCase().includes(searchField?.toLowerCase()) ||
          item?.mobile_country_code
            .toLowerCase()
            .includes(searchField?.toLowerCase())
        );
      });
    }
  };

  const filteredUserDetail = getFilteredDetails(users, searchField);
  let csvDownloadDetails = users?.map((item) => {
    let roles = item?.roles?.map((role) => role?.name).join(", ");
    return {
      ...item,
      roles,
    };
  });

  const handleBUDropdown = (bu_id) => {
    const selectedBU = businessUnits.find((obj) => obj.id === bu_id);
    dispatch({ type: BU_GET_TEAMS_UNIT, payload: selectedBU });
  };

  const handleTeamsDropdown = (team_id) => {
    const selectedTeam = allTeams.find((obj) => obj.id === team_id);
    setChosenTeam(selectedTeam);
  };

  const handleNext = () => {
    setGoNext(true);
  };

  const handleBack = () => {
    setGoNext(false);
  };

  const addNew =
    isEmpty(currentClient) && isEmpty(curClient) ? false : "Add New User";

  const csvPermission = !(isEmpty(selectedClient) && isEmpty(curClient));

  const uploadDialog = useUploadFileHook({
    uploadFileToggle,
    handleCSVUpload,
    menuUser,
    handleBack,
    csvHeaderConfig,
    templateDataHandlerCallback: csvHeader1,
    templateFileName: "Users_Template.csv",
  });

  const isEmptyTeanantIdBreadCrumbs =
    !menuUser && isEmpty(tenantId)
      ? [{ name: "Clients", path: "/client" }, selectedClient.name]
      : [];

  const breadCrumbsList = menuUser
    ? ["User List"]
    : [
      ...isEmptyTeanantIdBreadCrumbs,
      {
        name: "Setup",
        path: "/setup",
      },
      selectedTeam?.business_unit?.country,
      selectedTeam?.business_unit?.name,
      {
        name: "Teams",
        path: "/setup/team",
      },
      selectedTeam?.name,
      "Users",
    ];

  return (
    <div>
      <section className="client-listing">
        {isLoading && <LoaderWithLabel />}
        <h3 className="section-heading">Users</h3>
        <Breadcrumb list={breadCrumbsList} />
        <div style={{ width: "250px", margin: "16px 0" }}>
          {menuUser && hasTentant && (
            <>
              <Box
                sx={{ width: "100%", margin: "16px 0", background: "white" }}
              >
                <FormControl fullWidth>
                  <Select
                    theme={createThemeSelect}
                    id="client"
                    name="client"
                    placeholder="Select Client"
                    options={clientOptions}
                    value={selectClient}
                    onChange={(value) => {
                      setSelectClient(value);
                      setCurrentClient(value?.value);
                      clientPopulation(value?.value);
                      dispatch({
                        type: "users/setSelectedClientId",
                        payload: value,
                      });
                    }}
                    classNamePrefix="mySelect"
                  />
                </FormControl>
              </Box>
            </>
          )}
        </div>

        <div className="client-listing-content">
          <Toolbar
            searchLabel="Search"
            addNew={
              addPermission && isEmpty(tenantId) ? addNew : "Add New User"
            }
            addNewPath="/user/create"
            setSearchField={setSearchField}
            setRowLength={setRowLength}
            refreshHandler={refreshHandler}
            searchField={searchField}
            editItem={editItem}
            csvUpload={addPermission && csvPermission}
            csvuploadToggle={toggleDialog}
            csvDownload={csvPermission}
            csvDownloadHeaders={headers}
            csvDownloadDetails={csvDownloadDetails}
            csvDownloadFileName={"Users_" + CurrentDate}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />

          <div className="client-listing-grid">
            {refresh && isLoading ? (
              <LoaderWithLabel />
            ) : (
              <TableGrid
                userDetail={filteredUserDetail}
                rowLength={rowLength}
                searchField={searchField}
                editItem={editItem}
                setEditItem={setEditItem}
                editPermission={editPermission}
              />
            )}
          </div>
          {visible && (
            <>
              {!goNext && (
                <Dialog
                  id="window"
                  appendTo={fullScreenEnabled ? null : document.body}
                >
                  <>
                    <span className="span1">{t("Select BU and Team")}</span>
                    <span
                      className="k-icon k-i-close k-btn1"
                      onClick={uploadFileToggle}
                    ></span>
                    <br />
                    <span className="mt-10 warning custom-span">
                      * Before uploading CSV Please select BU and Team from
                      below options
                    </span>

                    <DialogActionsBar>
                      {menuUser && (
                        <div className="custom-dialog-container">
                          <div>
                            <Select
                              theme={createThemeSelect}
                              id="show"
                              name="show"
                              placeholder="Select Business Unit"
                              options={businessUnitOptions}
                              value={uploadValues?.business_unit}
                              onChange={(value) => {
                                handleBUDropdown(value.value);
                                setUploadValues({
                                  ...uploadValues,
                                  business_unit: value,
                                  team: null,
                                });
                                setChosenTeam(null);
                              }}
                              classNamePrefix="mySelect"
                            />
                          </div>
                          <div style={{ marginTop: "16px" }}>
                            <Select
                              theme={createThemeSelect}
                              id="show"
                              name="show"
                              placeholder="Select Team"
                              options={teamsOptions}
                              value={uploadValues.team}
                              onChange={(value) => {
                                handleTeamsDropdown(value.value);
                                setUploadValues({
                                  ...uploadValues,
                                  team: value,
                                });
                              }}
                              classNamePrefix="mySelect"
                            />
                          </div>
                        </div>
                      )}
                    </DialogActionsBar>

                    <DialogActionsBar>
                      <CSVLink
                        data={csvHeader1()}
                        filename={"Users_Template.csv"}
                      >
                        <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base download-template-custom-class">
                          {t("Download Template")}
                          <span className="k-icon k-i-download"></span>
                        </button>
                      </CSVLink>
                      <button
                        className={`btn cancel-button`}
                        onClick={uploadFileToggle}
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        className={`btn primary-button ${isEmpty(chosenTeam) && "k-disabled"
                          }`}
                        onClick={handleNext}
                      >
                        {t("Next")}
                      </button>
                    </DialogActionsBar>
                  </>
                </Dialog>
              )}
              {goNext && uploadDialog}
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default UserList;
