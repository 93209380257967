import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const TabChangeModel = (props) => {
  const { open, setOpen } = props;

  const toggleDialog = () => {
    setOpen(!open);
  };

  return (
    <div>
      {open && (
        <Dialog id="window" title={"Notice"} onClose={toggleDialog}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            Please save your changes before changing the tab !!!
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base primary-button"
              onClick={toggleDialog}
            >
              Ok
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default TabChangeModel;
