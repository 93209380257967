import { get, isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProcessingLoader from "../../components/ProcessingLoader";
import { GET_USER } from "../../helper/localstorageReaderHelper";
import { GET_TENANT_CLIENT } from "../../store/Types";
import BusinessUnitList from "./BusinessUnitList/BusinessUnitList";

const BusinessUnitPage = () => {
  const user = GET_USER();
  const dispatch = useDispatch();

  const tenantId = get(user, "tenant_id", "");
  const getTenantClientsDispatch = () => {
    if (!isEmpty(tenantId)) {
      dispatch({ type: "client/resetClientError" });
      dispatch({ type: GET_TENANT_CLIENT, payload: tenantId });
    }
  };
  useEffect(() => {
    getTenantClientsDispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tenantClient = useSelector((state) => state.ClientReducer.tenantClient);

  const selectedClient = useSelector(
    (state) => state.BusinessUnitReducer.selectedClient
  );

  const currentClient = isEmpty(tenantId) ? selectedClient : tenantClient;

  return (
    <>
      {isEmpty(currentClient) ? (
        <ProcessingLoader />
      ) : (
        <BusinessUnitList selectedClient={currentClient} />
      )}
    </>
  );
};
export default BusinessUnitPage;
