import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Breadcrumb from "../../../components/Breadcrumb/index.js";
import CustomInputContainer from "../../../components/CustomInputContainer.js";
import {
  convertLocalNumber,
  getPhoneCountryCode,
} from "../../../components/CustomPhoneInput";
import LoaderWithLabel from "../../../components/ProcessingLoader/LoaderWithLabel";
import { GET_USER } from "../../../helper/localstorageReaderHelper.js";
import { EDIT_TEAM_UNIT } from "../../../store/Types";
import { updatedCities, updatedCountries, updatedStates } from "../../../utils";
import "../../BusinessUnit/BusinessUnitCreation/BusinessUnitCreation.scss";
const validator = new SimpleReactValidator();

const TeamEdit = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");
  const editteamBU = useSelector((state) => state.TeamReducer.selectedTeam);

  const selectedBUClient = useSelector(
    (state) => state.BusinessUnitReducer.selectedClient
  );

  const tenantClient = useSelector((state) => state.ClientReducer.tenantClient);

  const selectedClient = !isEmpty(tenantClient)
    ? tenantClient
    : selectedBUClient;

  const [, forceUpdate] = useState();

  const [editCountry, SetEditCountry] = useState("");
  const [editState, SetEditState] = useState("");
  const [editCity, SetEditCity] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const teamError = useSelector((state) => state.TeamReducer.error);
  const teamSuccess = useSelector((state) => state.TeamReducer.apiSuccess);
  const teamLoader = useSelector((state) => state.TeamReducer.loader);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    business_unit_name: "",
    contact_person_name: "",
    contact_person_number: "",
    contact_person_country_code: "+91",
    email: "",
    address_line1: "",
    address_line2: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    organization: "",
    business_unit: "",
    user_count: 0,
  });

  useEffect(() => {

    if (isSubmitted && teamSuccess && !teamError) {
      navigate("/setup/team");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamLoader, teamError, isSubmitted]);

  useEffect(() => {
    if (!isEmpty(editteamBU)) {
      const contactNumber = editteamBU.contact_person_country_code.concat(
        editteamBU.contact_person_number
      );
      let localNumber = convertLocalNumber(contactNumber);
      let countryCode = getPhoneCountryCode(contactNumber);
      setFormData({
        id: editteamBU.id,
        name: editteamBU.name,
        business_unit_name: editteamBU.business_unit.name,
        contact_person_name: editteamBU.contact_person_name,
        contact_person_number: countryCode + localNumber,
        contact_person_country_code: editteamBU.contact_person_country_code,
        email: editteamBU.email,
        address_line1: editteamBU.address_line1,
        address_line2: editteamBU.address_line2,
        country: editteamBU.country,
        state: editteamBU.state,
        city: editteamBU.city,
        pincode: editteamBU.pincode,
        organization: editteamBU.organization,
        business_unit: editteamBU.business_unit.id,
        user_count: editteamBU.user_count,
      });

      const selectedCountry = updatedCountries.find((elem) => {
        return elem.label === editteamBU.country;
      });

      const selectedStates = !isEmpty(selectedCountry)
        ? updatedStates(selectedCountry.id)
        : [];

      const selectedState = !isEmpty(selectedCountry)
        ? selectedStates.find((elem) => {
          return elem.label === editteamBU.state;
        })
        : [];

      SetEditCountry({ label: editteamBU.country });
      SetEditState({ label: editteamBU.state });
      SetEditCity({ label: editteamBU.city });
      setValues(
        {
          country: selectedCountry,
          state: selectedState,
          city: editteamBU.city,
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editteamBU]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const addressFromik = useFormik({
    initialValues: {
      country: "India",
      state: null,
      city: null,
    },
  });

  const { values, setFieldValue, setValues } = addressFromik;

  const offsetHeight = window.innerHeight - 100;

  const onSubmit = () => {
    if (validator.allValid()) {
      let localNumber = convertLocalNumber(formData.contact_person_number);
      let countryCode = getPhoneCountryCode(formData.contact_person_number);

      const payload = { ...formData };

      payload.contact_person_number = localNumber;
      payload.contact_person_country_code = countryCode;
      payload.is_active = editteamBU.is_active;

      dispatch({ type: "teams/resetTeamError" });
      dispatch({ type: "teams/setLoaderTrue" });
      dispatch({ type: "teams/resetApiSuccess" });

      dispatch({ type: EDIT_TEAM_UNIT, payload: payload });
      setIsSubmitted(true);
    } else {
      validator.showMessages();
      forceUpdate(1);
      return false;
    }
  };

  return (
    <section className="bu-creation" style={{ height: `${offsetHeight}px` }}>
      {teamLoader && <LoaderWithLabel />}
      <h3 className="section-heading">{t("Update Team")}</h3>
      <Breadcrumb
        list={[
          isEmpty(tenantId) && {
            name: t("Clients"),
            path: "/client",
          },
          isEmpty(tenantId) && selectedClient.name,
          {
            name: t("Setup"),
            path: "/setup",
          },
          editteamBU.business_unit.country,
          formData.business_unit_name,
          {
            name: t("Teams"),
            path: "/setup/team",
          },
          formData.name,
          t("Update Team"),
        ]}
      />

      <CustomInputContainer
        title={"Team Information"}
        updatedCountries={updatedCountries}
        editCountry={editCountry}
        setFieldValue={setFieldValue}
        onChange={onChange}
        validator={validator}
        cancelRoute="/setup/team"
        type="TEAM"
        formData={formData}
        setFormData={setFormData}
        onSubmit={onSubmit}
        SetEditCountry={SetEditCountry}
        SetEditState={SetEditState}
        SetEditCity={SetEditCity}
        setValues={setValues}
        editState={editState}
        editCity={editCity}
        values={values}
        updatedCities={updatedCities}
        updatedStates={updatedStates}
      />
    </section>
  );
};

export default TeamEdit;
