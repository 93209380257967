import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { withStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import InputWithIcon from "../../globalComponent/InputWithIcon";
import { SHOW_ROWS } from "../../utils/Constants";
import CustomSelect from "../customSelect";
import "./Toolbar.scss";
const styles = {
  input1: {
    padding: "13px 12px",
  },
};

const Toolbar = (props) => {
  const {
    setSearchField,
    setRowLength,
    searchLabel,
    refreshHandler,
    searchField,
    editItem,
    onConfigureClick,
    onSetupClick,
    setup,
    configure,
    addNew,
    addNewPath,
    onEditClick,
    csvDownloadHeaders,
    csvDownloadDetails,
    csvDownload,
    csvDownloadFileName,
    csvUpload,
    csvuploadToggle,
    pageSize,
    setPageSize,
  } = props;

  const { t } = useTranslation();

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `Show ${item} Rows`,
    value: item,
  }));

  useEffect(() => {
    if (!isEmpty(pageSize)) {
      setRowLength(pageSize.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  return (
    <div className="client-listing-toolbar">
      <div className="client-listing-toolbar-left">
        <div
          className={`client-listing-toolbar-left-search ${
            addNewPath.includes("team") && "minWidth"
          }`}
        >
          <InputWithIcon
            label={searchLabel}
            name={"search"}
            value={searchField}
            onChange={handleChange}
            icon={<SearchIcon />}
          />
        </div>
        <div className="client-listing-toolbar-left-show ml-10">
          <CustomSelect
            placeholder={`${t("Show")} 10 ${t("Rows")}`}
            options={showMoreOptions || []}
            value={{
              label: `${pageSize?.label}`,
              value: pageSize?.value,
            }}
            onChange={(newPageSize) => {
              setPageSize(newPageSize);
            }}
          />
        </div>
        <div
          className="client-listing-toolbar-left-refresh ml-10"
          data-testid="client-refresh"
          onClick={() => {
            refreshHandler();
          }}
        >
          <i className="fa fa-undo" aria-hidden="true"></i>
        </div>
      </div>

      <div className="client-listing-toolbar-right">
        <div className="client-listing-toolbar-right-add-client">
          {setup && (
            <button
              data-testid="client-setup"
              className={`btn cancel-button ml-10 ${editItem.length !== 1 && "disabled"
                }`}
              onClick={onSetupClick}
            >
              Setup
            </button>
          )}
          {configure && (
            <button
              data-testid="client-config"
              className={`btn primary-button ml-10 ${editItem.length !== 1 && "disabled"
                }`}
              onClick={onConfigureClick}
            >
              Configure
            </button>
          )}
        </div>
        {onEditClick && (
          <div
            className={`client-listing-toolbar-right-edit ml-10 ${editItem.length !== 1 && "disabled"
              }`}
          >
            <EditIcon onClick={onEditClick} />
          </div>
        )}
        {csvUpload && (
          <div className="client-listing-toolbar-right-csv-download mr-20">
            {
              <button
                className="btn secondary-button"
                onClick={csvuploadToggle}
              >
                {" "}
                CSV Upload <i className="fa fa-upload" aria-hidden="true"></i>
              </button>
            }
          </div>
        )}
        {csvDownload && (
          <div className="client-listing-toolbar-right-csv-download">
            <button
              className="btn secondary-button csv-download-button"
              disabled={
                isEmpty(editItem)
                  ? csvDownloadDetails.length === 0
                  : editItem.length === 0
              }
            >
              <CSVLink
                data={isEmpty(editItem) ? csvDownloadDetails : editItem}
                headers={csvDownloadHeaders}
                filename={csvDownloadFileName}
              >
                {" "}
                CSV Download{" "}
                <i className="fa fa-download" aria-hidden="true"></i>
              </CSVLink>
            </button>
          </div>
        )}
        {addNew && (
          <div className="client-listing-toolbar-right-add-client">
            <Link to={addNewPath}>
              <button className="btn primary-button ml-20">
                {addNew}{" "}
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(Toolbar);
