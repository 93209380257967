import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CustomFieldValidator from "../../components/CustomFieldValidator";
import CustomPhoneInput from "../../components/CustomPhoneInput";
import InputField from "../../globalComponent/InputField";
import {
  createThemeSelect,
  entitiesNameValidation,
  getInputLabels,
  nameValidation,
  tanNoErrorMessage,
  tanNoValidationMessage,
  tanRegex,
} from "../../utils/Constants";

const CustomInputContainer = ({
  title,
  formData,
  setFormData,
  onSubmit,
  SetEditCountry,
  SetEditState,
  SetEditCity,
  setValues,
  updatedCountries,
  editCountry,
  editState,
  editCity,
  values,
  updatedCities,
  updatedStates,
  setFieldValue,
  onChange,
  validator,
  cancelRoute,
  type,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const diabledInput = ["business_unit_name"];
  const ignoreInput = [
    "id",
    "organization",
    "business_unit",
    "user_count",
    "contact_person_country_code",
  ];
  return (
    <div className="bu-creation-container">
      <div className="bu-create-container m-1">
        <h3 className="section-subheading mb-4">{t(title)}</h3>
      </div>
      <div className="mb-4">
        <Grid container spacing={2.5}>
          {Object.keys(formData).map((key) => {
            if (!ignoreInput.includes(key))
              return (
                <>
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    {key === "contact_person_number" ? (
                      <>
                        <CustomPhoneInput
                          placeholder={getInputLabels(type)[key]}
                          value={formData[key]}
                          onChange={(val) =>
                            setFormData({
                              ...formData,
                              contact_person_number: val,
                            })
                          }
                          className="phone-input"
                        />
                      </>
                    ) : key === "country" ||
                      key === "city" ||
                      key === "state" ? (
                      <>
                        {" "}
                        <Select
                          theme={createThemeSelect}
                          classNamePrefix="mySelect"
                          className="bu-creation-select"
                          id={key}
                          name={key}
                          placeholder={getInputLabels(type)[key]}
                          label={getInputLabels(type)[key]}
                          options={
                            key === "state"
                              ? updatedStates(
                                  values.country ? values.country.value : null
                                )
                              : key === "city"
                              ? updatedCities(
                                  values.state ? values.state.value : null
                                )
                              : updatedCountries
                          }
                          value={
                            key === "state"
                              ? editState
                              : key === "city"
                              ? editCity?.label ? editCity : null
                              : editCountry
                          }
                          onChange={(value) => {
                            if (key === "country") {
                              SetEditCountry();
                              SetEditState(null);
                              SetEditCity(null);
                              setFormData({
                                ...formData,
                                country: value.name,
                                state: "",
                                city: "",
                              });
                              setValues(
                                {
                                  country: value,
                                  state: null,
                                  city: null,
                                },
                                false
                              );
                            }
                            if (key === "state") {
                              SetEditState();
                              SetEditCity(null);
                              setFormData({
                                ...formData,
                                state: value.name,
                                city: "",
                              });
                              setValues(
                                { ...values, state: value, city: null },
                                false
                              );
                            }
                            if (key === "city") {
                              SetEditCity({
                                label: value?.name,
                                value: value?.value,
                              });
                              setFormData({ ...formData, city: value.name });
                              setFieldValue("city", value);
                            }
                          }}
                        />{" "}
                      </>
                    ) : (
                      <>
                        <InputField
                          disabled={diabledInput.includes(key)}
                          label={getInputLabels(type)[key]}
                          name={key}
                          value={formData[key]}
                          onChange={
                            diabledInput.includes(key)
                              ? () => {}
                              : key === "email"
                              ? (event) =>
                                  setFormData({
                                    ...formData,
                                    email: event.target.value,
                                  })
                              : onChange
                          }
                        />
                      </>
                    )}
                    {key === "tan" && (
                      <span className="bu-helper-text">
                        {tanNoValidationMessage}
                      </span>
                    )}
                    {/* Custom validator */}
                    {(key !== "address_line2" && key !== "city") ? (
                      <CustomFieldValidator
                        validator={validator}
                        field={getInputLabels(type)[key]}
                        inputValue={formData[key]}
                        validations={
                          key === "name" ||
                          key === "business_unit_name" ||
                          key === "contact_person_name"
                            ? nameValidation
                            : key === "contact_person_number"
                            ? [("required", "phone", { max: "13" })]
                            : key === "email"
                            ? "required|email"
                            : key === "pincode"
                            ? "required|numeric|min:4|max:125"
                            : key === "entities_name"
                            ? entitiesNameValidation
                            : ["required"]
                        }
                      />
                    ) : (
                      key === "tan" && (
                        <CustomFieldValidator
                          validator={validator}
                          field={getInputLabels(type)[key]}
                          inputValue={formData[key]}
                          validations={["required", { regex: tanRegex }]}
                          customMessage={tanNoErrorMessage}
                        />
                      )
                    )}
                  </Grid>
                </>
              );
          })}
        </Grid>
      </div>
      <div className="bu-creation-submit-container mt-4 text-end">
        <button className="btn primary-button" onClick={onSubmit}>
          {t("Update")}
        </button>
        <button
          className="btn cancel-button ml-20"
          onClick={() => navigate(cancelRoute)}
        >
          {t("Cancel")}
        </button>
      </div>
    </div>
  );
};

export default CustomInputContainer;
