import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import CustomFieldValidator from "../../../components/CustomFieldValidator";
import ProcessingLoader from "../../../components/ProcessingLoader";
import LoaderWithLabel from "../../../components/ProcessingLoader/LoaderWithLabel";
import InputField from "../../../globalComponent/InputField";
import { getUpdatedFields, handleChangeAccordion, handleFormChange, handleInput, handleOptionsChange, updateAccordionState } from "../../../helper/commonHelper";
import { GET_USER } from "../../../helper/localstorageReaderHelper";
import { useCommonHook } from "../../../hooks/useCommonHook";
import { CREATE_BUSINESSUNIT } from "../../../store/Types";
import { updatedCities, updatedCountries, updatedStates } from "../../../utils";
import {
  createThemeSelect,
  cryptoRandom,
  entitiesNameValidation,
  nameValidation,
  tanNoErrorMessage,
  tanNoValidationMessage,
  tanRegex,
} from "../../../utils/Constants";
import CustomPhoneInput from "./../../../components/CustomPhoneInput";
import "./BusinessUnitCreation.scss";

const validator = new SimpleReactValidator();

const BusinessUnitCreation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");
  const [expandedAccordion, setExpandedAccordion] = useState([]);

  const currentClient = useSelector(
    (state) => state.BusinessUnitReducer.selectedClient
  );

  const tenantClient = useSelector((state) => state.ClientReducer.tenantClient);

  const selectedClient = !isEmpty(tenantClient) ? tenantClient : currentClient;

  const [inputFields, setInputFields] = useState([
    {
      id: cryptoRandom(),
      name: "",
      entities_name: "",
      contact_person_name: "",
      contact_person_number: "",
      contact_person_country_code: "",
      tan: "",
      address_line1: "",
      address_line2: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    },
  ]);

  const dispatch = useDispatch();

  const [, forceUpdate] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const buError = useSelector((state) => state.BusinessUnitReducer.error);
  const buLoader = useSelector((state) => state.BusinessUnitReducer.loader);
  const buSuccess = useSelector(
    (state) => state.BusinessUnitReducer.apiSuccess
  );

  useEffect(() => {
    if (isSubmitted) {
      if (buSuccess && !buError) {
        navigate("/setup");
      }
    }
    return () => {
      // to remove error message on mount
      validator.hideMessages()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buLoader, buError, isSubmitted]);

  useCommonHook(inputFields, setExpandedAccordion);
  const { values, setValues } = useFormik({
    initialValues: [
      {
        country: "India",
        state: null,
        city: null,
      },
    ],
  });;

  const onSubmit = () => {
    if (validator.allValid()) {
      const updatedInputFields = getUpdatedFields(inputFields);
      const payload = {
        formData: updatedInputFields,
        org_id: selectedClient.id,
      };

      dispatch({ type: "businessUnit/resetBUError" });
      dispatch({ type: "businessUnit/setLoaderTrue" });
      dispatch({ type: "businessUnit/resetApiSuccess" });

      dispatch({ type: CREATE_BUSINESSUNIT, payload: payload });
      setIsSubmitted(true);
    } else {
      validator.showMessages();
      forceUpdate(1);
      updateAccordionState(inputFields, setExpandedAccordion);
      return false;
    }
  };
  const addFields = () => {
    let newfield = {
      id: cryptoRandom(),
      name: "",
      entities_name: "",
      contact_person_name: "",
      contact_person_number: "",
      contact_person_country_code: "+91",
      tan: "",
      address_line1: "",
      address_line2: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    };
    setInputFields([...inputFields, newfield]);

    let newOptionsFields = {
      country: "India",
      state: null,
      city: null,
    };
    setValues([...values, newOptionsFields]);
  };

  const deleteForm = (input, e) => {
    e.preventDefault();
    setInputFields((prevState) => prevState.filter((e) => e !== input));
  };

  const returnToSetup = () => {
    navigate("/setup");
  };

  return (
    <>
      {isEmpty(selectedClient) ? (
        <ProcessingLoader />
      ) : (
        <section className="bu-creation">
          {buLoader && <LoaderWithLabel />}
          <h3 className="section-heading">{t("Create BU")}</h3>
          <div className="section-breadcrumbs">
            <nav aria-label="breadcrumb">
              {isEmpty(tenantId) ? (
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/client">
                      <span className="section-breadcrumbs-a">Clients</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <span className="section-breadcrumbs-a">
                      {selectedClient.name}
                    </span>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to="/setup"
                      className="section-breadcrumbs-a"
                      onClick={returnToSetup}
                    >
                      Setup
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Create BU
                  </li>
                </ol>
              ) : (
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link
                      to="/setup"
                      className="section-breadcrumbs-a"
                      onClick={returnToSetup}
                    >
                      Setup
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Create BU
                  </li>
                </ol>
              )}
            </nav>
          </div>
          <div className="bu-create-container m-1">
            <h3 className="section-subheading">{t("BU Information")}</h3>
          </div>
          <div style={{ background: "white" }}>
            {inputFields.map((input, index) => {
              return (
                <form key={`bu-create-${index.toString()}`}>
                  <div className="mb-2">
                    <Accordion
                      defaultExpanded
                      expanded={expandedAccordion.includes(input?.id)}

                      onChange={handleChangeAccordion(input?.id)}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{ color: "white", fontSize: "1.25rem" }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          // background: "#292666",
                          borderRadius: "0.5rem", minHeight: "3rem", maxHeight: "3rem",
                          "&.Mui-expanded": {
                            minHeight: "3rem",
                            maxHeight: "3rem",
                          }
                        }}
                      >
                        <Typography>{`Business Unit ${index + 1
                          }*`}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="bu-creation-container">
                          <div className="mb-4">
                            <Grid container spacing={2.5}>
                              <Grid item lg={3} md={4} sm={6} xs={12}>
                                <InputField
                                  label={"BU Name*"}
                                  name={"name"}
                                  value={input.name}
                                  onChange={(event) =>
                                    handleFormChange(index, event, inputFields, setInputFields)
                                  }
                                />
                                <CustomFieldValidator
                                  validator={validator}
                                  field={"BU Name"}
                                  inputValue={input.name}
                                  validations={nameValidation}
                                />
                              </Grid>
                              <Grid item lg={3} md={4} sm={6} xs={12}>
                                <InputField
                                  label={"BU Entities Name*"}
                                  name={"entities_name"}
                                  value={input.entities_name}
                                  onChange={(event) =>
                                    handleFormChange(index, event, inputFields, setInputFields)
                                  }
                                />
                                <CustomFieldValidator
                                  validator={validator}
                                  field={"BU Entities Name"}
                                  inputValue={input.entities_name}
                                  validations={entitiesNameValidation}
                                />
                              </Grid>
                              <Grid item lg={3} md={4} sm={6} xs={12}>
                                <InputField
                                  label={"BU Contact Person Name*"}
                                  name={"contact_person_name"}
                                  value={input.contact_person_name}
                                  onChange={(event) =>
                                    handleFormChange(index, event, inputFields, setInputFields)
                                  }
                                />
                                <CustomFieldValidator
                                  validator={validator}
                                  field={"BU Contact Person Name"}
                                  inputValue={input.contact_person_name}
                                  validations={nameValidation}
                                />
                              </Grid>
                              <Grid item lg={3} md={4} sm={6} xs={12}>
                                <CustomPhoneInput
                                  placeholder="BU Contact Person Number*"
                                  value={input.contact_person_number}
                                  withCountryCallingCode={true}
                                  onChange={(val) =>
                                    handleInput(
                                      index,
                                      val,
                                      "contact_person_number",
                                      inputFields,
                                      setInputFields
                                    )
                                  }
                                  className="phone-input"
                                />
                                <CustomFieldValidator
                                  validator={validator}
                                  field={"BU Contact Person Number"}
                                  inputValue={input.contact_person_number}
                                  validations={[
                                    "required",
                                    "phone",
                                    { max: "13" },
                                  ]}
                                />
                              </Grid>
                              <Grid item lg={3} md={4} sm={6} xs={12}>
                                <InputField
                                  label={"TAN Number*"}
                                  name={"tan"}
                                  value={input.tan}
                                  onChange={(event) =>
                                    handleFormChange(index, event, inputFields, setInputFields)
                                  }
                                />
                                <span className="bu-helper-text">
                                  {tanNoValidationMessage}
                                </span>
                                <CustomFieldValidator
                                  validator={validator}
                                  field={"TAN Number"}
                                  inputValue={input.tan}
                                  validations={[
                                    "required",
                                    { regex: tanRegex },
                                  ]}
                                  customMessage={tanNoErrorMessage}
                                />
                              </Grid>
                              <Grid item lg={3} md={4} sm={6} xs={12}>
                                <InputField
                                  label={"Address Line 1*"}
                                  name={"address_line1"}
                                  value={input.address_line1}
                                  onChange={(event) =>
                                    handleFormChange(index, event, inputFields, setInputFields)
                                  }
                                />
                                <CustomFieldValidator
                                  validator={validator}
                                  field={"Address Line 1"}
                                  inputValue={input.address_line1}
                                  validations={["required", { max: "400" }]}
                                />
                              </Grid>
                              <Grid item lg={3} md={4} sm={6} xs={12}>
                                <InputField
                                  label={"Address Line 2"}
                                  name={"address_line2"}
                                  value={input.address_line2}
                                  onChange={(event) =>
                                    handleFormChange(index, event, inputFields, setInputFields)
                                  }
                                />
                                <CustomFieldValidator
                                  validator={validator}
                                  field={"Address Line 2"}
                                  inputValue={input.address_line2}
                                  validations={["max:400"]}
                                />
                              </Grid>

                              <Grid item lg={3} md={4} sm={6} xs={12}>
                                {" "}
                                <Select
                                  theme={createThemeSelect}
                                  classNamePrefix="mySelect"
                                  className="bu-creation-select"
                                  id="country"
                                  name="country"
                                  placeholder="Country*"
                                  label="Country*"
                                  options={updatedCountries}
                                  value={input.country}
                                  onChange={(value) => {
                                    handleInput(index, value, "country", inputFields, setInputFields);
                                    handleInput(index, null, "state", inputFields, setInputFields);
                                    handleInput(index, null, "city", inputFields, setInputFields);
                                    handleOptionsChange(
                                      index,
                                      value,
                                      "country",
                                      values,
                                      setValues
                                    );
                                  }}
                                />{" "}
                                <div style={{ marginTop: "1rem" }}>
                                  <CustomFieldValidator
                                    validator={validator}
                                    field={"Country"}
                                    inputValue={input.country}
                                    validations={["required"]}
                                  />
                                </div>
                              </Grid>

                              <Grid item lg={3} md={4} sm={6} xs={12}>
                                {" "}
                                <Select
                                  theme={createThemeSelect}
                                  classNamePrefix="mySelect"
                                  className="bu-creation-select"
                                  id="state"
                                  name="state"
                                  placeholder="State*"
                                  options={updatedStates(
                                    values[index].country
                                      ? values[index].country.value
                                      : null
                                  )}
                                  value={input.state}
                                  onChange={(value) => {
                                    handleInput(index, value, "state", inputFields, setInputFields);
                                    handleInput(index, null, "city", inputFields, setInputFields);
                                    handleOptionsChange(index, value, "state", values, setValues);
                                  }}
                                />{" "}
                                <div style={{ marginTop: "1rem" }}>
                                  <CustomFieldValidator
                                    validator={validator}
                                    field={"State"}
                                    inputValue={input.state}
                                    validations={["required"]}
                                  />
                                </div>
                              </Grid>

                              <Grid item lg={3} md={4} sm={6} xs={12}>
                                <Select
                                  theme={createThemeSelect}
                                  classNamePrefix="mySelect"
                                  className="bu-creation-select"
                                  id="city"
                                  name="city"
                                  placeholder="City"
                                  options={updatedCities(
                                    values[index].state
                                      ? values[index].state.value
                                      : null
                                  )}
                                  value={input.city}
                                  onChange={(value) => {
                                    handleInput(index, value, "city", inputFields, setInputFields);
                                    handleOptionsChange(index, value, "city", values, setValues);
                                  }}
                                />
                                <CustomFieldValidator
                                  validator={validator}
                                  field={"City"}
                                  inputValue={input.city}
                                  validations={[]}
                                />
                              </Grid>
                              <Grid item lg={3} md={4} sm={6} xs={12}>
                                <InputField
                                  label={"PIN Code*"}
                                  name={"pincode"}
                                  value={input.pincode}
                                  onChange={(event) =>
                                    handleFormChange(index, event, inputFields, setInputFields)
                                  }
                                />
                                <CustomFieldValidator
                                  validator={validator}
                                  field={"Pincode"}
                                  inputValue={input.pincode}
                                  validations={"required|numeric|min:4|max:125"}
                                />
                              </Grid>
                            </Grid>
                          </div>
                          {index !== 0 && (
                            <div>
                              <button
                                className="bu-create-delete"
                                onClick={(e) => deleteForm(input, e)}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </form>
              );
            })}
          </div>
          <div className="bu-create-container m-1">
            <button className="btn primary-button" onClick={addFields}>
              Add New BU{" "}
              <i className="fa fa-plus-circle" aria-hidden="true"></i>
            </button>
          </div>
          <div className="bu-creation-submit-container mt-4 text-end mb-4">
            <button className="btn primary-button" onClick={onSubmit}>
              {t("Create")}
            </button>
            <button
              className="btn cancel-button ml-20"
              onClick={() => navigate("/setup")}
            >
              {t("Cancel")}
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default BusinessUnitCreation;
