import { ENUM_USER_TYPE } from "./Constants";

export const getCsv = (detailsList, type) => {
  let csvFileData = [];
  let isBu = type === ENUM_USER_TYPE.BU;
  let flag = 0;
  detailsList?.forEach((Item) => {
    if (flag === 0) {
      csvFileData.push([
        `${isBu && "BU"} Name`,
        `${isBu && "BU"} Entities Name`,
        `${isBu && "BU"} Contact Person Name`,
        `${isBu && "BU"} Contact Person Country Code`,
        `${isBu && "BU"} Contact Person Number`,
        "TAN Number",
        "Address Line 1",
        "Address Line 2",
        "Country",
        "State",
        "City",
        "Pincode",
        ...(isBu ? ["Active/Inactive"] : []),
      ]);
    }
    flag = 1;
    let val =
      Item.name +
      "," +
      Item.entities_name +
      "," +
      Item.contact_person_name +
      "," +
      Item.contact_person_country_code +
      "," +
      Item.contact_person_number +
      "," +
      Item.tan +
      "," +
      Item.address_line1 +
      "," +
      Item.address_line2 +
      "," +
      Item.country +
      "," +
      Item.state +
      "," +
      Item.city +
      "," +
      Item.pincode;
    if (isBu) {
      val += "," + Item.is_active;
    }
    csvFileData.push(val.split(","));
  });
  return csvFileData;
};

export const requiredError = (headerName, rowNumber, columnNumber) => {
  return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
};

export const validate = (value, item) => {
  console.log("value", value, item);
  let regex = /^[A-Za-z0-9_-\s]+$/;
  let pincodeRegex = /^\d+$/;
  if (
    item === "BU Name" ||
    item === "BU Entities Name" ||
    item === "BU Contact Person Name" ||
    item === "Name" ||
    item === "Contact Person Name" ||
    item === "First Name" ||
    item === "Last Name"
  ) {
    return regex.test(value);
  } else if (item === "Pincode") {
    const pincode = value;
    return pincodeRegex.test(value) && pincode.length > 3;
  } else if (item === "BU Contact Person Country Code") {
    const countryCode = value;

    return !!(countryCode.length <= 3 && Number(countryCode));
  } else if (item === "BU Contact Person Number") {
    const phoneNumber = value;
    return !!(phoneNumber.length === 10 && Number(phoneNumber));
  } else return true;
};

export const validateError = (headerName, rowNumber, columnNumber) => {
  if (
    headerName === "BU Name" ||
    headerName === "BU Entities Name" ||
    headerName === "BU Contact Person Name" ||
    headerName === "Name" ||
    headerName === "Contact Person Name" ||
    headerName === "First Name" ||
    headerName === "Last Name"
  ) {
    return `${headerName} in ${rowNumber} row / ${columnNumber} column must be Only Contain Letters, Numbers, Dashes, And Spaces.`;
  } else if (headerName === "Pincode") {
    return `${headerName} in ${rowNumber} row / ${columnNumber} column must be at least 4 characters and only contain numbers.`;
  } else if (headerName === "BU Contact Person Country Code") {
    return `${headerName} in ${rowNumber} row / ${columnNumber} column must be of maximum 3 digits`;
  } else if (headerName === "BU Contact Person Number") {
    return `${headerName} in ${rowNumber} row / ${columnNumber} column must be of 10 digit`;
  }
};

export const firstLetterCapitalInWord = (sentence) => {
  return sentence
    .split(" ")
    .map((ele) => {
      let firstLet = ele[0].toUpperCase();
      let restLet = ele.substring(1);
      return firstLet + restLet;
    })
    .join(" ");
};
