import React from "react";

const ActivateText = () => {
  return (
    <div className="active-de-active">
      <span>Activate/</span>
      <span>Deactivate</span>
    </div>
  );
};

export default ActivateText;
