import { toast } from "react-toastify";

export const createErrorToastMessages = (error) => {
  if (error?.response?.data?.length > 0) {
    error.response.data.forEach((item) => {
      Object.keys(item).forEach((err) => {
        item[err].forEach((error) => {
          toast.error(`${err}: ${error}`);
        });
      });
    });
  } else if (error?.response?.data) {
    Object.keys(error.response.data).forEach((err) => {
      error.response.data[err].forEach((error) => {
        toast.error(`${err}: ${error}`);
      });
    });
  } else {
    toast.error(`${error.message}, Please try after sometime`);
  }
};
