import { useFormik } from "formik";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import Breadcrumb from "../../../components/Breadcrumb/index.js";
import CustomInputContainer from "../../../components/CustomInputContainer.js";
import {
  convertLocalNumber,
  getPhoneCountryCode,
} from "../../../components/CustomPhoneInput";
import LoaderWithLabel from "../../../components/ProcessingLoader/LoaderWithLabel";
import { GET_USER } from "../../../helper/localstorageReaderHelper.js";
import { EDIT_BUSINESSUNIT } from "../../../store/Types";
import { updatedCities, updatedCountries, updatedStates } from "../../../utils";
import "../BusinessUnitCreation/BusinessUnitCreation.scss";

const validator = new SimpleReactValidator();

const BusinessUnitEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedBU = get(location, "state", "");
  const [, forceUpdate] = useState();
  const user = GET_USER();
  const tenantId = get(user, "tenant_id", "");

  const [editCountry, SetEditCountry] = useState("");
  const [editState, SetEditState] = useState("");
  const [editCity, SetEditCity] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    entities_name: "",
    contact_person_name: "",
    contact_person_number: "",
    contact_person_country_code: "+91",
    tan: "",
    address_line1: "",
    address_line2: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
  });

  const currentClient = useSelector(
    (state) => state.BusinessUnitReducer.selectedClient
  );
  const tenantClient = useSelector((state) => state.ClientReducer.tenantClient);
  const selectedClient = !isEmpty(tenantClient) ? tenantClient : currentClient;
  const buError = useSelector((state) => state.BusinessUnitReducer.error);
  const buLoader = useSelector((state) => state.BusinessUnitReducer.loader);
  const buSuccess = useSelector(
    (state) => state.BusinessUnitReducer.apiSuccess
  );

  useEffect(() => {
    if (isSubmitted) {
      if (buSuccess && !buError) {
        navigate("/setup");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buLoader, buError, isSubmitted]);

  useEffect(() => {
    if (!isEmpty(selectedBU)) {
      const contactNumber = selectedBU.contact_person_country_code.concat(
        selectedBU.contact_person_number
      );
      let localNumber = convertLocalNumber(contactNumber);
      let countryCode = getPhoneCountryCode(contactNumber);

      setFormData({
        name: selectedBU.name,
        entities_name: selectedBU.entities_name,
        contact_person_name: selectedBU.contact_person_name,
        contact_person_number: countryCode + localNumber,
        contact_person_country_code: selectedBU.contact_person_country_code,
        tan: selectedBU.tan,
        address_line1: selectedBU.address_line1,
        address_line2: selectedBU.address_line2,
        country: selectedBU.country,
        state: selectedBU.state,
        city: selectedBU.city,
        pincode: selectedBU.pincode,
      });
      const selectedCountry = updatedCountries.find((elem) => {
        return elem.label === selectedBU.country;
      });

      const selectedStates = !isEmpty(selectedCountry)
        ? updatedStates(selectedCountry.id)
        : [];

      const selectedState = !isEmpty(selectedCountry)
        ? selectedStates.find((elem) => {
          return elem.label === selectedBU.state;
        })
        : [];

      SetEditCountry({ label: selectedBU.country });
      SetEditState({ label: selectedBU.state });
      SetEditCity({ label: selectedBU.city });
      setValues(
        {
          country: selectedCountry,
          state: selectedState,
          city: selectedBU.city,
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBU]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const addressFromik = useFormik({
    initialValues: {
      country: "India",
      state: null,
      city: null,
    },
  });

  const { values, setFieldValue, setValues } = addressFromik;

  const offsetHeight = window.innerHeight - 100;

  const onSubmit = () => {
    if (validator.allValid()) {
      //formatting phone number input
      const dFormData = { ...formData };
      let localNumber = convertLocalNumber(dFormData.contact_person_number);
      let countryCode = getPhoneCountryCode(dFormData.contact_person_number);

      formData.contact_person_number = localNumber;
      formData.contact_person_country_code = countryCode;

      const payload = {
        formData: formData,
        org_id: selectedBU.organization,
        business_id: selectedBU.id,
      };
      dispatch({ type: "businessUnit/resetBUError" });
      dispatch({ type: "businessUnit/setLoaderTrue" });
      dispatch({ type: "businessUnit/resetApiSuccess" });
      dispatch({ type: EDIT_BUSINESSUNIT, payload: payload });
      setIsSubmitted(true);
    } else {
      validator.showMessages();
      forceUpdate(1);
      return false;
    }
  };

  return (
    <section className="bu-creation" style={{ height: `${offsetHeight}px` }}>
      {buLoader && <LoaderWithLabel />}
      <h3 className="section-heading"> {t("Update BU")}</h3>
      <Breadcrumb
        list={[
          isEmpty(tenantId) && {
            name: t("Clients"),
            path: "/client",
          },
          isEmpty(tenantId) && selectedClient.name,
          {
            name: t("Setup"),
            path: "/setup",
          },
          selectedBU.country,
          selectedBU.name,
          t("Update BU"),
        ]}
      />

      <CustomInputContainer
        title={"BU Information"}
        formData={formData}
        setFormData={setFormData}
        onSubmit={onSubmit}
        SetEditCountry={SetEditCountry}
        SetEditState={SetEditState}
        SetEditCity={SetEditCity}
        setValues={setValues}
        updatedCountries={updatedCountries}
        editCountry={editCountry}
        editState={editState}
        editCity={editCity}
        values={values}
        updatedCities={updatedCities}
        updatedStates={updatedStates}
        setFieldValue={setFieldValue}
        onChange={onChange}
        validator={validator}
        cancelRoute="/setup"
        type={"BU"}
      />
    </section>
  );
};

export default BusinessUnitEdit;
