import csc from "country-state-city";
import { isEmpty } from "lodash";

//Country state ciy dropdown Options
export const countries = csc.getAllCountries();

export const updatedStates = (countryId) =>
  csc
    .getStatesOfCountry(countryId)
    .map((state) => ({ label: state.name, value: state.id, ...state }));

export const updatedCities = (stateId) =>
  csc
    .getCitiesOfState(stateId)
    .map((city) => ({ label: city.name, value: city.id, ...city }));

export const updatedCountries = countries.map((country) => ({
  label: country.name,
  value: country.id,
  ...country,
}));

//getclient from tenantID
export const getClient = (tenantId, clients) => {
  return clients.find((elem) => elem.id === tenantId);
};

//get base 64 files
export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

//get filtered data from search
export const getFilteredDetails = (items, searchField) => {
  if (isEmpty(searchField)) {
    return items;
  } else {
    return items.filter((item) => {
      return item?.name.toLowerCase().includes(searchField?.toLowerCase());
    });
  }
};

export const updateLabel = (label) => (label === "SNOP" ? "S&OP" : label);

export const getProductOptions = (clientProducts, type) => {
  return clientProducts
    .filter((item) => item.is_active)
    .map((item) => ({
      label: item.name
        ? updateLabel(item.name)
        : updateLabel(item.product_name),
      value: type === "product" ? item?.product : item.id,
    }));
};
