import EditIcon from "@mui/icons-material/Edit";
import { process } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { GridColumn as Column, Grid } from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ActivateModal from "../../../../components/ActivateModal/ActivateModal";
import {
  BU_GET_TEAMS_UNIT,
  EDIT_TEAM_UNIT,
  GET_ALL_ROLES,
  GET_ALL_USERS_BY_TEAMS
} from "../../../../store/Types";
import "./TeamGrid.scss";
import ActivateText from "../../../../components/ProcessingLoader/ActivateText";

const TeamGrid = (props) => {
  const {
    rowLength,
    teamDetail,
    searchField,
    editPermission,
    viewUserPermission,
  } = props;

  const selectedNameBU = useSelector((state) => state.TeamReducer.selectedBU);

  const data = teamDetail;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialDataState = {
    sort: [
      {
        field: "name",
        dir: "asc",
      },
    ],
    take: rowLength,
    skip: 0,
  };

  const [dataState, setDataState] = useState(initialDataState);
  const [resultClient, setResultClient] = useState([]);
  const [isActive, SetIsActive] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setDataState({ ...dataState, take: rowLength });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowLength]);

  const handleActiveToggle = (active, e) => {
    SetIsActive(active);
    setVisible(true);
  };

  useEffect(() => {
    if (searchField) {
      setDataState({ ...dataState, skip: 0 });
      const filtereddata = data?.filter((item) => {
        return (
          item?.name.toLowerCase().includes(searchField?.toLowerCase()) ||
          item?.email.toLowerCase().includes(searchField?.toLowerCase())
        );
      });

      setResultClient(filtereddata);
    } else {
      setResultClient(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchField, data]);

  const onEditClick = (team) => {
    dispatch({
      type: "teams/selectedTeamSlice",
      payload: team,
    });

    setTimeout(() => {
      navigate("/setup/team/edit");
    }, 1000);
  };

  const handleClick = () => {
    const editedTeam = {
      ...isActive,
      business_unit: selectedNameBU.id,
    };

    if (isActive.is_active) {
      const deactivatedTeam = { ...editedTeam, is_active: false, isStatus: true };

      dispatch({ type: EDIT_TEAM_UNIT, payload: deactivatedTeam });
      setVisible(!visible);
      setTimeout(() => {
        dispatch({ type: BU_GET_TEAMS_UNIT, payload: selectedNameBU });
      }, 100);
    } else {
      const activatedTeam = { ...editedTeam, is_active: true, isStatus: true };
      dispatch({ type: EDIT_TEAM_UNIT, payload: activatedTeam });
      setVisible(!visible);
      setTimeout(() => {
        dispatch({ type: BU_GET_TEAMS_UNIT, payload: selectedNameBU });
      }, 100);
    }
  };

  const onViewClick = (team) => {
    dispatch({ type: GET_ALL_USERS_BY_TEAMS, payload: team });
    dispatch({ type: GET_ALL_ROLES, payload: team.organization });
    dispatch({ type: "users/selectedTeamSlice", payload: team });
    setTimeout(() => {
      navigate("/setup/team/user");
    }, 300);
  };

  const filteredTeamList = [];

  !isEmpty(resultClient) &&
    resultClient.forEach((element) => {
      filteredTeamList.push(element);
    });

  return (
    <div className="tablegrid">
      <ActivateModal
        active={isActive}
        open={visible}
        setOpen={setVisible}
        handleClick={handleClick}
      />
      {!isEmpty(filteredTeamList) ? (
        <Grid
          pageable={true}
          sortable={true}
          data={process(filteredTeamList, dataState)}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.dataState);
          }}
        >
          <Column
            field="name"
            title="Team Name"
            headerClassName="tablegrid-heading"
          />
          <Column
            field="email"
            title="Team Email Id"
            headerClassName="tablegrid-heading"
          />
          <Column
            field="user_count"
            title="Number Of Users"
            headerClassName="tablegrid-heading"
          />
          {viewUserPermission && (
            <Column
              field=""
              title="View"
              headerClassName="tablegrid-heading"
              cell={(props) => {
                return (
                  <td className="tablegrid-switch">
                    <span>
                      <Button
                        className="btnteam"
                        style={{ background: "primary" }}
                        onClick={() => onViewClick(props.dataItem)}
                      >
                        View
                      </Button>
                    </span>
                  </td>
                );
              }}
            />
          )}
          <Column
            field="is_active"
            title={<ActivateText/>}
            headerClassName="tablegrid-heading"
            cell={(props) => {
              return (
                <td className="tablegrid-switch">
                  <span>
                    <Switch
                      onLabel={""}
                      offLabel={""}
                      size="small"
                      disabled={!editPermission}
                      checked={props.dataItem.is_active}
                      onChange={(e) => handleActiveToggle(props.dataItem, e)}
                    />
                  </span>
                </td>
              );
            }}
          />
          {editPermission && (
            <Column
              title="Edit"
              headerClassName="tablegrid-heading"
              cell={(props) => {
                const team = props.dataItem;
                return (
                  <td className="tablegrid-switch">
                    <span>
                      <Button
                        style={{ background: "primary" }}
                        onClick={() => onEditClick(team)}
                      >
                        <EditIcon className="editteam" />
                      </Button>
                    </span>
                  </td>
                );
              }}
            />
          )}
        </Grid>
      ) : (
        <div className="text-center w-100">No Records Found</div>
      )}
    </div>
  );
};

export default TeamGrid;
