import { all } from "redux-saga/effects";
import { watchClientSaga } from "./ClientSaga";
import { watchBusinessUnitSaga } from "./businessUnitSaga";
import { watchTeamSaga } from "./teamSaga";
import { watchUsersSaga } from "./userSaga";
import { watchRolesSaga } from "./roleSaga";
import { watchPermissionsSaga } from "./permissionSaga";
export function* watcherSaga() {
  yield all([
    watchClientSaga(),
    watchBusinessUnitSaga(),
    watchTeamSaga(),
    watchUsersSaga(),
    watchRolesSaga(),
    watchPermissionsSaga(),
  ]);
}
