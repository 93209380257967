import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React, { useContext, useEffect } from "react";
import ContextProvider from "../../ContextProvider";

const ActivateModal = (props) => {
  const { open, setOpen, active, handleClick } = props;

  const { fullScreenEnabled } = useContext(ContextProvider);

  useEffect(() => {
    setOpen(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const toggleDialog = () => {
    setOpen(!open);
  };

  return (
    <div>
      {open && (
        <Dialog
          title={"Please confirm"}
          onClose={toggleDialog}
          appendTo={fullScreenEnabled ? null : document.body}
        >
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            {active.is_active ? (
              <>
                Are you sure you want to deactivate <b>{active.name}?</b>
              </>
            ) : (
              <>
                {" "}
                Are you sure you want to activate <b>{active.name}?</b>{" "}
              </>
            )}
          </p>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              id="no-action-controller"
              onClick={toggleDialog}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              id="yes-action-controller"
              onClick={handleClick}
            >
              Yes
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default ActivateModal;
